<nz-skeleton [nzLoading]="loading$ | async" [nzActive]="true" [nzParagraph]="{ rows: 10 }">
  <form nz-form [formGroup]="formGroup" [nzLayout]="'vertical'">
    <nz-form-item>
      <nz-form-label>
        <strong translate>Company</strong>
      </nz-form-label>
      <nz-form-control>
        <nz-select
          formControlName="company"
          nzShowSearch
          [nzPlaceHolder]="'SELECT_COMPANY' | translate"
        >
          <nz-option nzValue="any" nzLabel="{{ 'ANY' | translate }}"></nz-option>
          <nz-option
            *ngFor="let item of companies$ | async"
            [nzValue]="item.id"
            [nzLabel]="item.name"
          ></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-label>
        <strong translate>Model</strong>
      </nz-form-label>
      <nz-form-control>
        <nz-select formControlName="model" nzShowSearch nzAllowClear>
          <nz-option nzValue="any" [nzLabel]="'ANY' | translate"></nz-option>
          <ng-container *ngFor="let model of models$ | async">
            <nz-option
              [nzValue]="model.id"
              nzLabel="{{ model.name }} ({{ model.lotsCount }})"
            ></nz-option>
          </ng-container>
        </nz-select>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-label>
        <strong>{{ 'Object ID' | translate }}</strong>
      </nz-form-label>
      <nz-form-control>
        <input
          type="text"
          nz-input
          formControlName="objectId"
          placeholder="{{ 'Object ID' | translate }}"
        />
      </nz-form-control>
    </nz-form-item>

    <!-- <nz-form-item>
      <nz-form-label class="label-flex"
        ><strong>{{ 'LOT_INFO.YEAR' | translate }}</strong>
        <span
          *ngIf="yearFilterApplied$ | async"
          nz-text
          nzType="secondary"
          translate
          (click)="resetRangeFilter('year')"
          >SEE_ALL</span
        >
      </nz-form-label>
      <nz-form-control>
        <nz-input-group nzCompact class="year-selectors">
          <nz-select
            formControlName="yearFrom"
            class="from"
            [nzShowArrow]="false"
            nzPlaceHolder="{{ 'FROM' | translate }}"
            [nzDropdownMatchSelectWidth]="false"
            style="width: 50%; text-align: center"
          >
            <nz-option *ngFor="let year of years" [nzValue]="year" [nzLabel]="year"></nz-option>
          </nz-select>
          <nz-select
            formControlName="yearTo"
            class="to"
            [nzDropdownMatchSelectWidth]="false"
            [nzShowArrow]="false"
            nzPlaceHolder="{{ 'TO' | translate }}"
            style="width: 50%; text-align: center; border-left: 0px"
          >
            <nz-option *ngFor="let year of years" [nzValue]="year" [nzLabel]="year"></nz-option>
          </nz-select>
        </nz-input-group>
      </nz-form-control>
    </nz-form-item> -->

    <nz-form-item>
      <nz-form-label class="label-flex"
        ><strong translate>LOT_INFO.MILEAGE</strong>
        <span
          *ngIf="mileageFilterApplied$ | async"
          (click)="resetRangeFilter('mileage')"
          nz-text
          nzType="secondary"
          >{{ 'SEE_ALL' | translate }}</span
        >
      </nz-form-label>
      <nz-form-control>
        <nz-input-group nzCompact>
          <input
            type="number"
            nz-input
            formControlName="mileageFrom"
            placeholder="{{ 'FROM' | translate }}"
            style="width: calc(50% - 15px); text-align: center"
          />
          <input
            type="text"
            disabled
            nz-input
            placeholder="-"
            style="
              width: 30px;
              border-left: 0px;
              pointer-events: none;
              background-color: rgb(255, 255, 255);
            "
          />
          <input
            type="number"
            nz-input
            placeholder="{{ 'TO' | translate }}"
            formControlName="mileageTo"
            style="width: calc(50% - 15px); text-align: center; border-left: 0px"
          />
        </nz-input-group>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-label class="label-flex"
        ><strong translate>ENGINE_VOLUME</strong>
        <span
          *ngIf="volumeFilterApplied$ | async"
          nz-text
          nzType="secondary"
          (click)="resetRangeFilter('volume')"
          >{{ 'SEE_ALL' | translate }}</span
        >
      </nz-form-label>
      <nz-form-control>
        <nz-input-group nzCompact>
          <input
            type="number"
            nz-input
            formControlName="volumeFrom"
            placeholder="{{ 'FROM' | translate }}"
            style="width: calc(50% - 15px); text-align: center"
          />
          <input
            type="text"
            disabled
            nz-input
            placeholder="-"
            style="
              width: 30px;
              border-left: 0px;
              pointer-events: none;
              background-color: rgb(255, 255, 255);
            "
          />
          <input
            type="number"
            nz-input
            placeholder="{{ 'TO' | translate }}"
            formControlName="volumeTo"
            style="width: calc(50% - 15px); text-align: center; border-left: 0px"
          />
        </nz-input-group>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-label>
        <strong>{{ 'LOT_INFO.SCORE' | translate }}</strong>
      </nz-form-label>
      <nz-form-control>
        <nz-select
          formControlName="score"
          nzMode="multiple"
          nzPlaceHolder="{{ 'PLEASE_SELECT' | translate }}"
        >
          <nz-option
            *ngFor="let option of scores$ | async"
            [nzLabel]="option.split('').join('.') || 'No Score'"
            [nzValue]="option"
          ></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item nzJustify="end">
      <nz-space>
        <button *nzSpaceItem nz-button (click)="reset()">
          <span translate>CLEAR</span>
        </button>
        <button *nzSpaceItem nz-button nzType="primary" (click)="apply()">
          <span translate>SEARCH</span>
        </button>
      </nz-space>
    </nz-form-item>
  </form>
</nz-skeleton>
