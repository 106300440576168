import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ListingsComponent } from './components/listings/listings.component';
import { LotComponent } from './components/lot/lot.component';

const routes: Routes = [
  {
    path: 'moto',
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'listing' },
      {
        path: 'listings',
        component: ListingsComponent,
      },
      {
        path: ':lot',
        component: LotComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AucnetMotoRoutingModule {}
