<div class="content">
  <nz-row>
    <nz-breadcrumb nzSeparator=">">
      <nz-breadcrumb-item>
        <a [routerLink]="['']" translate>Vehicle Search</a>
      </nz-breadcrumb-item>
      <nz-breadcrumb-item>
        <a [routerLink]="['.']" translate>Listings</a>
      </nz-breadcrumb-item>
      <nz-breadcrumb-item *ngIf="titlePart">
        <a translate>{{ titlePart }}</a>
      </nz-breadcrumb-item>
    </nz-breadcrumb>
  </nz-row>
  <nz-row nzJustify="space-between" class="top">
    <h1>
      <span translate>Used</span> {{ titlePart | translate }}
      <span translate>from Japan for Sale</span>
    </h1>
    <!-- <a nz-button href="https://id.twilight.link/sign-up" nzSize="large">Save Search</a> -->
  </nz-row>
  <nz-row>
    <nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="0" nzXl="0">
      <nz-row nzJustify="space-between">
        <button nz-button nzSize="large" (click)="isMobileFilterOn = !isMobileFilterOn">
          <span translate>Filter</span> <span nz-icon nzType="filter" nzTheme="outline"></span>
        </button>
        <button
          nz-button
          *ngIf="isMobileFilterOn"
          nzShape="circle"
          (click)="isMobileFilterOn = false"
        >
          <span nz-icon nzType="close" nzTheme="outline"></span>
        </button>
      </nz-row>
    </nz-col>
  </nz-row>
  <nz-row>
    <nz-col
      class="mobile-filter"
      *ngIf="isMobileFilterOn"
      nzXs="24"
      nzSm="24"
      nzMd="24"
      nzLg="0"
      nzXl="0"
    >
      <app-listings-filter></app-listings-filter>
    </nz-col>
  </nz-row>
  <nz-row nzGutter="20" style="margin-top: 20px">
    <nz-col nzXs="0" nzSm="0" nzMd="0" nzLg="6" nzXl="4">
      <app-listings-filter></app-listings-filter>
    </nz-col>
    <nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="18" nzXl="20">
      <nz-row nzJustify="space-between" *ngIf="(dataRef.data | async)?.lots.totalCount as total">
        <h2>
          <span translate>Showing</span>
          {{ ((dataRef.page | async) - 1) * (dataRef.pageSize | async) + 1 }} -
          {{
            total < (dataRef.pageSize | async)
              ? total
              : ((dataRef.page | async) - 1) * (dataRef.pageSize | async) +
                (dataRef.pageSize | async)
          }}
          <span translate>of</span> {{ total | number }} <span translate>total</span>
        </h2>
        <div>
          <span translate>Sort by</span>&nbsp;
          <a nz-dropdown [nzDropdownMenu]="menu">
            <span translate>{{ sortings[currentSort].title }}</span>
            <span nz-icon nzType="down"></span>
          </a>
          <nz-dropdown-menu #menu="nzDropdownMenu">
            <ul nz-menu nzSelectable>
              <li
                *ngFor="let sort of sortings | keyvalue"
                nz-menu-item
                (click)="sortEvent(sort.key)"
                translate
              >
                {{ sort.value.title }}
              </li>
            </ul>
          </nz-dropdown-menu>
        </div>
      </nz-row>
      <nz-spin [nzSpinning]="dataRef.loading | async">
        <nz-row [nzGutter]="[20, 20]" class="listings">
          <ng-container *ngFor="let car of (dataRef.data | async)?.lots.items">
            <a
              nz-col
              nzXs="24"
              nzSm="24"
              nzMd="12"
              nzLg="8"
              nzXl="6"
              routerLink="/car/{{ car.id }}"
            >
              <nz-card [nzCover]="coverTemplate" style="min-height: 555px" nzHoverable>
                <h4 nz-typography nzEllipsis title="{{ car.companyEn }} / {{ car.modelNameEn }}">
                  <span *ngIf="!car.modelNameEn.includes(car.companyEn)">
                    {{ car.companyEn }}
                  </span>
                  {{ car.modelNameEn }}
                </h4>
                <h5 style="height: 2lh">
                  {{ car.modelCd }} {{ car.modelGrade }} {{ car.engineVolume
                  }}{{ car.exhaustCd | lowercase }}
                  {{ car.bodyTypeCd.replaceAll('_', ' ') | titlecase }}
                  {{ car.driveCd.replaceAll('_', ' ') }}
                </h5>
                <nz-row nzJustify="space-between">
                  <h2>
                    <nz-tag *ngIf="car.registration">{{
                      car.registration.toString().substring(0, 4)
                    }}</nz-tag>
                    <app-rating-tag *ngIf="car.rate" [rating]="car.rate"></app-rating-tag>
                  </h2>
                  <h2>
                    <b>{{ car.startPrice | currency: 'JPY' }}</b>
                  </h2>
                </nz-row>
                <hr />
                <h5>
                  <fa-icon [fixedWidth]="true" [icon]="faGaugeSimpleHigh"></fa-icon>
                  {{ car.mileage | number }} {{ car.mileageFlag | lowercase }}
                </h5>
                <h5>
                  <fa-icon [fixedWidth]="true" [icon]="faLocationPin"></fa-icon>
                  {{ car.auctionName }}
                </h5>
                <h5>
                  <fa-icon [fixedWidth]="true" [icon]="faPaintBrush"></fa-icon>
                  {{ car.colorEn.replaceAll('_', ' ') | titlecase
                  }}<span *ngIf="car.colorEn2 != 'NOT_SET'"
                    >, {{ car.colorEn2.replaceAll('_', ' ') | titlecase }}</span
                  >
                </h5>
                <h5>
                  <fa-icon [fixedWidth]="true" [icon]="faCar"></fa-icon>
                  {{ car.accidentFlag.replaceAll('_', ' ') | titlecase }},
                  {{ car.historyCd | titlecase }}
                </h5>
                <h5>
                  <fa-icon [fixedWidth]="true" [icon]="faRegistered"></fa-icon>
                  {{ car.frame | vinMask }}
                </h5>
              </nz-card>
              <ng-template #coverTemplate>
                <swiper
                  appStopPropagation
                  [config]="configSwiper"
                  style="border: 1px solid #f0f0f0; min-height: 250px"
                >
                  <ng-template
                    *ngFor="let image of car.images.slice(0, 3); let i = index"
                    swiperSlide
                  >
                    <img
                      nz-image
                      nzDisablePreview
                      routerLink="/car/{{ car.id }}"
                      alt="car"
                      [nzSrc]="image"
                      [nzFallback]="fallbackImg"
                      style="max-width: 100%"
                      loading="lazy"
                    />
                  </ng-template>
                </swiper>
              </ng-template>
            </a>
          </ng-container>
        </nz-row>
      </nz-spin>
      <nz-row nzJustify="center" style="margin: 20px">
        <nz-pagination
          nzShowSizeChanger
          [nzPageIndex]="dataRef.page | async"
          [nzPageSize]="dataRef.pageSize | async"
          [nzTotal]="(dataRef.data | async)?.lots.totalCount"
          (nzPageIndexChange)="changePage($event)"
          (nzPageSizeChange)="changePageSize($event)"
        ></nz-pagination>
      </nz-row>
    </nz-col>
  </nz-row>
</div>
