import { QueryHandlingService, WatchPaginatedQueryRef } from '@akebono/core';
import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import {
  faCar,
  faGaugeSimpleHigh,
  faLocationPin,
  faPaintBrush,
  faRegistered,
} from '@fortawesome/free-solid-svg-icons';
import {
  MochironDumpsLotsListGQL,
  MochironDumpsLotsListQuery,
  MochironDumpsLotsListQueryVariables,
  SortEnumType,
  StockCarFilterInput,
  StockCarSortInput,
} from 'src/app/modules/graphql/service/graphql-mochiron-dumps-main-service';
import SwiperCore, { A11y, Navigation, Pagination, Scrollbar, SwiperOptions } from 'swiper';

import { DateRange } from '../../../../const';
import { QueryFilterService } from '../../services/query-filter.service';

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

@Component({
  selector: 'app-listings',
  templateUrl: './listings.component.html',
  styleUrls: ['./listings.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListingsComponent {
  dataRef: WatchPaginatedQueryRef<
    MochironDumpsLotsListQuery,
    StockCarFilterInput,
    StockCarSortInput | StockCarSortInput[],
    MochironDumpsLotsListQueryVariables
  >;
  data: any[] = [];
  filtersData: any;
  pageSize = 32;
  page = 1;
  loadingResult = true;
  loadingFilters = true;
  total = 0;
  faGaugeSimpleHigh = faGaugeSimpleHigh;
  faLocationPin = faLocationPin;
  faPaintBrush = faPaintBrush;
  faCar = faCar;
  faRegistered = faRegistered;
  isMobileFilterOn = false;

  years: number[] = [];
  minYear = 1985;

  form: FormGroup;

  companies: any;
  japanCompaniesList: any[];
  otherCompaniesList: any[];
  mainCompaniesList: any[];
  mainCompaniesNames = ['toyota', 'nissan', 'honda'];
  auctRefOptions: any[];
  trucks: any[];
  currentCompany;
  isYearFiltered = false;
  isMileageFiltered = false;
  isEngineVolumeFiltered = false;
  isProductionDateFiltered = false;
  isActiveFilters = true;
  // fallbackImg = `data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 640 480'%3e%3c/svg%3e`;
  fallbackImg = '/assets/images/placeholder.png';

  inclusionFilterFields = {
    frame: 'modelCd',
    score: 'aisScoreTotal',
    auction: 'regionName',
    color: 'colorCd1',
    transmission: 'missionCd',
    bodyType: 'bodyTypeCd',
    fuel: 'fuelCd',
  };
  configSwiper: SwiperOptions = {
    loop: true,
    keyboard: false,
    slidesPerView: 1,
    spaceBetween: 0,
    navigation: true,
    pagination: { clickable: true },
    autoHeight: true,
  };
  lastDay: Date;
  dateRange: DateRange[] = [];
  selectedDays: string[] = [];
  dumpVersion: string;
  outdated = false;

  sortings = {
    best_match: { key: 'default', value: '', title: 'Best Match' },
    price_asc: { key: 'wholesalePrice', value: SortEnumType.Asc, title: 'Price Low to High' },
    price_desc: { key: 'wholesalePrice', value: SortEnumType.Desc, title: 'Price High to Low' },
    mileage_asc: { key: 'mileage', value: SortEnumType.Asc, title: 'Mileage Low to High' },
    mileage_desc: { key: 'mileage', value: SortEnumType.Desc, title: 'Mileage High to Low' },
    registration_asc: { key: 'registration', value: SortEnumType.Asc, title: 'Year Low to High' },
    registration_desc: { key: 'registration', value: SortEnumType.Desc, title: 'Year High to Low' },
    inventory_desc: { key: 'inventoryNo', value: SortEnumType.Desc, title: 'Newly Listed' },
  };
  currentSort = 'best_match';
  titlePart = 'Cars';
  breadcrumb: string;
  country: string;
  routeWay: string;

  constructor(
    private title: Title,
    private router: Router,
    private listLotsGQL: MochironDumpsLotsListGQL,
    private qhs: QueryHandlingService,
    private queryFilter: QueryFilterService,
    private route: ActivatedRoute,
  ) {
    this.title.setTitle('Akebono World Stock Inventory Cars');
    this.breadcrumb = this.route.snapshot.data.breadcrumb;
    this.country = this.route.snapshot.data.country;
    this.routeWay = this.route.snapshot.data.route;
    const isUserCars = this.route.snapshot.data?.isUserCars;
    this.dataRef = this.qhs.watchPaginated(
      this.listLotsGQL,
      (params): StockCarFilterInput => {
        this.titlePart = this.getTitlePartFromParams(params) || 'Cars';
        this.currentSort = params.sort || 'best_match';
        let filter = this.queryFilter.getQueryFilter(params, this.inclusionFilterFields);
        if (isUserCars) {
          if (!filter) filter = {};
          filter = {
            ...filter,
            ownerId: { eq: +this.route.snapshot.params.id },
            isSold: { eq: false },
          };
        }
        if (this.route.snapshot.data.departmentId) {
          if (!filter) filter = {};
          filter.departmentId = {
            eq: this.route.snapshot.data.departmentId,
          };
        }
        return filter;
      },
      (params): StockCarSortInput | StockCarSortInput[] => {
        let sort: StockCarSortInput = {
          isSold: SortEnumType.Asc,
          companyEn: SortEnumType.Asc,
          modelEn: SortEnumType.Asc,
          year: SortEnumType.Desc,
          mileageNum: SortEnumType.Asc,
        };
        if (params.sort && params.sort != 'best_match') {
          sort = { isSold: SortEnumType.Asc };
          sort[this.sortings[this.currentSort].key] = this.sortings[this.currentSort].value;
        }
        return sort;
      },
      {},
      {
        defaultPageSize: 32,
      },
    );
  }

  getTitlePartFromParams(params: { company?: string; model?: string }) {
    let companyName = params.company?.replace('any', '');
    const modelName = params.model?.replace('any', '');
    if (modelName?.includes(companyName)) {
      companyName = '';
    }
    return [companyName, modelName].join(' ').trim();
  }

  scrollToTable() {
    document.querySelector('.top')?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
  }

  changePage(page) {
    this.router.navigate([], {
      queryParams: {
        page,
      },
      queryParamsHandling: 'merge',
    });
    this.scrollToTable();
  }

  changePageSize(pageSize) {
    this.loadingResult = true;

    this.router.navigate([], {
      queryParams: {
        pageSize,
      },
      queryParamsHandling: 'merge',
    });
    this.scrollToTable();
  }

  sortEvent(sort: string): void {
    this.loadingResult = true;

    this.router.navigate([], {
      queryParams: {
        sort: sort,
      },
      queryParamsHandling: 'merge',
    });
  }
}
