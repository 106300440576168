import { QueryHandlingService, WatchPaginatedQueryRef } from '@akebono/core';
import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import {
  faCar,
  faGaugeSimpleHigh,
  faLocationPin,
  faPaintBrush,
  faRegistered,
} from '@fortawesome/free-solid-svg-icons';
import { marker as _ } from '@jbouduin/ngx-translate-extract-marker';
import { QueryRef } from 'apollo-angular';
import {
  AucnetLotsListGQL,
  AucnetLotsListQuery,
  AucnetLotsListQueryVariables,
  SharedInventoryCarFilterInput,
  SharedInventoryCarSortInput,
  SortEnumType,
} from 'src/app/modules/graphql/service/graphql-aucnet-main-service';
import SwiperCore, { A11y, Navigation, Pagination, Scrollbar, SwiperOptions } from 'swiper';

import { DateRange } from '../../../../const';
import { QueryFilterService } from '../../services/query-filter.service';

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

@Component({
  selector: 'app-listings',
  templateUrl: './listings.component.html',
  styleUrls: ['./listings.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListingsComponent {
  dataRef: WatchPaginatedQueryRef<
    AucnetLotsListQuery,
    SharedInventoryCarFilterInput,
    SharedInventoryCarSortInput | SharedInventoryCarSortInput[],
    AucnetLotsListQueryVariables
  >;
  data: any[] = [];
  filtersData: any;
  pageSize = 32;
  page = 1;
  loadingResult = true;
  loadingFilters = true;
  total = 0;
  faGaugeSimpleHigh = faGaugeSimpleHigh;
  faLocationPin = faLocationPin;
  faPaintBrush = faPaintBrush;
  faCar = faCar;
  faRegistered = faRegistered;
  isMobileFilterOn = false;

  years: number[] = [];
  minYear = 1985;

  form: FormGroup;

  companies: any;
  japanCompaniesList: any[];
  otherCompaniesList: any[];
  mainCompaniesList: any[];
  mainCompaniesNames = ['toyota', 'nissan', 'honda'];
  auctRefOptions: any[];
  trucks: any[];
  currentCompany;
  isYearFiltered = false;
  isMileageFiltered = false;
  isEngineVolumeFiltered = false;
  isProductionDateFiltered = false;
  isActiveFilters = true;
  fallbackImg = `data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 500 500'%3e%3c/svg%3e`;

  inclusionFilterFields = {
    frame: 'modelCd',
    score: 'aisScoreTotal',
    auction: 'regionName',
    color: 'colorCd1',
    transmission: 'missionCd',
    bodyType: 'bodyTypeCd',
    fuel: 'fuelCd',
  };
  configSwiper: SwiperOptions = {
    loop: true,
    keyboard: false,
    slidesPerView: 1,
    spaceBetween: 0,
    navigation: true,
    pagination: { clickable: true },
    autoHeight: true,
  };
  lastDay: Date;
  dateRange: DateRange[] = [];
  selectedDays: string[] = [];
  dumpVersion: string;
  outdated = false;
  lotsListQueryRef: QueryRef<AucnetLotsListQuery, AucnetLotsListQueryVariables>;

  sortings = {
    best_match: { key: 'default', value: '', title: _('Best Match') },
    price_asc: { key: 'wholesalePrice', value: SortEnumType.Asc, title: _('Price Low to High') },
    price_desc: { key: 'wholesalePrice', value: SortEnumType.Desc, title: _('Price High to Low') },
    mileage_asc: { key: 'mileage', value: SortEnumType.Asc, title: _('Mileage Low to High') },
    mileage_desc: { key: 'mileage', value: SortEnumType.Desc, title: _('Mileage High to Low') },
    registration_asc: {
      key: 'registration',
      value: SortEnumType.Asc,
      title: _('Year Low to High'),
    },
    registration_desc: {
      key: 'registration',
      value: SortEnumType.Desc,
      title: _('Year High to Low'),
    },
    inventory_desc: { key: 'inventoryNo', value: SortEnumType.Desc, title: _('Newly Listed') },
  };
  currentSort = 'best_match';
  titlePart = 'Cars';

  constructor(
    private title: Title,
    private router: Router,
    private listLotsGQL: AucnetLotsListGQL,
    private qhs: QueryHandlingService,
    private queryFilter: QueryFilterService,
  ) {
    this.title.setTitle('Akebono World Stock Inventory Cars');
    this.dataRef = this.qhs.watchPaginated(
      this.listLotsGQL,
      (params) => {
        this.titlePart = this.getTitlePartFromParams(params) || _('Cars');
        this.currentSort = params.sort || 'best_match';
        return this.queryFilter.getQueryFilter(params, this.inclusionFilterFields);
      },
      (params): SharedInventoryCarSortInput | SharedInventoryCarSortInput[] => {
        let sort: SharedInventoryCarSortInput = {
          makerEn: SortEnumType.Asc,
          carnameEn: SortEnumType.Asc,
          inventoryYear: SortEnumType.Desc,
          mileage: SortEnumType.Asc,
        };
        if (params.sort && params.sort != 'best_match') {
          sort = {};
          sort[this.sortings[this.currentSort].key] = this.sortings[this.currentSort].value;
        }
        return sort;
      },
      {},
      {
        defaultPageSize: 32,
      },
    );
  }

  getTitlePartFromParams(params: { company?: string; model?: string }) {
    let companyName = params.company?.replace('any', '');
    const modelName = params.model?.replace('any', '');
    if (modelName?.includes(companyName)) {
      companyName = '';
    }
    console.log(companyName, modelName);
    return [companyName, modelName].join(' ').trim();
  }

  scrollToTable() {
    document.querySelector('.top')?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
  }

  changePage(page) {
    this.router.navigate(['listing'], {
      queryParams: {
        page,
      },
      queryParamsHandling: 'merge',
    });
    this.scrollToTable();
  }

  changePageSize(pageSize) {
    this.loadingResult = true;

    this.router.navigate(['listing'], {
      queryParams: {
        pageSize,
      },
      queryParamsHandling: 'merge',
    });
    this.scrollToTable();
  }

  sortEvent(sort: string): void {
    this.loadingResult = true;

    this.router.navigate(['listing'], {
      queryParams: {
        sort: sort,
      },
      queryParamsHandling: 'merge',
    });
  }
}
