import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LotComponent } from './components/lot/lot.component';
import { ListingsComponent } from './components/listings/listings.component';

const routes: Routes = [
  {
    path: 'listing',
    component: ListingsComponent,
  },
  {
    path: 'car/:lot',
    component: LotComponent,
  },
  {
    path: 'car/:source/:lot',
    component: LotComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AucnetSicRoutingModule {}
