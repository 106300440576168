import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { AutoLotView } from '../../../const';
import { AucnetLotQuery } from '../../graphql/service/graphql-aucnet-main-service';

@Component({
  selector: 'app-lot-title-full',
  templateUrl: './lot-title-full.component.html',
  styleUrls: ['./lot-title-full.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LotTitleFullComponent {
  @Input() lot: AucnetLotQuery['lot'];
}
