<div class="swiper-lot">
  <swiper
    #swiper
    *ngIf="images.length"
    class="swiper-lot"
    [config]="configSwiper"
    (activeIndexChange)="activeSlide = $event[0].activeIndex"
  >
    <ng-template swiperSlide *ngFor="let image of images; let i = index">
      <div class="img-wrapper">
        <img [src]="image" (click)="openPreview(i)" />
      </div>
    </ng-template>
  </swiper>

  <img
    *ngIf="!images.length"
    nz-image
    nzDisablePreview
    alt="car image"
    nzSrc="/assets/images/placeholder.png"
    nzFallback="/assets/images/placeholder.png"
    style="
      max-width: 100%;
      min-height: 250px;
      object-fit: contain;
      object-position: center center;
      padding: 100px;
      background-color: #e9e9e9;
    "
    loading="lazy"
  />
</div>

<div class="thumbs">
  <div
    class="thumb"
    *ngFor="let image of images; let i = index"
    [ngClass]="{ active: i + 1 === activeSlide }"
  >
    <div class="img-wrapper">
      <img [src]="image" (click)="onClickThumb(i + 1)" />
    </div>
  </div>
</div>
