import { Injectable } from '@angular/core';
import { StockCarFilterInput } from '../../graphql/service/graphql-mochiron-dumps-main-service';

@Injectable()
export class QueryFilterService {
  public getQueryFilter(params, inclusionFilterFields): StockCarFilterInput {
    const inclusionFilter = {};
    Object.keys(inclusionFilterFields).forEach((key) => {
      if (params[key] && params[key].length > 0) {
        inclusionFilter[inclusionFilterFields[key]] = {
          in: [].concat(params[key]),
        };
      }
    });

    const filter: StockCarFilterInput = { ...inclusionFilter };
    if (params.company && params.company != 'any') {
      filter.companyEn = {
        eq: params.company,
      };
    }
    if (params.model && params.model != 'any') {
      filter.modelEn = {
        eq: params.model,
      };
    }
    if (params.mileageFrom) {
      filter.mileageNum = {};
      filter.mileageNum.gte = Number(params.mileageFrom);
    }
    if (params.mileageTo) {
      if (!filter.mileageNum) {
        filter.mileageNum = {};
      }
      filter.mileageNum.lte = Number(params.mileageTo);
    }
    if (params.engineVolumeFrom) {
      filter.engineVolumeNum = {};
      filter.engineVolumeNum.gte = Number(params.engineVolumeFrom);
    }
    if (params.engineVolumeTo) {
      if (!filter.engineVolumeNum) {
        filter.engineVolumeNum = {};
      }
      filter.engineVolumeNum.lte = Number(params.engineVolumeTo);
    }
    if (params.yearFrom) {
      filter.year = {};
      filter.year.gte = Number(params.yearFrom);
    }
    if (params.yearTo) {
      if (!filter.year) {
        filter.year = {};
      }
      filter.year.lte = Number(params.yearTo) + 1;
    }

    if (params.notSold) {
      filter.isSold = {};
      filter.isSold.eq = false;
    }

    return Object.keys(filter).length > 0 ? filter : null;
  }
}
