import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ListingsComponent } from './components/listings/listings.component';
import { LotComponent } from './components/lot/lot.component';
import { Department } from '../graphql/service/graphql-mochiron-dumps-main-service';
import { marker as _ } from '@jbouduin/ngx-translate-extract-marker';

const routes: Routes = [
  // {
  //   path: 'dubai/listings',
  //   component: ListingsComponent,
  //   data: {
  //     departmentId: Department.Dubai,
  //     breadcrumb: _('Left Hand Drive Dubai Listings'),
  //     country: _('dubai'),
  //     route: 'dubai',
  //   },
  // },
  // {
  //   path: 'dubai/car/:lot',
  //   component: LotComponent,
  //   data: {
  //     departmentId: Department.Dubai,
  //     breadcrumb: _('Left Hand Drive Dubai Listings'),
  //     country: _('dubai'),
  //     route: 'dubai',
  //   },
  // },
  {
    path: 'mongolia/listings',
    component: ListingsComponent,
    data: {
      departmentId: Department.Mongolia,
      breadcrumb: _('Mongolia Stock Listings'),
      country: _('mongolia'),
      route: 'mongolia',
    },
  },
  {
    path: 'mongolia/car/:lot',
    component: LotComponent,
    data: {
      departmentId: Department.Mongolia,
      breadcrumb: _('Mongolia Stock Listings'),
      country: _('mongolia'),
      route: 'mongolia',
    },
  },
  {
    path: 'green/listings',
    component: ListingsComponent,
    data: {
      departmentId: Department.GreenCorner,
      breadcrumb: _('Green Corner 2.0 Listings'),
      country: _('japan'),
      route: 'green',
    },
  },
  {
    path: 'green/car/:lot',
    component: LotComponent,
    data: {
      departmentId: Department.GreenCorner,
      breadcrumb: _('Green Corner 2.0 Listings'),
      country: _('japan'),
      route: 'green',
    },
  },
  {
    path: 'green/car/user/:id',
    component: ListingsComponent,
    data: {
      departmentId: Department.GreenCorner,
      breadcrumb: _('Green Corner 2.0 seller ads'),
      country: _('japan'),
      isUserCars: true,
      route: 'green',
    },
  },
  {
    path: 'recycle/listings',
    component: ListingsComponent,
    data: {
      departmentId: Department.Utilization,
      breadcrumb: _('Toyama Recycle Listings'),
      country: _('toyama, japan'),
      route: 'recycle',
    },
  },
  {
    path: 'recycle/car/:lot',
    component: LotComponent,
    data: {
      departmentId: Department.Utilization,
      breadcrumb: _('Toyama Recycle Listings'),
      country: _('toyama, japan'),
      route: 'recycle',
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MochironDumpsRoutingModule {}
