import { QueryHandlingService } from '@akebono/core';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { debounceTime, map, merge, Observable, shareReplay, startWith, switchMap } from 'rxjs';
import {
  Company,
  LotsFilterDataGQL,
  LotsFilterDataQuery,
  LotsFilterDataQueryVariables,
  Model,
} from 'src/app/modules/graphql/service/graphql-aucnet-moto-service';

import { ListingsFilterBuilderService } from '../../services/listings-filter-builder.service';

@Component({
  selector: 'app-listings-filter',
  templateUrl: './listings-filter.component.html',
  styleUrls: ['./listings-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListingsFilterComponent {
  formGroup: FormGroup;

  loading$: Observable<boolean>;

  companies$: Observable<Company[]>;
  models$: Observable<Model[]>;
  scores$: Observable<string[]>;

  yearFilterApplied$: Observable<boolean>;
  volumeFilterApplied$: Observable<boolean>;
  mileageFilterApplied$: Observable<boolean>;

  years: number[] = [];
  minYear = 1985;

  constructor(
    fb: FormBuilder,
    route: ActivatedRoute,
    qhs: QueryHandlingService,
    private readonly router: Router,
    private readonly filterBuilder: ListingsFilterBuilderService,
    listingsFilterDataGQL: LotsFilterDataGQL,
  ) {
    this.formGroup = fb.group({
      company: fb.nonNullable.control('any'),
      model: fb.nonNullable.control('any'),
      objectId: fb.control(null),
      yearFrom: fb.control(null),
      yearTo: fb.control(null),
      mileageFrom: fb.control(null),
      mileageTo: fb.control(null),
      volumeFrom: fb.control(null),
      volumeTo: fb.control(null),
      score: fb.nonNullable.control([]),
    });

    this.yearFilterApplied$ = this.formGroup.valueChanges.pipe(
      map(({ yearFrom, yearTo }) => yearFrom || yearTo),
      shareReplay(1),
    );
    this.volumeFilterApplied$ = this.formGroup.valueChanges.pipe(
      map(({ volumeFrom, volumeTo }) => volumeFrom || volumeTo),
      shareReplay(1),
    );
    this.mileageFilterApplied$ = this.formGroup.valueChanges.pipe(
      map(({ mileageFrom, mileageTo }) => mileageFrom || mileageTo),
      shareReplay(1),
    );

    for (let year = new Date().getFullYear(); year >= this.minYear; year--) {
      this.years.push(year);
    }

    const queryRef = qhs.fetch<LotsFilterDataQuery, LotsFilterDataQueryVariables>(
      listingsFilterDataGQL,
      {},
      'network-only',
    );

    this.loading$ = queryRef.loading;
    const data$ = queryRef.data.pipe(shareReplay(1));

    const company$ = this.formGroup.controls.company.valueChanges.pipe(
      startWith('any'),
      shareReplay(1),
    );

    this.scores$ = data$.pipe(map((data) => data.scores));
    this.companies$ = data$.pipe(map((data) => data.companies));
    this.models$ = data$.pipe(
      map((data) => data.models),
      switchMap((models) =>
        company$.pipe(
          map((company) => {
            if (company && company !== 'any') {
              return models.filter((model) => model.companyId === company);
            } else {
              return models;
            }
          }),
        ),
      ),
      shareReplay(1),
    );

    merge(this.yearFilterApplied$, this.volumeFilterApplied$, this.mileageFilterApplied$)
      .pipe(takeUntilDestroyed())
      .subscribe();

    company$
      .pipe(takeUntilDestroyed())
      .subscribe(() => this.formGroup.controls.model.setValue('any'));

    route.queryParams
      .pipe(
        map((params) => filterBuilder.parseParams(params)),
        takeUntilDestroyed(),
      )
      .subscribe((value) => this.formGroup.patchValue(value, { emitEvent: false }));

    this.formGroup.valueChanges
      .pipe(debounceTime(500), takeUntilDestroyed())
      .subscribe(() => this.apply());
  }

  apply(): void {
    const filterValue = this.formGroup.value;

    if (filterValue.objectId) {
      this.router.navigate(['/moto', filterValue.objectId]);
      return;
    }

    this.router.navigate([], {
      queryParams: {
        page: null,
        ...this.filterBuilder.getParams(filterValue),
      },
      queryParamsHandling: 'merge',
    });
  }

  resetRangeFilter(field: 'year' | 'volume' | 'mileage'): void {
    this.formGroup.controls[`${field}From`].setValue(null);
    this.formGroup.controls[`${field}To`].setValue(null);
    this.apply();
  }

  reset(): void {
    this.formGroup.reset();
    this.router.navigate([]);
  }
}
