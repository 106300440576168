import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'default' })
export class DefaultPipe implements PipeTransform {
  transform(value: unknown, defaultValue = '—'): unknown {
    if (typeof value === 'string') {
      return value?.trim() ? value : defaultValue;
    } else {
      return value || defaultValue;
    }
  }
}
