import { AkebonoCoreModule } from '@akebono/core';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { SwiperModule } from 'swiper/angular';

import { LotCarouselComponent } from './lot-carousel.component';
import { NzImageModule } from 'ng-zorro-antd/image';

@NgModule({
  declarations: [LotCarouselComponent],
  exports: [LotCarouselComponent],
  imports: [
    CommonModule,
    SwiperModule,
    NzIconModule,
    NzButtonModule,
    NzPopconfirmModule,
    NzToolTipModule,
    NzSpinModule,
    NzImageModule,
    TranslateModule,
    AkebonoCoreModule,
  ],
})
export class LotCarouselModule {}
