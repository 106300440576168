<nz-row style="margin-top: 20px">
  <nz-breadcrumb nzSeparator=">">
    <nz-breadcrumb-item>
      <a [routerLink]="['/']" translate>Motorcycles Search</a>
    </nz-breadcrumb-item>
    <nz-breadcrumb-item>
      <a [routerLink]="['/moto', 'listings']" translate>Listings</a>
    </nz-breadcrumb-item>
    <nz-breadcrumb-item *ngIf="lotId$ | async as lotId">
      <a [routerLink]="[]">#{{ lotId }}</a>
    </nz-breadcrumb-item>
  </nz-breadcrumb>
</nz-row>

<nz-spin nzSize="large" [nzSpinning]="loading$ | async">
  <header style="margin-top: 20px">
    <nz-result
      *ngIf="(lot$ | async) === null && (loading$ | async) === false"
      nzStatus="404"
      [nzTitle]="'Listing not found' | translate"
    ></nz-result>

    <h2 *ngIf="lot$ | async as lot">
      <strong>
        <span class="company"> {{ lot | motoTitle }} </span>
        <ng-container *ngIf="lot.engineModel">{{ lot.engineModel }}</ng-container> - #{{ lot.id }}
      </strong>
    </h2>
  </header>

  <main *ngIf="lot$ | async as lot" nz-row [nzGutter]="[16]">
    <aside nz-col [nzXs]="{ span: 24, order: 1 }" [nzLg]="{ span: 14, order: 2 }">
      <app-lot-carousel
        style="margin-bottom: 20px"
        [images]="lot | extractImages"
      ></app-lot-carousel>
    </aside>

    <aside nz-col [nzXs]="{ span: 24, order: 2 }" [nzLg]="{ span: 10, order: 1 }">
      <nz-card [nzTitle]="title">
        <ng-template #title>
          <nz-row nzAlign="top" nzJustify="space-between">
            <nz-col>
              <span translate>Price</span>
              <br />
              <h1 style="font-size: 32px">
                {{ lot.wholesalePrice | currency: 'JPY':'symbol-narrow' }}
              </h1>
            </nz-col>
            <nz-col>
              <button nz-button nzType="default" nzSize="large" (click)="copyToClipboard()">
                <span nz-icon nzType="share-alt" nzTheme="outline" style="cursor: pointer"></span>
                <span translate>SHARED.SHARE</span>
              </button>
            </nz-col>
          </nz-row>
          <nz-row nzJustify="space-between">
            <!-- <nz-col>
              <span translate>Year</span><br />
              <h3>{{ lot.modelYear || '—' }}</h3>
            </nz-col> -->
            <nz-col>
              <span translate>Grade</span><br />
              <h3>{{ lot | motoTitle: 'model' | default }}</h3>
            </nz-col>
            <nz-col>
              <span translate>Color</span><br />
              <h3>
                {{ lot.color | default }}
              </h3>
            </nz-col>
            <nz-col>
              <span translate>Mileage</span><br />
              <h3>{{ lot.mileage | number }} {{ lot.mileageUnits }}</h3>
            </nz-col>
            <nz-col>
              <span translate>LOT_INFO.SCORE</span><br />
              <h3>{{ lot.evaluation | default }}</h3>
            </nz-col>
          </nz-row>
        </ng-template>

        <h1 class="info-icon">
          <fa-icon [fixedWidth]="true" [icon]="faRegistered"></fa-icon>
          {{ lot.frameNumber | vinMask }}
        </h1>

        <app-contact-form
          [style.margin-top.px]="20"
          [lotId]="lot.id"
          [lotSource]="lotSource"
          [lotType]="lotType"
          [currentUser]="currentUser$ | async"
          [wholesalePrice]="lot.wholesalePrice"
        ></app-contact-form>
      </nz-card>
    </aside>
  </main>
</nz-spin>
