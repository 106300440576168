import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** The `Long` scalar type represents non-fractional signed whole 64-bit numeric values. Long can represent values between -(2^63) and 2^63 - 1. */
  Long: { input: any; output: any; }
  /** The UnsignedInt scalar type represents a unsigned 32-bit numeric non-fractional value greater than or equal to 0. */
  UnsignedInt: { input: any; output: any; }
};

export type BooleanOperationFilterInput = {
  eq?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Information about the offset pagination. */
export type CollectionSegmentInfo = {
  __typename?: 'CollectionSegmentInfo';
  /** Indicates whether more items exist following the set defined by the clients arguments. */
  hasNextPage: Scalars['Boolean']['output'];
  /** Indicates whether more items exist prior the set defined by the clients arguments. */
  hasPreviousPage: Scalars['Boolean']['output'];
};

export type Company = {
  __typename?: 'Company';
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export enum Department {
  Dubai = 'DUBAI',
  GreenCorner = 'GREEN_CORNER',
  Mongolia = 'MONGOLIA',
  Utilization = 'UTILIZATION'
}

export type Frame = {
  __typename?: 'Frame';
  count: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type IntOperationFilterInput = {
  eq?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  neq?: InputMaybe<Scalars['Int']['input']>;
  ngt?: InputMaybe<Scalars['Int']['input']>;
  ngte?: InputMaybe<Scalars['Int']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  nlt?: InputMaybe<Scalars['Int']['input']>;
  nlte?: InputMaybe<Scalars['Int']['input']>;
};

/** A segment of a collection. */
export type LotsPaginatedListCollectionSegment = {
  __typename?: 'LotsPaginatedListCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<StockCar>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type Model = {
  __typename?: 'Model';
  companyId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  lotsCount: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type NullableOfDepartmentOperationFilterInput = {
  eq?: InputMaybe<Department>;
  in?: InputMaybe<Array<InputMaybe<Department>>>;
  neq?: InputMaybe<Department>;
  nin?: InputMaybe<Array<InputMaybe<Department>>>;
};

export type Query = {
  __typename?: 'Query';
  colors: Array<Maybe<Scalars['String']['output']>>;
  companies: Array<Company>;
  frames: Array<Frame>;
  lot?: Maybe<StockCar>;
  lotsPaginatedList?: Maybe<LotsPaginatedListCollectionSegment>;
  models: Array<Model>;
  scores: Array<Scalars['String']['output']>;
  transmissions: Array<Scalars['String']['output']>;
  years: Array<Scalars['Int']['output']>;
};


export type QueryColorsArgs = {
  order?: InputMaybe<Array<StockCarSortInput>>;
  where?: InputMaybe<StockCarFilterInput>;
};


export type QueryCompaniesArgs = {
  order?: InputMaybe<Array<StockCarSortInput>>;
  where?: InputMaybe<StockCarFilterInput>;
};


export type QueryFramesArgs = {
  order?: InputMaybe<Array<StockCarSortInput>>;
  where?: InputMaybe<StockCarFilterInput>;
};


export type QueryLotArgs = {
  id: Scalars['Long']['input'];
};


export type QueryLotsPaginatedListArgs = {
  order?: InputMaybe<Array<StockCarSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<StockCarFilterInput>;
};


export type QueryModelsArgs = {
  order?: InputMaybe<Array<StockCarSortInput>>;
  where?: InputMaybe<StockCarFilterInput>;
};


export type QueryScoresArgs = {
  order?: InputMaybe<Array<StockCarSortInput>>;
  where?: InputMaybe<StockCarFilterInput>;
};


export type QueryTransmissionsArgs = {
  order?: InputMaybe<Array<StockCarSortInput>>;
  where?: InputMaybe<StockCarFilterInput>;
};


export type QueryYearsArgs = {
  order?: InputMaybe<Array<StockCarSortInput>>;
  where?: InputMaybe<StockCarFilterInput>;
};

export enum SortEnumType {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type StockCar = {
  __typename?: 'StockCar';
  akebonoLotId?: Maybe<Scalars['Int']['output']>;
  akebonoLotSourceOriginalId?: Maybe<Scalars['String']['output']>;
  akebonoLotSourceProvider?: Maybe<Scalars['String']['output']>;
  akebonoLotSourceSubgroup?: Maybe<Scalars['String']['output']>;
  auctionList?: Maybe<Scalars['String']['output']>;
  colorEn?: Maybe<Scalars['String']['output']>;
  companyEn?: Maybe<Scalars['String']['output']>;
  departmentId?: Maybe<Department>;
  description?: Maybe<Scalars['String']['output']>;
  engineVolumeNum?: Maybe<Scalars['Int']['output']>;
  equipmentEn?: Maybe<Scalars['String']['output']>;
  guid1C?: Maybe<Scalars['String']['output']>;
  hasTelegram?: Maybe<Scalars['Boolean']['output']>;
  hasWhatsapp?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['Int']['output'];
  images?: Maybe<Scalars['String']['output']>;
  inStockDubai?: Maybe<Scalars['Boolean']['output']>;
  isSold?: Maybe<Scalars['Boolean']['output']>;
  mileageNum?: Maybe<Scalars['Int']['output']>;
  modelEn?: Maybe<Scalars['String']['output']>;
  modelGradeEn?: Maybe<Scalars['String']['output']>;
  modelTypeEn?: Maybe<Scalars['String']['output']>;
  ownerId?: Maybe<Scalars['Int']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  priceInStock?: Maybe<Scalars['Int']['output']>;
  priceInStockCurrency?: Maybe<Scalars['String']['output']>;
  priceOnDelivery?: Maybe<Scalars['Int']['output']>;
  priceOnDeliveryCurrency?: Maybe<Scalars['String']['output']>;
  ratingEn?: Maybe<Scalars['String']['output']>;
  thumbnails?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  transmissionEn?: Maybe<Scalars['String']['output']>;
  userDescription?: Maybe<Scalars['String']['output']>;
  vin?: Maybe<Scalars['String']['output']>;
  year?: Maybe<Scalars['Int']['output']>;
};

export type StockCarFilterInput = {
  akebonoLotId?: InputMaybe<IntOperationFilterInput>;
  akebonoLotSourceOriginalId?: InputMaybe<StringOperationFilterInput>;
  akebonoLotSourceProvider?: InputMaybe<StringOperationFilterInput>;
  akebonoLotSourceSubgroup?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<StockCarFilterInput>>;
  auctionList?: InputMaybe<StringOperationFilterInput>;
  colorEn?: InputMaybe<StringOperationFilterInput>;
  companyEn?: InputMaybe<StringOperationFilterInput>;
  departmentId?: InputMaybe<NullableOfDepartmentOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  engineVolumeNum?: InputMaybe<IntOperationFilterInput>;
  equipmentEn?: InputMaybe<StringOperationFilterInput>;
  guid1C?: InputMaybe<StringOperationFilterInput>;
  hasTelegram?: InputMaybe<BooleanOperationFilterInput>;
  hasWhatsapp?: InputMaybe<BooleanOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  images?: InputMaybe<StringOperationFilterInput>;
  inStockDubai?: InputMaybe<BooleanOperationFilterInput>;
  isSold?: InputMaybe<BooleanOperationFilterInput>;
  mileageNum?: InputMaybe<IntOperationFilterInput>;
  modelEn?: InputMaybe<StringOperationFilterInput>;
  modelGradeEn?: InputMaybe<StringOperationFilterInput>;
  modelTypeEn?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<StockCarFilterInput>>;
  ownerId?: InputMaybe<IntOperationFilterInput>;
  phone?: InputMaybe<StringOperationFilterInput>;
  priceInStock?: InputMaybe<IntOperationFilterInput>;
  priceInStockCurrency?: InputMaybe<StringOperationFilterInput>;
  priceOnDelivery?: InputMaybe<IntOperationFilterInput>;
  priceOnDeliveryCurrency?: InputMaybe<StringOperationFilterInput>;
  ratingEn?: InputMaybe<StringOperationFilterInput>;
  thumbnails?: InputMaybe<StringOperationFilterInput>;
  title?: InputMaybe<StringOperationFilterInput>;
  transmissionEn?: InputMaybe<StringOperationFilterInput>;
  userDescription?: InputMaybe<StringOperationFilterInput>;
  vin?: InputMaybe<StringOperationFilterInput>;
  year?: InputMaybe<IntOperationFilterInput>;
};

export type StockCarSortInput = {
  akebonoLotId?: InputMaybe<SortEnumType>;
  akebonoLotSourceOriginalId?: InputMaybe<SortEnumType>;
  akebonoLotSourceProvider?: InputMaybe<SortEnumType>;
  akebonoLotSourceSubgroup?: InputMaybe<SortEnumType>;
  auctionList?: InputMaybe<SortEnumType>;
  colorEn?: InputMaybe<SortEnumType>;
  companyEn?: InputMaybe<SortEnumType>;
  departmentId?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  engineVolumeNum?: InputMaybe<SortEnumType>;
  equipmentEn?: InputMaybe<SortEnumType>;
  guid1C?: InputMaybe<SortEnumType>;
  hasTelegram?: InputMaybe<SortEnumType>;
  hasWhatsapp?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  images?: InputMaybe<SortEnumType>;
  inStockDubai?: InputMaybe<SortEnumType>;
  isSold?: InputMaybe<SortEnumType>;
  mileageNum?: InputMaybe<SortEnumType>;
  modelEn?: InputMaybe<SortEnumType>;
  modelGradeEn?: InputMaybe<SortEnumType>;
  modelTypeEn?: InputMaybe<SortEnumType>;
  ownerId?: InputMaybe<SortEnumType>;
  phone?: InputMaybe<SortEnumType>;
  priceInStock?: InputMaybe<SortEnumType>;
  priceInStockCurrency?: InputMaybe<SortEnumType>;
  priceOnDelivery?: InputMaybe<SortEnumType>;
  priceOnDeliveryCurrency?: InputMaybe<SortEnumType>;
  ratingEn?: InputMaybe<SortEnumType>;
  thumbnails?: InputMaybe<SortEnumType>;
  title?: InputMaybe<SortEnumType>;
  transmissionEn?: InputMaybe<SortEnumType>;
  userDescription?: InputMaybe<SortEnumType>;
  vin?: InputMaybe<SortEnumType>;
  year?: InputMaybe<SortEnumType>;
};

export type StringOperationFilterInput = {
  and?: InputMaybe<Array<StringOperationFilterInput>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  eq?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ncontains?: InputMaybe<Scalars['String']['input']>;
  nendsWith?: InputMaybe<Scalars['String']['input']>;
  neq?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  nstartsWith?: InputMaybe<Scalars['String']['input']>;
  or?: InputMaybe<Array<StringOperationFilterInput>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type MochironDumpsLotQueryVariables = Exact<{
  id: Scalars['Long']['input'];
}>;


export type MochironDumpsLotQuery = { __typename?: 'Query', lot?: { __typename?: 'StockCar', id: number, guid1C?: string | null, images?: string | null, thumbnails?: string | null, vin?: string | null, year?: number | null, title?: string | null, companyEn?: string | null, modelEn?: string | null, modelTypeEn?: string | null, modelGradeEn?: string | null, colorEn?: string | null, mileageNum?: number | null, engineVolumeNum?: number | null, ratingEn?: string | null, equipmentEn?: string | null, transmissionEn?: string | null, priceInStock?: number | null, priceInStockCurrency?: string | null, priceOnDelivery?: number | null, priceOnDeliveryCurrency?: string | null, description?: string | null, akebonoLotId?: number | null, akebonoLotSourceOriginalId?: string | null, akebonoLotSourceSubgroup?: string | null, akebonoLotSourceProvider?: string | null, isSold?: boolean | null, inStockDubai?: boolean | null, departmentId?: Department | null, ownerId?: number | null, userDescription?: string | null, phone?: string | null, hasWhatsapp?: boolean | null, hasTelegram?: boolean | null } | null };

export type MochironDumpsLotsListFiltersQueryVariables = Exact<{
  withModel: Scalars['Boolean']['input'];
  filter?: InputMaybe<StockCarFilterInput>;
  sort?: InputMaybe<Array<StockCarSortInput> | StockCarSortInput>;
}>;


export type MochironDumpsLotsListFiltersQuery = { __typename?: 'Query', colors: Array<string | null>, scores: Array<string>, transmissions: Array<string>, frames?: Array<{ __typename?: 'Frame', name?: string | null, count: number }>, companies: Array<{ __typename?: 'Company', id?: string | null, name?: string | null }>, models: Array<{ __typename?: 'Model', id?: string | null, name: string, companyId?: string | null, lotsCount: number }> };

export type MochironDumpsLotsListQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  filter?: InputMaybe<StockCarFilterInput>;
  sort?: InputMaybe<Array<StockCarSortInput> | StockCarSortInput>;
}>;


export type MochironDumpsLotsListQuery = { __typename?: 'Query', lots?: { __typename?: 'LotsPaginatedListCollectionSegment', totalCount: number, items?: Array<{ __typename?: 'StockCar', id: number, guid1C?: string | null, images?: string | null, thumbnails?: string | null, vin?: string | null, year?: number | null, title?: string | null, companyEn?: string | null, modelEn?: string | null, modelTypeEn?: string | null, modelGradeEn?: string | null, colorEn?: string | null, mileageNum?: number | null, engineVolumeNum?: number | null, ratingEn?: string | null, equipmentEn?: string | null, transmissionEn?: string | null, priceInStock?: number | null, priceInStockCurrency?: string | null, priceOnDelivery?: number | null, priceOnDeliveryCurrency?: string | null, description?: string | null, akebonoLotId?: number | null, akebonoLotSourceOriginalId?: string | null, akebonoLotSourceSubgroup?: string | null, akebonoLotSourceProvider?: string | null, isSold?: boolean | null, inStockDubai?: boolean | null }> | null } | null };

export const MochironDumpsLotDocument = gql`
    query mochironDumpsLot($id: Long!) {
  lot(id: $id) {
    id
    guid1C
    images
    thumbnails
    vin
    year
    title
    companyEn
    modelEn
    modelTypeEn
    modelGradeEn
    colorEn
    mileageNum
    engineVolumeNum
    ratingEn
    equipmentEn
    transmissionEn
    priceInStock
    priceInStockCurrency
    priceOnDelivery
    priceOnDeliveryCurrency
    description
    akebonoLotId
    akebonoLotSourceOriginalId
    akebonoLotSourceSubgroup
    akebonoLotSourceProvider
    guid1C
    isSold
    inStockDubai
    departmentId
    ownerId
    userDescription
    phone
    hasWhatsapp
    hasTelegram
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MochironDumpsLotGQL extends Apollo.Query<MochironDumpsLotQuery, MochironDumpsLotQueryVariables> {
    document = MochironDumpsLotDocument;
    client = 'mochiron-dumps-main';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MochironDumpsLotsListFiltersDocument = gql`
    query mochironDumpsLotsListFilters($withModel: Boolean!, $filter: StockCarFilterInput, $sort: [StockCarSortInput!]) {
  frames(where: $filter, order: $sort) @include(if: $withModel) {
    name
    count
  }
  colors(where: $filter, order: $sort)
  scores(where: $filter, order: $sort)
  transmissions(where: $filter, order: $sort)
  companies(where: $filter) {
    id
    name
  }
  models(where: $filter) {
    id
    name
    companyId
    lotsCount
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MochironDumpsLotsListFiltersGQL extends Apollo.Query<MochironDumpsLotsListFiltersQuery, MochironDumpsLotsListFiltersQueryVariables> {
    document = MochironDumpsLotsListFiltersDocument;
    client = 'mochiron-dumps-main';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MochironDumpsLotsListDocument = gql`
    query mochironDumpsLotsList($first: Int, $offset: Int, $filter: StockCarFilterInput, $sort: [StockCarSortInput!]) {
  lots: lotsPaginatedList(
    take: $first
    skip: $offset
    where: $filter
    order: $sort
  ) {
    items {
      id
      guid1C
      images
      thumbnails
      vin
      year
      title
      companyEn
      modelEn
      modelTypeEn
      modelGradeEn
      colorEn
      mileageNum
      engineVolumeNum
      ratingEn
      equipmentEn
      transmissionEn
      priceInStock
      priceInStockCurrency
      priceOnDelivery
      priceOnDeliveryCurrency
      description
      akebonoLotId
      akebonoLotSourceOriginalId
      akebonoLotSourceSubgroup
      akebonoLotSourceProvider
      isSold
      inStockDubai
    }
    totalCount
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MochironDumpsLotsListGQL extends Apollo.Query<MochironDumpsLotsListQuery, MochironDumpsLotsListQueryVariables> {
    document = MochironDumpsLotsListDocument;
    client = 'mochiron-dumps-main';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {}
};
      export default result;
    