<nz-row nzJustify="start">
  <nz-col nzSpan="24">
    <nz-row style="margin-top: 20px">
      <nz-breadcrumb nzSeparator=">">
        <nz-breadcrumb-item>
          <a [routerLink]="['']" translate>Vehicle Search</a>
        </nz-breadcrumb-item>
        <nz-breadcrumb-item>
          <a [routerLink]="['/', routeWay, 'listings']" translate>{{ breadcrumb }}</a>
        </nz-breadcrumb-item>
        <nz-breadcrumb-item *ngIf="lotId">
          <a [routerLink]="['.']">#{{ lotId }}</a>
        </nz-breadcrumb-item>
      </nz-breadcrumb>
    </nz-row>

    <nz-spin nzSize="large" [nzSpinning]="loading$ | async">
      <header style="margin-top: 20px">
        <nz-result
          *ngIf="!(data$ | async)?.lot && !(loading$ | async)"
          nzStatus="404"
          [nzTitle]="'Listing not found' | translate"
        ></nz-result>

        <h2 *ngIf="(data$ | async)?.lot as lot">
          <strong>
            <span class="company">{{ lot.companyEn }} {{ lot.modelEn }}</span> - #{{ lot.id }}
            <span *ngIf="lot.isSold">
              <img src="assets/images/sold-sticker.svg" style="width: 70px" />
            </span>
          </strong>
        </h2>
      </header>

      <ng-container *ngIf="data$ | async as data">
        <main *ngIf="data?.lot" nz-row nzGutter="16">
          <aside nz-col [nzXs]="{ span: 24, order: 1 }" [nzLg]="{ span: 14, order: 2 }">
            <app-lot-carousel
              style="margin-bottom: 20px"
              [images]="getImagesList(data.lot)"
            ></app-lot-carousel>
          </aside>

          <aside nz-col [nzXs]="{ span: 24, order: 2 }" [nzLg]="{ span: 10, order: 1 }">
            <section>
              <nz-card [nzTitle]="title">
                <ng-template #title>
                  <nz-row nzAlign="top" nzJustify="space-between">
                    <nz-col *ngIf="data.lot.priceOnDelivery">
                      {{ 'Price On Delivery' | translate }}
                      <br />
                      <h1 style="font-size: 32px">
                        {{
                          data.lot.priceOnDelivery
                            | currency: data.lot.priceOnDeliveryCurrency:'symbol-narrow':'1.0-2'
                        }}
                      </h1>
                    </nz-col>
                    <nz-col *ngIf="data.lot.priceInStock">
                      {{ 'Price In Stock' | translate }}
                      <br />
                      <h1 style="font-size: 32px">
                        {{
                          data.lot.priceInStock
                            | currency: data.lot.priceInStockCurrency:'symbol-narrow':'1.0-2'
                        }}
                      </h1>
                    </nz-col>
                    <nz-col></nz-col>
                    <nz-col>
                      <button nz-button nzType="default" nzSize="large" (click)="copyToClipboard()">
                        <span
                          nz-icon
                          nzType="share-alt"
                          nzTheme="outline"
                          style="cursor: pointer"
                        ></span>
                        {{ 'SHARED.SHARE' | translate }}
                      </button>
                    </nz-col>
                  </nz-row>
                  <nz-row nzJustify="space-between">
                    <nz-col>
                      <span translate>Year</span><br />
                      <h3>{{ data.lot.year }}</h3>
                    </nz-col>
                    <nz-col>
                      <span translate>Grade</span><br />
                      <h3>{{ data.lot.modelEn }}</h3>
                    </nz-col>
                    <nz-col *ngIf="data.lot.transmissionEn">
                      <span translate>Mission</span><br />
                      <h3>{{ data.lot.transmissionEn }}</h3>
                    </nz-col>
                    <nz-col *ngIf="data.lot.colorEn">
                      <span translate>Color</span><br />
                      <h3>
                        {{ data.lot.colorEn | titlecase }}
                      </h3>
                    </nz-col>
                    <nz-col>
                      <span translate>Mileage</span><br />
                      <h3>{{ data.lot.mileageNum * 1000 | number }} km</h3>
                    </nz-col>
                  </nz-row>
                </ng-template>
                <h1>
                  <fa-icon [fixedWidth]="true" [icon]="faLocationPin"></fa-icon>
                  {{ country | titlecase }}
                </h1>
                <h1 *ngIf="data.lot.engineVolumeNum">
                  <fa-icon [fixedWidth]="true" [icon]="faCarBattery"></fa-icon>
                  {{ data.lot.engineVolumeNum | number }}
                  cm3
                </h1>
                <h1>
                  <fa-icon [fixedWidth]="true" [icon]="faRegistered"></fa-icon>
                  {{ data.lot.vin | vinMask }}
                </h1>
                <app-lot-contacts
                  [lot]="data.lot"
                  *ngIf="!data.lot.isSold && isGreenCorner"
                ></app-lot-contacts>
                <app-contact-form
                  style="margin-top: 24px"
                  *ngIf="!data.lot.isSold && (isGreenCorner ? !data.lot?.phone : true)"
                  [departmentId]="data.lot.departmentId"
                  [isGreenCorner]="isGreenCorner"
                  [lotId]="data.lot.akebonoLotSourceOriginalId || data.lot.id.toString()"
                  [lotSource]="data.lot.akebonoLotSourceProvider || 'mochiron'"
                  [lotType]="data.lot.akebonoLotSourceSubgroup || 'oneprice'"
                  [objectGUID]="data.lot.guid1C"
                  [currentUser]="data.currentUser"
                  [wholesalePrice]="data.lot.priceInStock || data.lot.priceOnDelivery || null"
                  [wholesalePriceCurrency]="
                    data.lot.priceInStockCurrency || data.lot.priceOnDeliveryCurrency || null
                  "
                ></app-contact-form>
              </nz-card>
            </section>
          </aside>
        </main>
      </ng-container>
    </nz-spin>
  </nz-col>
</nz-row>
