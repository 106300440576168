import { QueryHandlingService } from '@akebono/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { faRegistered } from '@fortawesome/free-solid-svg-icons';
import { NzMessageService } from 'ng-zorro-antd/message';
import { combineLatest, map, Observable, shareReplay, switchMap, tap } from 'rxjs';
import {
  AkebonoLotSource,
  AkebonoLotTypeEnum,
  CurrentUserGQL,
  CurrentUserQuery,
  CurrentUserQueryVariables,
} from 'src/app/modules/graphql/service/akebono-main-service';
import {
  LotGQL,
  LotQuery,
  LotQueryVariables,
} from 'src/app/modules/graphql/service/graphql-aucnet-moto-service';

@Component({
  selector: 'app-lot',
  templateUrl: './lot.component.html',
  styleUrls: ['./lot.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LotComponent {
  lotId$: Observable<string>;
  lot$: Observable<LotQuery['lot']>;
  currentUser$: Observable<CurrentUserQuery['currentUser']>;

  loading$: Observable<boolean>;

  lotSource = AkebonoLotSource.Aucnet;
  lotType = AkebonoLotTypeEnum.Moto;

  faRegistered = faRegistered;

  constructor(
    title: Title,
    route: ActivatedRoute,
    qhs: QueryHandlingService,
    private readonly clipboard: Clipboard,
    private readonly message: NzMessageService,
    lotGQL: LotGQL,
    currentUserGQL: CurrentUserGQL,
  ) {
    this.lotId$ = route.params.pipe(
      map((params) => params.lot),
      shareReplay(1),
    );

    const lotQueryRef$ = this.lotId$.pipe(
      map((lotId) => qhs.fetch<LotQuery, LotQueryVariables>(lotGQL, { id: lotId }, 'network-only')),
      shareReplay(1),
    );
    const currentUserQueryRef = qhs.fetch<CurrentUserQuery, CurrentUserQueryVariables>(
      currentUserGQL,
      {},
      'network-only',
    );

    this.loading$ = combineLatest([
      currentUserQueryRef.loading,
      lotQueryRef$.pipe(switchMap((ref) => ref.loading)),
    ]).pipe(
      map((loadings) => loadings.some((l) => !!l)),
      shareReplay(1),
    );

    this.currentUser$ = currentUserQueryRef.data.pipe(map((data) => data.currentUser));
    this.lot$ = lotQueryRef$.pipe(
      switchMap((ref) => ref.data),
      map((data) => data.lot),
      tap((lot) => {
        title.setTitle(`Akebono World Car #${lot.id}`);
        scrollTo({ top: 0 });
      }),
      shareReplay(1),
    );
  }

  copyToClipboard(): void {
    this.clipboard.copy(window.location.href);
    this.message.success('Link copied', {
      nzDuration: 2000,
    });
  }
}
