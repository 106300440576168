<div class="box" *ngIf="lot?.userDescription">
  <div class="box-header">{{'LOT_DESCRIPTION' | translate}}</div>
  <div class="box-content">{{lot.userDescription}}</div>
</div>
<div class="box" *ngIf="lot?.phone">
  <div class="box-header">{{'LOT_CONTACTS' | translate}}</div>
  <div class="box-content phone">
    <a class="box-link box-link-phone" href="tel:{{lot.phone}}">{{lot.phone}}</a>
    <a class="box-link box-link-icon" target="_blank" href="https://wa.me/{{lot.phone}}" *ngIf="lot.hasWhatsapp">
      <img class="box-icon"
           src="./assets/img/icons/whatsapp.svg"
           title="WhatsApp"
           alt="WhatsApp"/>
    </a>
    <img class="box-icon"
         src="./assets/img/icons/telegram.svg"
         title="Telegram"
         alt="Telegram"
         *ngIf="lot.hasWhatsapp" />
  </div>
</div>
<button [routerLink]="['/green/car/user', lot.ownerId]"
        class="more-btn"
        nz-button
        nzType="default"
        nzBlock>{{'LOT_ALL_USER_LOTS' | translate}}</button>
