<nz-content>
  <div nz-row nzJustify="center" nzAlign="middle">
    <div nz-col class="card-lot-type" nzSpan="24" nzXl="18" nzXXl="18">
      <div
        nz-row
        nzAlign="middle"
        nzJustify="center"
        [nzGutter]="[20, { xs: 5, sm: 5, lg: 25, xl: 25, xxl: 25 }]"
        style="width: 100%"
      >
        <!-- <div nz-col nzXs="24" nzSm="24" nzMd="8" nzLg="8" nzXl="8" nzXXl="8">
          <a routerLink="/dubai/listings" class="link-panel">
            <img src="assets/img/rec4.jpg" alt="Dubai Cars Stock" />
            <span class="card-header" translate>Dubai Cars Stock</span>
          </a>
        </div> -->
        <div nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="12" nzXl="12" nzXXl="12">
          <a routerLink="/mongolia/listings" class="link-panel">
            <img src="assets/img/rec10.jpg" alt="Mongolia Stock" />
            <span class="card-header" translate>Mongolia Stock</span>
          </a>
        </div>
        <div nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="12" nzXl="12" nzXXl="12">
          <a routerLink="/green/listings" class="link-panel">
            <img src="assets/img/rec6.jpg" alt="Green Corner" />
            <span class="card-header" translate> Green Corner </span>
          </a>
        </div>
        <div nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="12" nzXl="12" nzXXl="12">
          <a routerLink="/listing" class="link-panel">
            <img src="assets/img/rec8.jpg" alt="Japan Stock" />
            <span class="card-header" translate>Japan Stock</span>
          </a>
        </div>
        <div nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="12" nzXl="12" nzXXl="12">
          <a routerLink="/recycle/listings" class="link-panel">
            <img src="assets/img/rec7.jpg" alt="Toyama Recycle" />
            <span class="card-header" translate>Toyama Recycle</span>
          </a>
        </div>
        <div nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="12" nzXl="12" nzXXl="12">
          <a routerLink="/moto/listings" class="link-panel">
            <img src="assets/img/moto-banner.jpg" alt="Motorcycles" />
            <span class="card-header" translate>Motorcycles</span>
          </a>
        </div>
      </div>
    </div>
  </div>
  <nz-row class="moto" nzJustify="center" style="margin-top: 70px">
    <nz-col>
      <p style="text-align: center" translate>
        Looking for a reliable partner for delivering cars from Japanese auctions worldwide?
      </p>
    </nz-col>
  </nz-row>
  <nz-row class="moto-head" nzJustify="center">
    <nz-col><p style="text-align: center" translate>Akebono is your choice!</p></nz-col>
  </nz-row>
  <nz-row class="moto" nzJustify="center" style="margin-top: 53px; margin-bottom: 70px">
    <nz-col nzSpan="24" style="max-width: 1230px">
      <p style="text-align: center" translate>
        We specialize in international car delivery and have official representations in Mongolia,
        Philippines, Dubai and Russian<br />Federation. Reliability and professionalism in every
        shipment.
      </p>
    </nz-col>
  </nz-row>
  <nz-row *ngIf="loading" nzType="flex" nzJustify="center" nzAlign="middle" style="height: 90vh">
    <nz-spin nzSimple [nzSize]="'large'"></nz-spin>
  </nz-row>
  <div class="content" *ngIf="!loading">
    <nz-row
      nzJustify="space-around"
      class="companies-links"
      style="max-width: 1230px; margin: auto"
    >
      <a
        nz-button
        nz-col
        nzXs="11"
        nzSm="11"
        nzMd="8"
        nzLg="6"
        nzXl="4"
        nzXXl="4"
        nzType="link"
        nzSize="large"
        class="top"
        *ngFor="let company of onlyTopCompanies"
        [routerLink]="['/listing']"
        [queryParams]="{ company: company.name }"
      >
        <span nz-icon>
          <img width="48" src="/assets/thumb/{{ company.name | lowercase }}.png" />
        </span>
        {{ company.name }}
      </a>
    </nz-row>
    <nz-divider></nz-divider>
    <div class="columns">
      <div class="no-break" *ngFor="let item of remainingCompanies | keyvalue">
        <h3>{{ item.key }}</h3>
        <ng-container *ngFor="let company of item.value">
          <a
            [routerLink]="['/listing']"
            [queryParams]="{ company: company.name }"
            class="link-item"
          >
            {{ company.name }}
          </a>
          <br />
        </ng-container>
      </div>
    </div>
  </div>
</nz-content>
