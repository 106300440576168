import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { NzImage, NzImageService } from 'ng-zorro-antd/image';
import Swiper, { SwiperOptions } from 'swiper';
import { SwiperComponent } from 'swiper/angular';

@Component({
  selector: 'app-lot-carousel',
  templateUrl: './lot-carousel.component.html',
  styleUrls: ['./lot-carousel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LotCarouselComponent implements OnInit, OnChanges {
  @Input() images: string[] = [];

  @ViewChild('swiper', { static: false }) swiper?: SwiperComponent;

  albums: NzImage[] = [];
  activeSlide: number = 0;

  configSwiper: SwiperOptions = {
    loop: true,
    keyboard: false,
    slidesPerView: 1,
    spaceBetween: 0,
    navigation: true,
  };

  constructor(private imageService: NzImageService) {}

  ngOnInit(): void {
    this.createAlbums();
  }

  setActiveSlide(index: number): void {
    this.activeSlide = index;
  }

  onClickThumb(index: number): void {
    this.swiper.swiperRef.slideTo(index);
  }

  createAlbums(): void {
    this.albums = [];
    this.images.forEach((image) => {
      this.albums.push({
        src: image.replace('akebono.world/640x480/', 'akebono.world/'),
      });
    });
  }

  openPreview(index: number): void {
    this.imageService.preview(this.albums, { nzKeyboard: true }).switchTo(index);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.images?.currentValue?.length !== changes.images?.previousValue?.length) {
      this.createAlbums();
    }
  }
}
