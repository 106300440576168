import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { LotMiniGalleryComponent } from './lot-mini-gallery.component';

@NgModule({
  declarations: [LotMiniGalleryComponent],
  imports: [CommonModule],
  exports: [LotMiniGalleryComponent],
})
export class LotMiniGalleryModule {}
