import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { InputPhoneNumberComponent } from './input-phone-number.component';
import { CountryPipe } from './country.pipe';
import { CountryService } from './country.service';
import { PhoneMaskCursorProcessorDirective } from './phone.directive';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzInputModule } from 'ng-zorro-antd/input';

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, NzSelectModule, NzInputModule],
  declarations: [InputPhoneNumberComponent, PhoneMaskCursorProcessorDirective, CountryPipe],
  exports: [InputPhoneNumberComponent, CountryPipe],
  providers: [CountryService],
})
export class InputPhoneNumberModule {
  static forRoot(): ModuleWithProviders<InputPhoneNumberModule> {
    return {
      ngModule: InputPhoneNumberModule,
      providers: [CountryService],
    };
  }
}
