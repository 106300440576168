import { BirthdayGreetingsService } from '@akebono/core';
import { Component, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent {
  listOfData = [];
  topCompanies = [1, 2, 3, 4, 5, 6, 7, 8, 9].map((id) => id.toString());
  onlyTopCompanies = [];
  remainingCompanies: object = {};
  loading = false;

  companies = [
    {
      id: '334',
      companyId: '334',
      name: 'ABARTH',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '158',
      companyId: '158',
      name: 'AC',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '11',
      companyId: '11',
      name: 'ACURA',
      country: 'USA',
      __typename: 'AutoCompany',
    },
    {
      id: '159',
      companyId: '159',
      name: 'AEROLITE',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '160',
      companyId: '160',
      name: 'AIRSTREAM',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '161',
      companyId: '161',
      name: 'ALFA',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '22',
      companyId: '22',
      name: 'ALFA ROMEO',
      country: 'ITALY',
      __typename: 'AutoCompany',
    },
    {
      id: '12',
      companyId: '12',
      name: 'ALFAROMEO',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '162',
      companyId: '162',
      name: 'ALJO',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '163',
      companyId: '163',
      name: 'AM GENERAL',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '102',
      companyId: '102',
      name: 'AMC',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '104',
      companyId: '104',
      name: 'AMC JEEP',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '105',
      companyId: '105',
      name: 'AMERICA HONDA',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '106',
      companyId: '106',
      name: 'AMERICA MAZDA',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '107',
      companyId: '107',
      name: 'AMERICA MITSUBISHI',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '108',
      companyId: '108',
      name: 'AMERICA NISSAN',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '109',
      companyId: '109',
      name: 'AMERICA SUZUKI',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '110',
      companyId: '110',
      name: 'AMERICA TOYOTA',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '164',
      companyId: '164',
      name: 'AMERICAN (AMC)',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '111',
      companyId: '111',
      name: 'AMG',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '165',
      companyId: '165',
      name: 'APRILIA',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '166',
      companyId: '166',
      name: 'ARCTIC CAT',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '112',
      companyId: '112',
      name: 'ASTON MARTIN',
      country: 'ENGLAND',
      __typename: 'AutoCompany',
    },
    {
      id: '167',
      companyId: '167',
      name: 'ASUNA',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '168',
      companyId: '168',
      name: 'ATLAS',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '169',
      companyId: '169',
      name: 'ATWOOD',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '13',
      companyId: '13',
      name: 'AUDI',
      country: 'GERMANY',
      __typename: 'AutoCompany',
    },
    {
      id: '113',
      companyId: '113',
      name: 'AUSTIN',
      country: 'ENGLAND',
      __typename: 'AutoCompany',
    },
    {
      id: '335',
      companyId: '335',
      name: 'AUTOBIANCHI',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '114',
      companyId: '114',
      name: 'AVANTI',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '170',
      companyId: '170',
      name: 'AVANTI II',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '171',
      companyId: '171',
      name: 'AVION',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '172',
      companyId: '172',
      name: 'BAJA',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '173',
      companyId: '173',
      name: 'BAYLINER',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '174',
      companyId: '174',
      name: 'BELSHE',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '115',
      companyId: '115',
      name: 'BENTLEY',
      country: 'ENGLAND',
      __typename: 'AutoCompany',
    },
    {
      id: '175',
      companyId: '175',
      name: 'BERTONE',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '176',
      companyId: '176',
      name: 'BIG DOG',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '177',
      companyId: '177',
      name: 'BIMOTA',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '116',
      companyId: '116',
      name: 'BIRKIN',
      country: 'HOLLAND',
      __typename: 'AutoCompany',
    },
    {
      id: '178',
      companyId: '178',
      name: 'BLUEBIRD',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '14',
      companyId: '14',
      name: 'BMW',
      country: 'GERMANY',
      __typename: 'AutoCompany',
    },
    {
      id: '117',
      companyId: '117',
      name: 'BMW ALPINA',
      country: 'GERMANY',
      __typename: 'AutoCompany',
    },
    {
      id: '179',
      companyId: '179',
      name: 'BOMBARDIER',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '180',
      companyId: '180',
      name: 'BOURGETS',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '181',
      companyId: '181',
      name: 'BUCK DANDY',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '182',
      companyId: '182',
      name: 'BUELL',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '183',
      companyId: '183',
      name: 'BUGATTI',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '118',
      companyId: '118',
      name: 'BUICK',
      country: 'USA',
      __typename: 'AutoCompany',
    },
    {
      id: '119',
      companyId: '119',
      name: 'CADILLAC',
      country: 'USA',
      __typename: 'AutoCompany',
    },
    {
      id: '184',
      companyId: '184',
      name: 'CAMPAGNA',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '185',
      companyId: '185',
      name: 'CASE',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '120',
      companyId: '120',
      name: 'CATERHAM',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '337',
      companyId: '337',
      name: 'CATERPILLAR',
      country: 'USA',
      __typename: 'AutoCompany',
    },
    {
      id: '186',
      companyId: '186',
      name: 'CHAMPION',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '187',
      companyId: '187',
      name: 'CHECKER',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '121',
      companyId: '121',
      name: 'CHEVROLET',
      country: 'USA',
      __typename: 'AutoCompany',
    },
    {
      id: '15',
      companyId: '15',
      name: 'CHRYSLER',
      country: 'USA',
      __typename: 'AutoCompany',
    },
    {
      id: '122',
      companyId: '122',
      name: 'CHRYSLER JEEP',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '188',
      companyId: '188',
      name: 'CITATION',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '16',
      companyId: '16',
      name: 'CITROEN',
      country: 'FRANCE',
      __typename: 'AutoCompany',
    },
    {
      id: '189',
      companyId: '189',
      name: 'CLUB CART',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '190',
      companyId: '190',
      name: 'COACHMAN',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '191',
      companyId: '191',
      name: 'COBRA',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '192',
      companyId: '192',
      name: 'COLEMAN',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '193',
      companyId: '193',
      name: 'CROSS LANDER',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '194',
      companyId: '194',
      name: 'CROWNLINE',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '333',
      companyId: '333',
      name: 'CT T',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '195',
      companyId: '195',
      name: 'CUSHMAN',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '123',
      companyId: '123',
      name: 'DAEWOO',
      country: 'KOREA',
      __typename: 'AutoCompany',
    },
    {
      id: '9',
      companyId: '9',
      name: 'DAIHATSU',
      country: 'JAPAN',
      __typename: 'AutoCompany',
    },
    {
      id: '17',
      companyId: '17',
      name: 'DAIMLER',
      country: 'ENGLAND',
      __typename: 'AutoCompany',
    },
    {
      id: '196',
      companyId: '196',
      name: 'DAMON',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '197',
      companyId: '197',
      name: 'DATSUN',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '198',
      companyId: '198',
      name: 'DELOREAN',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '199',
      companyId: '199',
      name: 'DIAMO',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '200',
      companyId: '200',
      name: 'DINLI',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '201',
      companyId: '201',
      name: 'DITCHWITCH',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '124',
      companyId: '124',
      name: 'DODGE',
      country: 'USA',
      __typename: 'AutoCompany',
    },
    {
      id: '332',
      companyId: '332',
      name: 'DONKERVOORT',
      country: 'HOLLAND',
      __typename: 'AutoCompany',
    },
    {
      id: '202',
      companyId: '202',
      name: 'DORSEY',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '203',
      companyId: '203',
      name: 'DUCATI',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '204',
      companyId: '204',
      name: 'DUTCHMEN',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '205',
      companyId: '205',
      name: 'EAGLE',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '206',
      companyId: '206',
      name: 'ECONOBOY',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '207',
      companyId: '207',
      name: 'ELDORADO',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '100',
      companyId: '100',
      name: 'EUNOS',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '125',
      companyId: '125',
      name: 'EUROPE FORD',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '338',
      companyId: '338',
      name: 'EUROPEAN FORD',
      country: 'GERMANY',
      __typename: 'AutoCompany',
    },
    {
      id: '208',
      companyId: '208',
      name: 'EXCALIBUR',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '209',
      companyId: '209',
      name: 'EXTREME',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '210',
      companyId: '210',
      name: 'EZGO',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '126',
      companyId: '126',
      name: 'FERRARI',
      country: 'ITALY',
      __typename: 'AutoCompany',
    },
    {
      id: '18',
      companyId: '18',
      name: 'FIAT',
      country: 'ITALY',
      __typename: 'AutoCompany',
    },
    {
      id: '211',
      companyId: '211',
      name: 'FLEETWOOD',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '19',
      companyId: '19',
      name: 'FORD',
      country: 'USA',
      __typename: 'AutoCompany',
    },
    {
      id: '101',
      companyId: '101',
      name: 'FORD JAPAN',
      country: 'JAPAN',
      __typename: 'AutoCompany',
    },
    {
      id: '212',
      companyId: '212',
      name: 'FOREST RIVER',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '213',
      companyId: '213',
      name: 'FREIGHTLINER',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '214',
      companyId: '214',
      name: 'FRUEHAUF',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '215',
      companyId: '215',
      name: 'GEM',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '216',
      companyId: '216',
      name: 'GEO',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '217',
      companyId: '217',
      name: 'GILIG',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '20',
      companyId: '20',
      name: 'GM',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '127',
      companyId: '127',
      name: 'GM MATIZ',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '128',
      companyId: '128',
      name: 'GMC',
      country: 'USA',
      __typename: 'AutoCompany',
    },
    {
      id: '218',
      companyId: '218',
      name: 'GREATDANE',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '219',
      companyId: '219',
      name: 'GULFSTREAM',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '339',
      companyId: '339',
      name: 'HANIX',
      country: 'JAPAN',
      __typename: 'AutoCompany',
    },
    {
      id: '220',
      companyId: '220',
      name: 'HARLEY DAVIDSON',
      country: 'USA',
      __typename: 'AutoCompany',
    },
    {
      id: '221',
      companyId: '221',
      name: 'HAULMARK',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '222',
      companyId: '222',
      name: 'HERITAGE',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '21',
      companyId: '21',
      name: 'HINO',
      country: 'JAPAN',
      __typename: 'AutoCompany',
    },
    {
      id: '340',
      companyId: '340',
      name: 'HITACHI',
      country: 'JAPAN',
      __typename: 'AutoCompany',
    },
    {
      id: '5',
      companyId: '5',
      name: 'HONDA',
      country: 'JAPAN',
      __typename: 'AutoCompany',
    },
    {
      id: '129',
      companyId: '129',
      name: 'HUMMER',
      country: 'USA',
      __typename: 'AutoCompany',
    },
    {
      id: '223',
      companyId: '223',
      name: 'HUSQVARNA',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '224',
      companyId: '224',
      name: 'HYMOTO',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '225',
      companyId: '225',
      name: 'HYOSUNG',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '130',
      companyId: '130',
      name: 'HYUNDAI',
      country: 'KOREA',
      __typename: 'AutoCompany',
    },
    {
      id: '226',
      companyId: '226',
      name: 'IHC',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '227',
      companyId: '227',
      name: 'INDEPENDENT',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '228',
      companyId: '228',
      name: 'INDIAN',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '229',
      companyId: '229',
      name: 'INFINITI',
      country: 'USA',
      __typename: 'AutoCompany',
    },
    {
      id: '131',
      companyId: '131',
      name: 'INFINITY',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '230',
      companyId: '230',
      name: 'INTERNATIONAL',
      country: 'USA',
      __typename: 'AutoCompany',
    },
    {
      id: '231',
      companyId: '231',
      name: 'IRONHORSE',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '341',
      companyId: '341',
      name: 'ISEKI',
      country: 'JAPAN',
      __typename: 'AutoCompany',
    },
    {
      id: '8',
      companyId: '8',
      name: 'ISUZU',
      country: 'JAPAN',
      __typename: 'AutoCompany',
    },
    {
      id: '232',
      companyId: '232',
      name: 'IVECO',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '132',
      companyId: '132',
      name: 'JAGUAR',
      country: 'ENGLAND',
      __typename: 'AutoCompany',
    },
    {
      id: '133',
      companyId: '133',
      name: 'JAPAN OTHER',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '233',
      companyId: '233',
      name: 'JAYCO',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '234',
      companyId: '234',
      name: 'JEEP',
      country: 'USA',
      __typename: 'AutoCompany',
    },
    {
      id: '235',
      companyId: '235',
      name: 'KARAVAN',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '236',
      companyId: '236',
      name: 'KAWASAKI',
      country: 'JAPAN',
      __typename: 'AutoCompany',
    },
    {
      id: '237',
      companyId: '237',
      name: 'KAZUMA',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '238',
      companyId: '238',
      name: 'KEARNS',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '239',
      companyId: '239',
      name: 'KENWORTH',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '240',
      companyId: '240',
      name: 'KEYSTONE',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '241',
      companyId: '241',
      name: 'KIA',
      country: 'KOREA',
      __typename: 'AutoCompany',
    },
    {
      id: '242',
      companyId: '242',
      name: 'KIDRON',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '342',
      companyId: '342',
      name: 'KOBELCO',
      country: 'JAPAN',
      __typename: 'AutoCompany',
    },
    {
      id: '343',
      companyId: '343',
      name: 'KOMATSU',
      country: 'JAPAN',
      __typename: 'AutoCompany',
    },
    {
      id: '243',
      companyId: '243',
      name: 'KTM',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '344',
      companyId: '344',
      name: 'KUBOTA',
      country: 'JAPAN',
      __typename: 'AutoCompany',
    },
    {
      id: '244',
      companyId: '244',
      name: 'KYMCO',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '245',
      companyId: '245',
      name: 'LADA',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '246',
      companyId: '246',
      name: 'LAFORZA',
      country: 'ITALY',
      __typename: 'AutoCompany',
    },
    {
      id: '134',
      companyId: '134',
      name: 'LAMBORGHINI',
      country: 'ITALY',
      __typename: 'AutoCompany',
    },
    {
      id: '135',
      companyId: '135',
      name: 'LANCIA',
      country: 'ITALY',
      __typename: 'AutoCompany',
    },
    {
      id: '247',
      companyId: '247',
      name: 'LAND ALL',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '34',
      companyId: '34',
      name: 'LAND ROVER',
      country: 'ENGLAND',
      __typename: 'AutoCompany',
    },
    {
      id: '248',
      companyId: '248',
      name: 'LARSON',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '23',
      companyId: '23',
      name: 'LEXUS',
      country: 'JAPAN',
      __typename: 'AutoCompany',
    },
    {
      id: '35',
      companyId: '35',
      name: 'LINCOLN',
      country: 'USA',
      __typename: 'AutoCompany',
    },
    {
      id: '136',
      companyId: '136',
      name: 'LOTUS',
      country: 'ENGLAND',
      __typename: 'AutoCompany',
    },
    {
      id: '249',
      companyId: '249',
      name: 'MACK',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '250',
      companyId: '250',
      name: 'MAGIC TILT',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '251',
      companyId: '251',
      name: 'MAHINDRA',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '252',
      companyId: '252',
      name: 'MARQUIS',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '137',
      companyId: '137',
      name: 'MASERATI',
      country: 'ITALY',
      __typename: 'AutoCompany',
    },
    {
      id: '253',
      companyId: '253',
      name: 'MASTER TOW',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '254',
      companyId: '254',
      name: 'MAXUM',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '255',
      companyId: '255',
      name: 'MAYBACH',
      country: 'GERMANY',
      __typename: 'AutoCompany',
    },
    {
      id: '3',
      companyId: '3',
      name: 'MAZDA',
      country: 'JAPAN',
      __typename: 'AutoCompany',
    },
    {
      id: '138',
      companyId: '138',
      name: 'MCC SMART',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '24',
      companyId: '24',
      name: 'MERCEDES BENZ',
      country: 'GERMANY',
      __typename: 'AutoCompany',
    },
    {
      id: '36',
      companyId: '36',
      name: 'MERCURY',
      country: 'USA',
      __typename: 'AutoCompany',
    },
    {
      id: '256',
      companyId: '256',
      name: 'MERKUR',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '33',
      companyId: '33',
      name: 'MG',
      country: 'ENGLAND',
      __typename: 'AutoCompany',
    },
    {
      id: '139',
      companyId: '139',
      name: 'MINI',
      country: 'ENGLAND',
      __typename: 'AutoCompany',
    },
    {
      id: '4',
      companyId: '4',
      name: 'MITSUBISHI',
      country: 'JAPAN',
      __typename: 'AutoCompany',
    },
    {
      id: '140',
      companyId: '140',
      name: 'MITSUBISHI FUSO',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '10',
      companyId: '10',
      name: 'MITSUOKA',
      country: 'JAPAN',
      __typename: 'AutoCompany',
    },
    {
      id: '141',
      companyId: '141',
      name: 'MOKE',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '257',
      companyId: '257',
      name: 'MONTANA',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '258',
      companyId: '258',
      name: 'MONTEREY',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '142',
      companyId: '142',
      name: 'MORGAN',
      country: 'ENGLAND',
      __typename: 'AutoCompany',
    },
    {
      id: '143',
      companyId: '143',
      name: 'MORRIS',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '259',
      companyId: '259',
      name: 'MOTO GUZZI',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '260',
      companyId: '260',
      name: 'NASH',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '261',
      companyId: '261',
      name: 'NATIONAL',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '262',
      companyId: '262',
      name: 'NEWMAR',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '2',
      companyId: '2',
      name: 'NISSAN',
      country: 'JAPAN',
      __typename: 'AutoCompany',
    },
    {
      id: '144',
      companyId: '144',
      name: 'NISSAN DIESEL',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '263',
      companyId: '263',
      name: 'NORTH STAR',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '264',
      companyId: '264',
      name: 'NORTHLAND',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '145',
      companyId: '145',
      name: 'OLDSMOBILE',
      country: 'USA',
      __typename: 'AutoCompany',
    },
    {
      id: '25',
      companyId: '25',
      name: 'OPEL',
      country: 'GERMANY',
      __typename: 'AutoCompany',
    },
    {
      id: '265',
      companyId: '265',
      name: 'ORANGE',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '266',
      companyId: '266',
      name: 'OSHKOSH',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '99',
      companyId: '99',
      name: 'OTHER',
      country: 'OTHER',
      __typename: 'AutoCompany',
    },
    {
      id: '98',
      companyId: '98',
      name: 'OTHERS',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '267',
      companyId: '267',
      name: 'PACE',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '268',
      companyId: '268',
      name: 'PALOMINO',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '269',
      companyId: '269',
      name: 'PANOZ',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '270',
      companyId: '270',
      name: 'PANTERRA',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '271',
      companyId: '271',
      name: 'PASSPORT',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '272',
      companyId: '272',
      name: 'PETERBILT',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '26',
      companyId: '26',
      name: 'PEUGEOT',
      country: 'FRANCE',
      __typename: 'AutoCompany',
    },
    {
      id: '273',
      companyId: '273',
      name: 'PIONEER',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '146',
      companyId: '146',
      name: 'PLYMOUTH',
      country: 'USA',
      __typename: 'AutoCompany',
    },
    {
      id: '274',
      companyId: '274',
      name: 'POLARIS',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '147',
      companyId: '147',
      name: 'PONTIAC',
      country: 'USA',
      __typename: 'AutoCompany',
    },
    {
      id: '148',
      companyId: '148',
      name: 'PORSCHE',
      country: 'GERMANY',
      __typename: 'AutoCompany',
    },
    {
      id: '275',
      companyId: '275',
      name: 'POWER KING',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '276',
      companyId: '276',
      name: 'PRIMO',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '277',
      companyId: '277',
      name: 'PROWLER',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '278',
      companyId: '278',
      name: 'QIANJIANG',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '279',
      companyId: '279',
      name: 'QVALE',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '280',
      companyId: '280',
      name: 'RANGER',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '281',
      companyId: '281',
      name: 'REDCAT',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '27',
      companyId: '27',
      name: 'RENAULT',
      country: 'FRANCE',
      __typename: 'AutoCompany',
    },
    {
      id: '282',
      companyId: '282',
      name: 'RIDLEY',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '149',
      companyId: '149',
      name: 'ROLLS ROYCE',
      country: 'ENGLAND',
      __typename: 'AutoCompany',
    },
    {
      id: '28',
      companyId: '28',
      name: 'ROVER',
      country: 'ENGLAND',
      __typename: 'AutoCompany',
    },
    {
      id: '150',
      companyId: '150',
      name: 'SAAB',
      country: 'SWEDEN',
      __typename: 'AutoCompany',
    },
    {
      id: '284',
      companyId: '284',
      name: 'SAFARI',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '285',
      companyId: '285',
      name: 'SALEEN',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '151',
      companyId: '151',
      name: 'SATURN',
      country: 'USA',
      __typename: 'AutoCompany',
    },
    {
      id: '286',
      companyId: '286',
      name: 'SCION',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '287',
      companyId: '287',
      name: 'SCORPION',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '288',
      companyId: '288',
      name: 'SEA RAY',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '289',
      companyId: '289',
      name: 'SEADOO',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '152',
      companyId: '152',
      name: 'SEAT',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '290',
      companyId: '290',
      name: 'SKYLINE',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '291',
      companyId: '291',
      name: 'SMART',
      country: 'GERMANY',
      __typename: 'AutoCompany',
    },
    {
      id: '292',
      companyId: '292',
      name: 'SOUTHWIND',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '29',
      companyId: '29',
      name: 'SPALDING',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '293',
      companyId: '293',
      name: 'SPORTSMAN',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '294',
      companyId: '294',
      name: 'SPYKER',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '153',
      companyId: '153',
      name: 'SSANGYONG',
      country: 'KOREA',
      __typename: 'AutoCompany',
    },
    {
      id: '154',
      companyId: '154',
      name: 'STARCRAFT',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '295',
      companyId: '295',
      name: 'STERLING',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '296',
      companyId: '296',
      name: 'STOUGHTON',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '7',
      companyId: '7',
      name: 'SUBARU',
      country: 'JAPAN',
      __typename: 'AutoCompany',
    },
    {
      id: '345',
      companyId: '345',
      name: 'SUMITOMO',
      country: 'JAPAN',
      __typename: 'AutoCompany',
    },
    {
      id: '297',
      companyId: '297',
      name: 'SUN RAY',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '298',
      companyId: '298',
      name: 'SUNDOWNER',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '299',
      companyId: '299',
      name: 'SUNL',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '300',
      companyId: '300',
      name: 'SUNNYGIRL',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '6',
      companyId: '6',
      name: 'SUZUKI',
      country: 'JAPAN',
      __typename: 'AutoCompany',
    },
    {
      id: '346',
      companyId: '346',
      name: 'TADANO',
      country: 'JAPAN',
      __typename: 'AutoCompany',
    },
    {
      id: '301',
      companyId: '301',
      name: 'TALBERT',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '302',
      companyId: '302',
      name: 'TANK',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '30',
      companyId: '30',
      name: 'TCM',
      country: 'JAPAN',
      __typename: 'AutoCompany',
    },
    {
      id: '348',
      companyId: '348',
      name: 'TESLA',
      country: 'USA',
      __typename: 'AutoCompany',
    },
    {
      id: '303',
      companyId: '303',
      name: 'THOMAS',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '304',
      companyId: '304',
      name: 'THOR',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '305',
      companyId: '305',
      name: 'THUNDER',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '336',
      companyId: '336',
      name: 'TIARA',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '306',
      companyId: '306',
      name: 'TIFFIN',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '307',
      companyId: '307',
      name: 'TITAN',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '1',
      companyId: '1',
      name: 'TOYOTA',
      country: 'JAPAN',
      __typename: 'AutoCompany',
    },
    {
      id: '308',
      companyId: '308',
      name: 'TRACKER',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '309',
      companyId: '309',
      name: 'TRAILER',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '310',
      companyId: '310',
      name: 'TRAILMOBILE',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '311',
      companyId: '311',
      name: 'TRANSCRAFT',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '312',
      companyId: '312',
      name: 'TRITON',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '313',
      companyId: '313',
      name: 'TRIUMPH',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '155',
      companyId: '155',
      name: 'TVR',
      country: 'ENGLAND',
      __typename: 'AutoCompany',
    },
    {
      id: '314',
      companyId: '314',
      name: 'UNITED',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '315',
      companyId: '315',
      name: 'VANGUARD',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '316',
      companyId: '316',
      name: 'VECTOR',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '317',
      companyId: '317',
      name: 'VENEER',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '318',
      companyId: '318',
      name: 'VENNER',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '319',
      companyId: '319',
      name: 'VENTO',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '330',
      companyId: '330',
      name: 'VENTURI',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '320',
      companyId: '320',
      name: 'VERUCCI',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '321',
      companyId: '321',
      name: 'VICTORY',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '31',
      companyId: '31',
      name: 'VOLKSWAGEN',
      country: 'GERMANY',
      __typename: 'AutoCompany',
    },
    {
      id: '32',
      companyId: '32',
      name: 'VOLVO',
      country: 'SWEDEN',
      __typename: 'AutoCompany',
    },
    {
      id: '322',
      companyId: '322',
      name: 'WELLCRAFT',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '323',
      companyId: '323',
      name: 'WESTERN STAR',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '156',
      companyId: '156',
      name: 'WESTFIELD',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '324',
      companyId: '324',
      name: 'WILDFIRE',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '325',
      companyId: '325',
      name: 'WILSON',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '157',
      companyId: '157',
      name: 'WINNEBAGO',
      country: 'USA',
      __typename: 'AutoCompany',
    },
    {
      id: '326',
      companyId: '326',
      name: 'YAMAHA',
      country: 'JAPAN',
      __typename: 'AutoCompany',
    },
    {
      id: '327',
      companyId: '327',
      name: 'YAMATI',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '347',
      companyId: '347',
      name: 'YANMAR',
      country: 'JAPAN',
      __typename: 'AutoCompany',
    },
    {
      id: '331',
      companyId: '331',
      name: 'YES',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '328',
      companyId: '328',
      name: 'YUGO',
      country: '',
      __typename: 'AutoCompany',
    },
    {
      id: '329',
      companyId: '329',
      name: 'ZONGSHEN',
      country: '',
      __typename: 'AutoCompany',
    },
  ];

  constructor(private title: Title) {
    this.title.setTitle('Akebono World');

    this.companies.forEach((c) => {
      if (c.companyId !== '0') {
        if (this.topCompanies.includes(c.companyId)) {
          this.onlyTopCompanies.push(c);
        } else {
          const letter = c.name[0];
          if (!this.remainingCompanies[letter]) {
            this.remainingCompanies[letter] = [];
          }
          this.remainingCompanies[letter].push(c);
        }
      }
    });
    this.onlyTopCompanies = this.onlyTopCompanies.reverse();
  }
}
