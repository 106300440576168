import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {MochironDumpsLotQuery} from "../../../graphql/service/graphql-mochiron-dumps-main-service";

@Component({
  selector: 'app-lot-contacts',
  templateUrl: './lot-contacts.component.html',
  styleUrls: ['./lot-contacts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LotContactsComponent {
  @Input() lot: MochironDumpsLotQuery['lot'];
}
