import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { LotTitleFullComponent } from './lot-title-full.component';

@NgModule({
  declarations: [LotTitleFullComponent],
  imports: [CommonModule, TranslateModule],
  exports: [LotTitleFullComponent],
})
export class LotTitleFullModule {}
