import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** The `Byte` scalar type represents non-fractional whole numeric values. Byte can represent values between 0 and 255. */
  Byte: { input: any; output: any; }
  /** The UnsignedInt scalar type represents a unsigned 32-bit numeric non-fractional value greater than or equal to 0. */
  UnsignedInt: { input: any; output: any; }
};

export type ByteOperationFilterInput = {
  eq?: InputMaybe<Scalars['Byte']['input']>;
  gt?: InputMaybe<Scalars['Byte']['input']>;
  gte?: InputMaybe<Scalars['Byte']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Byte']['input']>>>;
  lt?: InputMaybe<Scalars['Byte']['input']>;
  lte?: InputMaybe<Scalars['Byte']['input']>;
  neq?: InputMaybe<Scalars['Byte']['input']>;
  ngt?: InputMaybe<Scalars['Byte']['input']>;
  ngte?: InputMaybe<Scalars['Byte']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Byte']['input']>>>;
  nlt?: InputMaybe<Scalars['Byte']['input']>;
  nlte?: InputMaybe<Scalars['Byte']['input']>;
};

/** Information about the offset pagination. */
export type CollectionSegmentInfo = {
  __typename?: 'CollectionSegmentInfo';
  /** Indicates whether more items exist following the set defined by the clients arguments. */
  hasNextPage: Scalars['Boolean']['output'];
  /** Indicates whether more items exist prior the set defined by the clients arguments. */
  hasPreviousPage: Scalars['Boolean']['output'];
};

export type Company = {
  __typename?: 'Company';
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type FloatOperationFilterInput = {
  eq?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  neq?: InputMaybe<Scalars['Float']['input']>;
  ngt?: InputMaybe<Scalars['Float']['input']>;
  ngte?: InputMaybe<Scalars['Float']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  nlt?: InputMaybe<Scalars['Float']['input']>;
  nlte?: InputMaybe<Scalars['Float']['input']>;
};

export type Frame = {
  __typename?: 'Frame';
  count: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type IntOperationFilterInput = {
  eq?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  neq?: InputMaybe<Scalars['Int']['input']>;
  ngt?: InputMaybe<Scalars['Int']['input']>;
  ngte?: InputMaybe<Scalars['Int']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  nlt?: InputMaybe<Scalars['Int']['input']>;
  nlte?: InputMaybe<Scalars['Int']['input']>;
};

/** A segment of a collection. */
export type LotsPaginatedListCollectionSegment = {
  __typename?: 'LotsPaginatedListCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<SharedInventoryMoto>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type Model = {
  __typename?: 'Model';
  companyId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  lotsCount: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type Query = {
  __typename?: 'Query';
  bodyStyles: Array<Maybe<Scalars['String']['output']>>;
  colors: Array<Maybe<Scalars['String']['output']>>;
  companies: Array<Company>;
  engines: Array<Maybe<Scalars['String']['output']>>;
  frames: Array<Frame>;
  lot?: Maybe<SharedInventoryMoto>;
  lotsPaginatedList?: Maybe<LotsPaginatedListCollectionSegment>;
  models: Array<Model>;
  scores: Array<Scalars['String']['output']>;
  years: Array<Scalars['Int']['output']>;
};


export type QueryBodyStylesArgs = {
  order?: InputMaybe<Array<SharedInventoryMotoSortInput>>;
  where?: InputMaybe<SharedInventoryMotoFilterInput>;
};


export type QueryColorsArgs = {
  order?: InputMaybe<Array<SharedInventoryMotoSortInput>>;
  where?: InputMaybe<SharedInventoryMotoFilterInput>;
};


export type QueryCompaniesArgs = {
  order?: InputMaybe<Array<SharedInventoryMotoSortInput>>;
  where?: InputMaybe<SharedInventoryMotoFilterInput>;
};


export type QueryEnginesArgs = {
  order?: InputMaybe<Array<SharedInventoryMotoSortInput>>;
  where?: InputMaybe<SharedInventoryMotoFilterInput>;
};


export type QueryFramesArgs = {
  order?: InputMaybe<Array<SharedInventoryMotoSortInput>>;
  where?: InputMaybe<SharedInventoryMotoFilterInput>;
};


export type QueryLotArgs = {
  id: Scalars['String']['input'];
};


export type QueryLotsPaginatedListArgs = {
  order?: InputMaybe<Array<SharedInventoryMotoSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SharedInventoryMotoFilterInput>;
};


export type QueryModelsArgs = {
  order?: InputMaybe<Array<SharedInventoryMotoSortInput>>;
  where?: InputMaybe<SharedInventoryMotoFilterInput>;
};


export type QueryScoresArgs = {
  order?: InputMaybe<Array<SharedInventoryMotoSortInput>>;
  where?: InputMaybe<SharedInventoryMotoFilterInput>;
};


export type QueryYearsArgs = {
  order?: InputMaybe<Array<SharedInventoryMotoSortInput>>;
  where?: InputMaybe<SharedInventoryMotoFilterInput>;
};

export type SharedInventoryMoto = {
  __typename?: 'SharedInventoryMoto';
  appearance?: Maybe<Scalars['String']['output']>;
  bodyStyle?: Maybe<Scalars['String']['output']>;
  color?: Maybe<Scalars['String']['output']>;
  colorChange?: Maybe<Scalars['String']['output']>;
  company?: Maybe<Scalars['String']['output']>;
  countryOfOrigin?: Maybe<Scalars['String']['output']>;
  deletionFlag?: Maybe<Scalars['Byte']['output']>;
  engineCc: Scalars['Int']['output'];
  engineModel?: Maybe<Scalars['String']['output']>;
  evaluation?: Maybe<Scalars['String']['output']>;
  features?: Maybe<Scalars['String']['output']>;
  frameNumber?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  images?: Maybe<Scalars['String']['output']>;
  inspectionEra?: Maybe<Scalars['String']['output']>;
  inspectionMonth?: Maybe<Scalars['Int']['output']>;
  inspectionYear?: Maybe<Scalars['Int']['output']>;
  mileage?: Maybe<Scalars['Float']['output']>;
  mileageStatus?: Maybe<Scalars['String']['output']>;
  mileageUnits?: Maybe<Scalars['String']['output']>;
  modelEra?: Maybe<Scalars['String']['output']>;
  modelGroup?: Maybe<Scalars['String']['output']>;
  modelName?: Maybe<Scalars['String']['output']>;
  modelYear?: Maybe<Scalars['Int']['output']>;
  modifications?: Maybe<Scalars['String']['output']>;
  postChangeOdometer?: Maybe<Scalars['Float']['output']>;
  postChangeStatus?: Maybe<Scalars['String']['output']>;
  postChangeUnits?: Maybe<Scalars['String']['output']>;
  preChangeOdometer?: Maybe<Scalars['Float']['output']>;
  preChangeStatus?: Maybe<Scalars['String']['output']>;
  preChangeUnits?: Maybe<Scalars['String']['output']>;
  registrationEra?: Maybe<Scalars['String']['output']>;
  registrationMonth?: Maybe<Scalars['Int']['output']>;
  registrationYear?: Maybe<Scalars['Int']['output']>;
  repairHistory?: Maybe<Scalars['String']['output']>;
  retailModelName?: Maybe<Scalars['String']['output']>;
  wholesalePrice?: Maybe<Scalars['Float']['output']>;
};

export type SharedInventoryMotoFilterInput = {
  and?: InputMaybe<Array<SharedInventoryMotoFilterInput>>;
  appearance?: InputMaybe<StringOperationFilterInput>;
  bodyStyle?: InputMaybe<StringOperationFilterInput>;
  color?: InputMaybe<StringOperationFilterInput>;
  colorChange?: InputMaybe<StringOperationFilterInput>;
  company?: InputMaybe<StringOperationFilterInput>;
  countryOfOrigin?: InputMaybe<StringOperationFilterInput>;
  deletionFlag?: InputMaybe<ByteOperationFilterInput>;
  engineCc?: InputMaybe<IntOperationFilterInput>;
  engineModel?: InputMaybe<StringOperationFilterInput>;
  evaluation?: InputMaybe<StringOperationFilterInput>;
  features?: InputMaybe<StringOperationFilterInput>;
  frameNumber?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<StringOperationFilterInput>;
  images?: InputMaybe<StringOperationFilterInput>;
  inspectionEra?: InputMaybe<StringOperationFilterInput>;
  inspectionMonth?: InputMaybe<IntOperationFilterInput>;
  inspectionYear?: InputMaybe<IntOperationFilterInput>;
  mileage?: InputMaybe<FloatOperationFilterInput>;
  mileageStatus?: InputMaybe<StringOperationFilterInput>;
  mileageUnits?: InputMaybe<StringOperationFilterInput>;
  modelEra?: InputMaybe<StringOperationFilterInput>;
  modelGroup?: InputMaybe<StringOperationFilterInput>;
  modelName?: InputMaybe<StringOperationFilterInput>;
  modelYear?: InputMaybe<IntOperationFilterInput>;
  modifications?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<SharedInventoryMotoFilterInput>>;
  postChangeOdometer?: InputMaybe<FloatOperationFilterInput>;
  postChangeStatus?: InputMaybe<StringOperationFilterInput>;
  postChangeUnits?: InputMaybe<StringOperationFilterInput>;
  preChangeOdometer?: InputMaybe<FloatOperationFilterInput>;
  preChangeStatus?: InputMaybe<StringOperationFilterInput>;
  preChangeUnits?: InputMaybe<StringOperationFilterInput>;
  registrationEra?: InputMaybe<StringOperationFilterInput>;
  registrationMonth?: InputMaybe<IntOperationFilterInput>;
  registrationYear?: InputMaybe<IntOperationFilterInput>;
  repairHistory?: InputMaybe<StringOperationFilterInput>;
  retailModelName?: InputMaybe<StringOperationFilterInput>;
  wholesalePrice?: InputMaybe<FloatOperationFilterInput>;
};

export type SharedInventoryMotoSortInput = {
  appearance?: InputMaybe<SortEnumType>;
  bodyStyle?: InputMaybe<SortEnumType>;
  color?: InputMaybe<SortEnumType>;
  colorChange?: InputMaybe<SortEnumType>;
  company?: InputMaybe<SortEnumType>;
  countryOfOrigin?: InputMaybe<SortEnumType>;
  deletionFlag?: InputMaybe<SortEnumType>;
  engineCc?: InputMaybe<SortEnumType>;
  engineModel?: InputMaybe<SortEnumType>;
  evaluation?: InputMaybe<SortEnumType>;
  features?: InputMaybe<SortEnumType>;
  frameNumber?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  images?: InputMaybe<SortEnumType>;
  inspectionEra?: InputMaybe<SortEnumType>;
  inspectionMonth?: InputMaybe<SortEnumType>;
  inspectionYear?: InputMaybe<SortEnumType>;
  mileage?: InputMaybe<SortEnumType>;
  mileageStatus?: InputMaybe<SortEnumType>;
  mileageUnits?: InputMaybe<SortEnumType>;
  modelEra?: InputMaybe<SortEnumType>;
  modelGroup?: InputMaybe<SortEnumType>;
  modelName?: InputMaybe<SortEnumType>;
  modelYear?: InputMaybe<SortEnumType>;
  modifications?: InputMaybe<SortEnumType>;
  postChangeOdometer?: InputMaybe<SortEnumType>;
  postChangeStatus?: InputMaybe<SortEnumType>;
  postChangeUnits?: InputMaybe<SortEnumType>;
  preChangeOdometer?: InputMaybe<SortEnumType>;
  preChangeStatus?: InputMaybe<SortEnumType>;
  preChangeUnits?: InputMaybe<SortEnumType>;
  registrationEra?: InputMaybe<SortEnumType>;
  registrationMonth?: InputMaybe<SortEnumType>;
  registrationYear?: InputMaybe<SortEnumType>;
  repairHistory?: InputMaybe<SortEnumType>;
  retailModelName?: InputMaybe<SortEnumType>;
  wholesalePrice?: InputMaybe<SortEnumType>;
};

export enum SortEnumType {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type StringOperationFilterInput = {
  and?: InputMaybe<Array<StringOperationFilterInput>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  eq?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ncontains?: InputMaybe<Scalars['String']['input']>;
  nendsWith?: InputMaybe<Scalars['String']['input']>;
  neq?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  nstartsWith?: InputMaybe<Scalars['String']['input']>;
  or?: InputMaybe<Array<StringOperationFilterInput>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type LotsListItemFragment = { __typename?: 'SharedInventoryMoto', id?: string | null, company?: string | null, modelName?: string | null, engineCc: number, frameNumber?: string | null, registrationYear?: number | null, modelYear?: number | null, evaluation?: string | null, color?: string | null, mileage?: number | null, mileageUnits?: string | null, wholesalePrice?: number | null, images?: string | null };

export type LotQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type LotQuery = { __typename?: 'Query', lot?: { __typename?: 'SharedInventoryMoto', id?: string | null, company?: string | null, modelName?: string | null, engineModel?: string | null, frameNumber?: string | null, modelYear?: number | null, color?: string | null, images?: string | null, wholesalePrice?: number | null, mileage?: number | null, mileageUnits?: string | null, evaluation?: string | null } | null };

export type LotsFilterDataQueryVariables = Exact<{ [key: string]: never; }>;


export type LotsFilterDataQuery = { __typename?: 'Query', scores: Array<string>, companies: Array<{ __typename?: 'Company', id?: string | null, name?: string | null }>, models: Array<{ __typename?: 'Model', id?: string | null, name: string, companyId?: string | null, lotsCount: number }> };

export type LotsListQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  filter?: InputMaybe<SharedInventoryMotoFilterInput>;
  sort?: InputMaybe<Array<SharedInventoryMotoSortInput> | SharedInventoryMotoSortInput>;
}>;


export type LotsListQuery = { __typename?: 'Query', lots?: { __typename?: 'LotsPaginatedListCollectionSegment', totalCount: number, items?: Array<{ __typename?: 'SharedInventoryMoto', id?: string | null, company?: string | null, modelName?: string | null, engineCc: number, frameNumber?: string | null, registrationYear?: number | null, modelYear?: number | null, evaluation?: string | null, color?: string | null, mileage?: number | null, mileageUnits?: string | null, wholesalePrice?: number | null, images?: string | null }> | null } | null };

export const LotsListItemFragmentDoc = gql`
    fragment LotsListItem on SharedInventoryMoto {
  id
  company
  modelName
  engineCc
  frameNumber
  registrationYear
  modelYear
  evaluation
  color
  mileage
  mileageUnits
  wholesalePrice
  images
}
    `;
export const LotDocument = gql`
    query lot($id: String!) {
  lot(id: $id) {
    id
    company
    modelName
    engineModel
    frameNumber
    modelYear
    color
    images
    wholesalePrice
    mileage
    mileageUnits
    evaluation
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class LotGQL extends Apollo.Query<LotQuery, LotQueryVariables> {
    document = LotDocument;
    client = 'aucnet-moto';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const LotsFilterDataDocument = gql`
    query lotsFilterData {
  scores
  companies {
    id
    name
  }
  models {
    id
    name
    companyId
    lotsCount
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class LotsFilterDataGQL extends Apollo.Query<LotsFilterDataQuery, LotsFilterDataQueryVariables> {
    document = LotsFilterDataDocument;
    client = 'aucnet-moto';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const LotsListDocument = gql`
    query lotsList($first: Int, $offset: Int, $filter: SharedInventoryMotoFilterInput, $sort: [SharedInventoryMotoSortInput!]) {
  lots: lotsPaginatedList(
    take: $first
    skip: $offset
    where: $filter
    order: $sort
  ) {
    items {
      ...LotsListItem
    }
    totalCount
  }
}
    ${LotsListItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class LotsListGQL extends Apollo.Query<LotsListQuery, LotsListQueryVariables> {
    document = LotsListDocument;
    client = 'aucnet-moto';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {}
};
      export default result;
    