import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RatingTagComponent } from './rating-tag.component';
import { NzTagModule } from 'ng-zorro-antd/tag';

@NgModule({
  declarations: [RatingTagComponent],
  imports: [CommonModule, NzTagModule],
  exports: [RatingTagComponent],
})
export class RatingTagModule {}
