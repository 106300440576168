<nz-row nzJustify="start">
  <nz-col nzSpan="24">
    <nz-row style="margin-top: 20px">
      <nz-breadcrumb nzSeparator=">">
        <nz-breadcrumb-item>
          <a [routerLink]="['']" translate>Vehicle Search</a>
        </nz-breadcrumb-item>
        <nz-breadcrumb-item>
          <a [routerLink]="['/listing']" translate>Listings</a>
        </nz-breadcrumb-item>
        <nz-breadcrumb-item *ngIf="lotId">
          <a [routerLink]="['.']">#{{ lotId }}</a>
        </nz-breadcrumb-item>
      </nz-breadcrumb>
    </nz-row>

    <nz-spin nzSize="large" [nzSpinning]="loading$ | async">
      <header style="margin-top: 20px">
        <nz-result
          *ngIf="!(data$ | async)?.lot && !(loading$ | async)"
          nzStatus="404"
          [nzTitle]="'Listing not found' | translate"
        ></nz-result>

        <app-lot-title-full *ngIf="(data$ | async)?.lot as lot" [lot]="lot"></app-lot-title-full>
      </header>

      <ng-container *ngIf="data$ | async as data">
        <main *ngIf="data?.lot" nz-row nzGutter="16">
          <aside nz-col [nzXs]="{ span: 24, order: 1 }" [nzLg]="{ span: 14, order: 2 }">
            <app-lot-carousel
              style="margin-bottom: 20px"
              [images]="data.lot?.images || []"
            ></app-lot-carousel>
          </aside>

          <aside nz-col [nzXs]="{ span: 24, order: 2 }" [nzLg]="{ span: 10, order: 1 }">
            <section>
              <nz-card [nzTitle]="title">
                <ng-template #title>
                  <nz-row nzAlign="top" nzJustify="space-between">
                    <nz-col>
                      <span translate>Wholesale Price</span>
                      <br />
                      <h1 style="font-size: 32px">
                        {{ data.lot.wholesalePrice | currency: 'JPY':'symbol-narrow' }}
                      </h1>
                    </nz-col>
                    <nz-col>
                      <button nz-button nzType="default" nzSize="large" (click)="copyToClipboard()">
                        <span
                          nz-icon
                          nzType="share-alt"
                          nzTheme="outline"
                          style="cursor: pointer"
                        ></span>
                        <span translate>SHARED.SHARE</span>
                      </button>
                    </nz-col>
                  </nz-row>
                  <nz-row nzJustify="space-between">
                    <nz-col>
                      <span translate>Year</span><br />
                      <h3>{{ data.lot.registration.toString().slice(0, 4) }}</h3>
                    </nz-col>
                    <nz-col>
                      <span translate>Grade</span><br />
                      <h3>{{ data.lot.modelCd }}</h3>
                    </nz-col>
                    <nz-col>
                      <span translate>Mission</span><br />
                      <h3>{{ data.lot.missionCd }}</h3>
                    </nz-col>
                    <nz-col>
                      <span translate>Color</span><br />
                      <h3>
                        {{ data.lot.colorCd1.replaceAll('_', ' ') | titlecase }}
                        <span *ngIf="data.lot.colorCd2 && data.lot.colorCd2 != 'NOT_SET'">
                          / Int: {{ data.lot.colorCd2.replaceAll('_', ' ') | titlecase }}
                        </span>
                      </h3>
                    </nz-col>
                    <nz-col>
                      <span translate>Mileage</span><br />
                      <h3>
                        {{ data.lot.mileage | number }} {{ data.lot.mileageFlag | lowercase }}
                      </h3>
                    </nz-col>
                  </nz-row>
                </ng-template>
                <h1>
                  <fa-icon [fixedWidth]="true" [icon]="faLocationPin"></fa-icon>
                  {{ data.lot.regionName }}
                </h1>
                <h1>
                  <fa-icon [fixedWidth]="true" [icon]="faCarBattery"></fa-icon>
                  {{ data.lot.fuelCd | titlecase }} {{ data.lot.exhaust | number }}
                  {{ data.lot.exhaustCd | lowercase }}
                </h1>
                <h1>
                  <fa-icon [fixedWidth]="true" [icon]="faCar"></fa-icon>
                  {{ data.lot.accidentFlag.replaceAll('_', ' ') | titlecase }},
                  {{ data.lot.historyCd | titlecase }}
                </h1>
                <h1>
                  <fa-icon [fixedWidth]="true" [icon]="faRegistered"></fa-icon>
                  {{ data.lot.carbodyNo | vinMask }}
                </h1>
                <div style="margin-top: 20px">
                  <app-contact-form
                    [lotId]="data.lotId"
                    [lotSource]="lotSource"
                    [lotType]="lotType"
                    [currentUser]="data.currentUser"
                    [wholesalePrice]="data.lot.wholesalePrice"
                  ></app-contact-form>
                </div>
              </nz-card>
              <nz-card style="margin-top: 20px" nzTitle="Inspection">
                <nz-row [nzGutter]="[20, 20]">
                  <nz-col nzSpan="24" *ngIf="data.lot.aisScoreTotal">
                    <h1 translate>AIS Information</h1>
                    <nz-row nzJustify="space-between">
                      <nz-col>
                        <app-rating-tag [rating]="data.lot.aisScoreTotal"></app-rating-tag>
                        <span translate>Total Score</span>
                      </nz-col>
                      <nz-col>
                        <app-rating-tag [rating]="data.lot.aisScoreExt"></app-rating-tag>
                        <span translate>Exterior Score</span>
                      </nz-col>
                      <nz-col>
                        <app-rating-tag [rating]="data.lot.aisScoreInt"></app-rating-tag>
                        <span translate>Interior Score</span>
                      </nz-col>
                    </nz-row>
                    <nz-row style="margin-top: 10px">
                      <nz-col>
                        <div *ngFor="let item of [].constructor(20); let i = index">
                          {{ data.lot['aisComment' + i.toString().padStart(2, '0')] }}
                        </div>
                      </nz-col>
                    </nz-row>
                  </nz-col>
                  <nz-col nzSpan="24" *ngIf="data.lot.selfScoreTotal">
                    <h1 translate>AUCNET Information</h1>
                    <nz-row nzJustify="space-between">
                      <nz-col>
                        <app-rating-tag
                          [rating]="data.lot.selfScoreTotal.toString()"
                        ></app-rating-tag>
                        <span translate>Total Score</span>
                      </nz-col>
                      <nz-col>
                        <app-rating-tag
                          [rating]="data.lot.selfScoreInt.toString()"
                        ></app-rating-tag>
                        <span translate>Interior Score</span>
                      </nz-col>
                    </nz-row>
                  </nz-col>
                  <nz-col nzSpan="24" *ngIf="data.lot.sellingPointEn">
                    <h1 translate>Selling Point</h1>
                    <div>{{ data.lot.sellingPointEn }}</div>
                  </nz-col>
                  <nz-col
                    nzSpan="24"
                    *ngIf="data.lot.noticeInfo.trim() || data.lot.aucNoticeInfo.trim()"
                  >
                    <h1 translate>Notice</h1>
                    <div>{{ data.lot.aucNoticeInfo }}</div>
                    <div>{{ data.lot.noticeInfo }}</div>
                  </nz-col>
                </nz-row>
              </nz-card>
              <nz-card [nzTitle]="'Complection' | translate" style="margin-top: 20px">
                <div style="display: flex; justify-content: space-between">
                  <span ngClass="eqGenuine" style="padding: 4px 8px" translate>Genuine part</span>
                  <span ngClass="eqThirdParty" style="padding: 4px 8px; margin-left: 8px" translate>
                    Third-party part
                  </span>
                  <span ngClass="eqAvailable" style="padding: 4px 8px; margin-left: 8px" translate>
                    Genuine or third-party
                  </span>
                </div>
                <div nz-row style="margin-top: 8px">
                  <div
                    [nzHoverable]="false"
                    nz-card-grid
                    nz-col
                    nzXs="24"
                    nzSm="12"
                    nzMd="8"
                    nzLg="12"
                    nzXl="12"
                    nzXXl="8"
                    [ngClass]="defineCardColor(data.lot.airCondition)"
                  >
                    <span>
                      {{ data.lot.airCondition?.replaceAll('_', ' ') }}
                      <span translate>Air-conditioning</span>
                    </span>
                  </div>
                  <div
                    [nzHoverable]="false"
                    nz-card-grid
                    nz-col
                    nzXs="24"
                    nzSm="12"
                    nzMd="8"
                    nzLg="12"
                    nzXl="12"
                    nzXXl="8"
                    [ngClass]="defineCardColor(data.lot.airbag)"
                  >
                    <span translate>Air-bag</span>
                  </div>
                  <div
                    [nzHoverable]="false"
                    nz-card-grid
                    nz-col
                    nzXs="24"
                    nzSm="12"
                    nzMd="8"
                    nzLg="12"
                    nzXl="12"
                    nzXXl="8"
                    [ngClass]="defineCardColor(data.lot.abs)"
                  >
                    <span translate>ABS</span>
                  </div>

                  <div
                    [nzHoverable]="false"
                    nz-card-grid
                    nz-col
                    nzXs="24"
                    nzSm="12"
                    nzMd="8"
                    nzLg="12"
                    nzXl="12"
                    nzXXl="8"
                    [ngClass]="defineCardColor(data.lot.sunroof)"
                  >
                    <span translate>Sunroof</span>
                  </div>
                  <div
                    [nzHoverable]="false"
                    nz-card-grid
                    nz-col
                    nzXs="24"
                    nzSm="12"
                    nzMd="8"
                    nzLg="12"
                    nzXl="12"
                    nzXXl="8"
                    [ngClass]="defineCardColor(data.lot.aw)"
                  >
                    <span translate>Aluminum wheel</span>
                  </div>
                  <div
                    [nzHoverable]="false"
                    nz-card-grid
                    nz-col
                    nzXs="24"
                    nzSm="12"
                    nzMd="8"
                    nzLg="12"
                    nzXl="12"
                    nzXXl="8"
                    [ngClass]="defineCardColor(data.lot.leatherSeat)"
                  >
                    <span translate>Leather seat</span>
                  </div>
                  <div
                    [nzHoverable]="false"
                    nz-card-grid
                    nz-col
                    nzXs="24"
                    nzSm="12"
                    nzMd="8"
                    nzLg="12"
                    nzXXl="8"
                    [ngClass]="defineCardColor(data.lot.navi)"
                  >
                    <span>
                      {{ data.lot.naviType?.replaceAll('_', ' ') | titlecase }}
                      <span translate>Navigation</span>
                    </span>
                  </div>
                  <div
                    [nzHoverable]="false"
                    nz-card-grid
                    nz-col
                    nzXs="24"
                    nzSm="12"
                    nzMd="8"
                    nzLg="12"
                    nzXXl="8"
                    [ngClass]="defineCardColor(data.lot.tv)"
                  >
                    <span
                      >{{ data.lot.tvType?.replaceAll('_', ' ') | titlecase }}
                      <span span>TV</span></span
                    >
                  </div>
                  <div
                    [nzHoverable]="false"
                    nz-card-grid
                    nz-col
                    nzXs="24"
                    nzSm="12"
                    nzMd="8"
                    nzLg="12"
                    nzXXl="8"
                    [ngClass]="defineCardColor(data.lot.keyless)"
                  >
                    <span span>Keyless entry</span>
                  </div>
                  <div
                    [nzHoverable]="false"
                    nz-card-grid
                    nz-col
                    nzXs="24"
                    nzSm="12"
                    nzMd="8"
                    nzLg="12"
                    nzXXl="8"
                    [ngClass]="defineCardColor(data.lot.smartKey)"
                  >
                    <span translate>Smart key</span>
                  </div>
                  <div
                    [nzHoverable]="false"
                    nz-card-grid
                    nz-col
                    nzXs="24"
                    nzSm="12"
                    nzMd="8"
                    nzLg="12"
                    nzXXl="8"
                    [ngClass]="defineCardColor(data.lot.floormat)"
                  >
                    <span translate>Floor mat</span>
                  </div>
                  <div
                    [nzHoverable]="false"
                    nz-card-grid
                    nz-col
                    nzXs="24"
                    nzSm="12"
                    nzMd="8"
                    nzLg="12"
                    nzXXl="8"
                    [ngClass]="defineCardColor(data.lot.backcamera)"
                  >
                    <span translate>Back camera</span>
                  </div>
                  <div
                    [nzHoverable]="false"
                    nz-card-grid
                    nz-col
                    nzXs="24"
                    nzSm="12"
                    nzMd="8"
                    nzLg="12"
                    nzXXl="8"
                    [ngClass]="defineCardColor(data.lot.etc)"
                  >
                    <span translate>ETC</span>
                  </div>
                  <div
                    [nzHoverable]="false"
                    nz-card-grid
                    nz-col
                    nzXs="24"
                    nzSm="12"
                    nzMd="8"
                    nzLg="12"
                    nzXXl="8"
                    [ngClass]="defineCardColor(data.lot.guaranteeFlag)"
                  >
                    <span translate>Guarantee book</span>
                  </div>
                  <div
                    [nzHoverable]="false"
                    nz-card-grid
                    nz-col
                    nzXs="24"
                    nzSm="12"
                    nzMd="8"
                    nzLg="12"
                    nzXXl="8"
                    [ngClass]="defineCardColor(data.lot.manualFlag)"
                  >
                    <span translate>Manual book</span>
                  </div>
                  <div
                    [nzHoverable]="false"
                    nz-card-grid
                    nz-col
                    nzXs="24"
                    nzSm="12"
                    nzMd="8"
                    nzLg="12"
                    nzXXl="8"
                    [ngClass]="defineCardColor(data.lot.recordFlag)"
                  >
                    <span translate>Record book</span>
                  </div>
                  <div
                    [nzHoverable]="false"
                    nz-card-grid
                    nz-col
                    nzXs="24"
                    nzSm="12"
                    nzMd="8"
                    nzLg="12"
                    nzXXl="8"
                    [ngClass]="defineCardColor(data.lot.noteFlag)"
                  >
                    <span translate>Maintenance note</span>
                  </div>
                </div>
              </nz-card>
            </section>
          </aside>
        </main>
      </ng-container>
    </nz-spin>
  </nz-col>
</nz-row>
