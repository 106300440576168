import { QueryHandlingService } from '@akebono/core';
import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import {
  faCar,
  faGaugeSimpleHigh,
  faLocationPin,
  faPaintBrush,
  faRegistered,
} from '@fortawesome/free-solid-svg-icons';
import { marker as _ } from '@jbouduin/ngx-translate-extract-marker';
import { map, Observable, shareReplay, switchMap, tap } from 'rxjs';
import {
  LotsListGQL,
  LotsListItemFragment,
  LotsListQuery,
  LotsListQueryVariables,
  SharedInventoryMotoSortInput,
  SortEnumType,
} from 'src/app/modules/graphql/service/graphql-aucnet-moto-service';
import { SwiperOptions } from 'swiper';

import { ListingsFilterBuilderService } from '../../services/listings-filter-builder.service';

@Component({
  selector: 'app-listings',
  templateUrl: './listings.component.html',
  styleUrls: ['./listings.component.scss'],
  providers: [ListingsFilterBuilderService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class ListingsComponent {
  loading$: Observable<boolean>;
  lots$: Observable<LotsListItemFragment[]>;

  page = 1;
  pageSize = 32;
  currentSort = 'best_match';
  total$: Observable<number>;

  isMobileFilterOn = false;
  fallbackImg = `data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 500 500'%3e%3c/svg%3e`;
  configSwiper: SwiperOptions = {
    loop: true,
    keyboard: false,
    slidesPerView: 1,
    spaceBetween: 0,
    navigation: true,
    pagination: { clickable: true },
    autoHeight: true,
  };
  sortings = {
    best_match: { key: 'default', value: '', title: _('Best Match') },
    price_asc: { key: 'wholesalePrice', value: SortEnumType.Asc, title: _('Price Low to High') },
    price_desc: { key: 'wholesalePrice', value: SortEnumType.Desc, title: _('Price High to Low') },
    mileage_asc: { key: 'mileage', value: SortEnumType.Asc, title: _('Mileage Low to High') },
    mileage_desc: { key: 'mileage', value: SortEnumType.Desc, title: _('Mileage High to Low') },
    registration_asc: {
      key: 'modelYear',
      value: SortEnumType.Asc,
      title: _('Year Low to High'),
    },
    registration_desc: {
      key: 'modelYear',
      value: SortEnumType.Desc,
      title: _('Year High to Low'),
    },
    inventory_desc: { key: 'id', value: SortEnumType.Desc, title: _('Newly Listed') },
  };

  faGaugeSimpleHigh = faGaugeSimpleHigh;
  faLocationPin = faLocationPin;
  faPaintBrush = faPaintBrush;
  faCar = faCar;
  faRegistered = faRegistered;

  constructor(
    title: Title,
    route: ActivatedRoute,
    qhs: QueryHandlingService,
    private readonly router: Router,
    filterBuilder: ListingsFilterBuilderService,
    lotsListGQL: LotsListGQL,
  ) {
    title.setTitle('Akebono World Stock Inventory Motorcycles');

    const queryRef$ = route.queryParams.pipe(
      tap((params) => {
        this.page = Number(params.page) || 1;
        this.pageSize = Number(params.pageSize) || 32;
        this.currentSort = params.sort || 'best_match';
      }),
      map((params) => {
        return <LotsListQueryVariables>{
          first: this.pageSize,
          offset: (this.page - 1) * this.pageSize,
          filter: filterBuilder.buildFilter(params),
          sort: this.buildSort(params),
        };
      }),
      map((variables) =>
        qhs.fetch<LotsListQuery, LotsListQueryVariables>(lotsListGQL, variables, 'network-only'),
      ),
      shareReplay(1),
    );

    this.loading$ = queryRef$.pipe(
      switchMap((ref) => ref.loading),
      shareReplay(1),
    );
    const data$ = queryRef$.pipe(
      switchMap((ref) => ref.data),
      shareReplay(1),
    );

    this.lots$ = data$.pipe(map((data) => data.lots?.items || []));
    this.total$ = data$.pipe(map((data) => data.lots?.totalCount ?? 0));
  }

  scrollToTable(): void {
    document.querySelector('.top')?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
  }

  changePage(page: number): void {
    this.router.navigate([], {
      queryParams: {
        page,
      },
      queryParamsHandling: 'merge',
    });
    this.scrollToTable();
  }

  changePageSize(pageSize: number): void {
    this.router.navigate([], {
      queryParams: {
        pageSize,
      },
      queryParamsHandling: 'merge',
    });
    this.scrollToTable();
  }

  sortEvent(sort: string): void {
    this.router.navigate([], {
      queryParams: {
        sort: sort,
      },
      queryParamsHandling: 'merge',
    });
  }

  private buildSort(params: Params): SharedInventoryMotoSortInput {
    const defaultSort: SharedInventoryMotoSortInput = {
      company: SortEnumType.Asc,
      modelName: SortEnumType.Asc,
      modelYear: SortEnumType.Desc,
      mileage: SortEnumType.Asc,
    };

    if (params.sort && params.sort != 'best_match') {
      return {
        [this.sortings[this.currentSort].key]: this.sortings[this.currentSort].value,
      };
    } else {
      return defaultSort;
    }
  }
}
