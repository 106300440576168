import { Injectable } from '@angular/core';
import { SharedInventoryCarFilterInput } from 'src/app/modules/graphql/service/graphql-aucnet-main-service';

@Injectable()
export class QueryFilterService {
  public getQueryFilter(params, inclusionFilterFields): SharedInventoryCarFilterInput {
    const inclusionFilter = {};
    Object.keys(inclusionFilterFields).forEach((key) => {
      if (params[key] && params[key].length > 0) {
        inclusionFilter[inclusionFilterFields[key]] = {
          in: [].concat(params[key]),
        };
      }
    });

    const filter: SharedInventoryCarFilterInput = { ...inclusionFilter };
    if (params.company && params.company != 'any') {
      filter.makerEn = {
        eq: params.company,
      };
    }
    if (params.model && params.model != 'any') {
      filter.carnameEn = {
        eq: params.model,
      };
    }
    if (params.mileageFrom) {
      filter.mileage = {
        gte: Number(params.mileageFrom),
      };
    }
    if (params.mileageTo) {
      if (!filter.mileage) {
        filter.mileage = {};
      }
      filter.mileage.lte = Number(params.mileageTo);
    }
    if (params.engineVolumeFrom) {
      filter.exhaust = {
        gte: Number(params.engineVolumeFrom),
      };
    }
    if (params.engineVolumeTo) {
      if (!filter.exhaust) {
        filter.exhaust = {};
      }
      filter.exhaust.lte = Number(params.engineVolumeTo);
    }
    if (params.yearFrom) {
      filter.registration = {
        gte: Number(params.yearFrom) * 100,
      };
    }
    if (params.yearTo) {
      if (!filter.registration) {
        filter.registration = {};
      }
      filter.registration.lte = (Number(params.yearTo) + 1) * 100;
    }
    if (params.bid) {
      filter.inventoryYear = {
        eq: params.bid.slice(0, 4),
      };
      filter.inventoryNo = {
        eq: params.bid.slice(4),
      };
    }

    return Object.keys(filter).length > 0 ? filter : null;
  }
}
