import { Currency } from '@akebono/core';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { QueryRef } from 'apollo-angular';
import { interval, Observable, Subscription} from 'rxjs';
import { debounce, delay } from 'rxjs/operators';
import { DateRange, simpleNameSort, toLocalIsoString } from 'src/app/const';

import { QueryFilterService } from '../../services/query-filter.service';

import {
  MochironDumpsLotsListFiltersGQL,
  MochironDumpsLotsListFiltersQuery,
  MochironDumpsLotsListFiltersQueryVariables,
} from 'src/app/modules/graphql/service/graphql-mochiron-dumps-main-service';

@Component({
  selector: 'app-listings-filter',
  templateUrl: './listings-filter.component.html',
  styleUrls: ['./listings-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListingsFilterComponent {
  data: any = {
    total: 0,
    nodes: [],
  };
  filtersData: MochironDumpsLotsListFiltersQuery;
  pageSize = 20;
  page = 1;
  loadingFilters = true;
  loadingFrames = true;
  extendedSearchVisible = false;
  total = 0;
  japanCompaniesList = [];

  years: number[] = [];
  minYear = 1985;

  form: FormGroup;

  modelsList: any[];
  otherCompaniesList: any[];
  mainCompaniesList: any[];
  mainCompaniesNames = [
    'TOYOTA',
    'NISSAN',
    'MAZDA',
    'MITSUBISHI',
    'HONDA',
    'SUZUKI',
    'SUBARU',
    'ISUZU',
    'DAIHATSU',
    'MITSUOKA',
    'LEXUS',
  ];
  auctRefOptions: any[];
  auctions: any[];
  isYearFiltered = false;
  isMileageFiltered = false;
  isEngineVolumeFiltered = false;
  isProductionDateFiltered = false;
  isActiveFilters = true;
  lastModelId: string;
  filterPresetDeletings$: { [key: string]: Observable<boolean> } = {};

  inclusionFilterFields = {
    frame: 'modelTypeEn',
    score: 'ratingEn',
    color: 'colorEn',
    transmission: 'transmissionEn',
  };
  lastDay: Date;
  dateRange: DateRange[] = [];
  currentCurrency: Currency;
  dumpVersion: string;
  outdated = false;
  lotsListFiltersQueryRef: QueryRef<
    MochironDumpsLotsListFiltersQuery,
    MochironDumpsLotsListFiltersQueryVariables
  >;
  sub = new Subscription();

  presetContent: any = null;
  routeCurrentPath: string;
  routeWay: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private listLotsFiltersGQL: MochironDumpsLotsListFiltersGQL,
    private queryFilter: QueryFilterService,
  ) {
    for (let year = new Date().getFullYear(); year >= this.minYear; year--) {
      this.years.push(year);
    }

    this.routeWay = this.route.snapshot.data.route;
    this.routeCurrentPath = this.route.snapshot.url.reduce(
      (accum, current) => accum + '/' + current.path,
      '',
    );
    const formState = this.getFilterFormState(this.route.snapshot.queryParams);
    console.log(formState);
    this.form = this.fb.group({
      company: this.fb.control(formState.company),
      model: this.fb.control(formState.model),
      bodyType: this.fb.control(formState.bodyType),
      bid: this.fb.control(formState.bid),
      yearFrom: this.fb.control(formState.yearFrom),
      yearTo: this.fb.control(formState.yearTo),
      mileageFrom: this.fb.control(formState.mileageFrom),
      mileageTo: this.fb.control(formState.mileageTo),
      engineVolumeFrom: this.fb.control(formState.engineVolumeFrom),
      engineVolumeTo: this.fb.control(formState.engineVolumeTo),
      inclusionFrame: this.fb.control([formState.inclusionFrame].flat()),
      inclusionScore: this.fb.control(formState.inclusionScore),
      inclusionAuction: this.fb.control([formState.inclusionAuction].flat()),
      inclusionColor: this.fb.control(formState.inclusionColor),
      inclusionStatus: this.fb.control(formState.inclusionStatus),
      inclusionTransmission: this.fb.control(formState.inclusionTransmission),
      inclusionFuel: this.fb.control(formState.inclusionFuel),
      notSold: this.fb.control(formState.notSold),
      objectID: this.fb.control(null),
    });
    this.form.get('company').valueChanges.subscribe(this.onSelectedCompanyChange.bind(this));
    this.form.get('model').valueChanges.subscribe(this.onSelectedModelChange.bind(this));
    this.form.get('bodyType').valueChanges.pipe(delay(100)).subscribe(this.filter.bind(this));
    this.form.get('bid').valueChanges.pipe(delay(100)).subscribe(this.filter.bind(this));
    this.form.get('yearFrom').valueChanges.pipe(delay(100)).subscribe(this.filter.bind(this));
    this.form.get('yearTo').valueChanges.pipe(delay(100)).subscribe(this.filter.bind(this));
    this.form
      .get('mileageFrom')
      .valueChanges.pipe(
        debounce((x) => interval(1000)),
        delay(100),
      )
      .subscribe(this.filter.bind(this));
    this.form
      .get('mileageTo')
      .valueChanges.pipe(
        debounce((x) => interval(1000)),
        delay(100),
      )
      .subscribe(this.filter.bind(this));
    this.form
      .get('engineVolumeFrom')
      .valueChanges.pipe(
        debounce((x) => interval(1000)),
        delay(100),
      )
      .subscribe(this.filter.bind(this));
    this.form
      .get('engineVolumeTo')
      .valueChanges.pipe(
        debounce((x) => interval(1000)),
        delay(100),
      )
      .subscribe(this.filter.bind(this));
    this.form.get('inclusionFrame').valueChanges.pipe(delay(100)).subscribe(this.filter.bind(this));
    this.form.get('inclusionScore').valueChanges.pipe(delay(100)).subscribe(this.filter.bind(this));
    this.form
      .get('inclusionAuction')
      .valueChanges.pipe(delay(100))
      .subscribe(this.filter.bind(this));
    this.form.get('inclusionColor').valueChanges.pipe(delay(100)).subscribe(this.filter.bind(this));
    this.form
      .get('inclusionStatus')
      .valueChanges.pipe(delay(100))
      .subscribe(this.filter.bind(this));
    this.form
      .get('inclusionTransmission')
      .valueChanges.pipe(delay(100))
      .subscribe(this.filter.bind(this));
    this.form.get('notSold').valueChanges.pipe(delay(100)).subscribe(this.filter.bind(this));

    this.presetContent = this.form.value;
    this.form.valueChanges.subscribe((content) => (this.presetContent = content));

    this.route.queryParams.subscribe(async (params) => {
      await this.fetchFilterData(params);
    });
  }

  change(event) {}
  removeFilter(filter) {
    if (filter === 'year') {
      this.form.get('yearFrom').setValue(null);
      this.form.get('yearTo').setValue(null);
      this.isYearFiltered = false;
    }
    if (filter === 'engineVolume') {
      this.form.get('engineVolumeFrom').setValue(null);
      this.form.get('engineVolumeTo').setValue(null);
      this.isEngineVolumeFiltered = false;
    }
    if (filter === 'mileage') {
      this.form.get('mileageFrom').setValue(null);
      this.form.get('mileageTo').setValue(null);
      this.isMileageFiltered = false;
    }
    this.form.get('objectID').setValue(null);
    this.filter();
  }
  search(event) {}
  selectDateAuction(a, b) {}

  async fetchFilterData(params: Params) {
    this.loadingFrames = true;
    this.lastModelId = params.model;
    const hasModelId = !!(params.model && params.model !== 'any');
    let filter = this.queryFilter.getQueryFilter(params, this.inclusionFilterFields);
    console.log('listings filter', this.route.snapshot.data.departmentId);
    if (this.route.snapshot.data.departmentId) {
      if (!filter) filter = {};
      filter.departmentId = {
        eq: this.route.snapshot.data.departmentId,
      };
    }
    console.log(JSON.stringify(filter), params);
    // if (filter?.modelEn) {
    //   delete filter.modelEn;
    // }
    const result = await this.listLotsFiltersGQL
      .fetch(
        {
          withModel: hasModelId,
          filter,
        },
        {
          fetchPolicy: 'cache-first',
        },
      )
      .toPromise();

    this.otherCompaniesList = result.data.companies
      .filter((c) => !this.mainCompaniesNames.includes(c.name.toLocaleUpperCase()))
      .sort(simpleNameSort);
    this.mainCompaniesList = result.data.companies
      .filter((c) => this.mainCompaniesNames.includes(c.name.toLocaleUpperCase()))
      .sort(simpleNameSort)
      .reverse();

    if (!this.modelsList) {
      this.modelsList = result.data.models;
    }

    this.filtersData = result.data;
    this.cdr.markForCheck();
    this.loadingFilters = false;
    this.loadingFrames = false;
    console.log(this.form.value.model);
  }

  onSelectedCompanyChange(company: string) {
    this.form.get('bid').setValue(null);
    this.form.get('model').setValue('any', { emitEvent: false });
    this.form.get('inclusionScore').setValue(['any']);
    this.form.get('inclusionFrame').setValue(['any']);
    this.form.get('inclusionColor').setValue(['any']);
    this.form.get('inclusionTransmission').setValue(['any']);
    this.form.get('inclusionAuction').setValue(['any']);
    this.form.get('yearFrom').setValue(null);
    this.form.get('yearTo').setValue(null);
    this.form.get('objectID').setValue(null);
    this.router.navigate([this.route.snapshot.data.route, 'listings'], {
      queryParams: {
        page: 1,
        company: company,
        model: 'any',
        frame: null,
        bid: null,
        yearFrom: null,
        yearTo: null,
        objectID: null,
      },
      queryParamsHandling: 'merge',
    });
  }

  onSelectedModelChange(model: string) {
    this.form.get('bid').setValue(null);
    this.form.get('inclusionScore').setValue(['any']);
    this.form.get('inclusionFrame').setValue(['any']);
    this.form.get('inclusionColor').setValue(['any']);
    this.form.get('inclusionTransmission').setValue(['any']);
    this.form.get('inclusionAuction').setValue(['any']);
    this.form.get('yearFrom').setValue(null);
    this.form.get('yearTo').setValue(null);
    this.form.get('objectID').setValue(null);
    this.router.navigate([this.route.snapshot.data.route, 'listings'], {
      queryParams: {
        page: 1,
        model: model,
        frame: null,
        color: null,
        auction: null,
        score: null,
        bid: null,
        yearFrom: null,
        yearTo: null,
        objectID: null,
      },
      queryParamsHandling: 'merge',
    });
  }

  private toUTCDate(date: Date) {
    date.setUTCDate(date?.getDate());
    return date;
  }

  submit() {
    const objectID = this.form.value.objectID;
    if(objectID) {
      this.router.navigate([this.routeWay, 'car', objectID]);
      return;
    }
    this.filter();
  }
  filter() {
    const value = this.form.value;
    const bidSet = value.bid && value.bid !== '№ лота';
    this.router.navigate([this.routeCurrentPath], {
      queryParams: {
        r: Math.floor(Math.random() * 100),
        page: 1,
        company: value.company,
        model: value.model,
        bodyType: value.bodyType,
        bid: bidSet ? value.bid : undefined,
        yearFrom: value.yearFrom ? value.yearFrom : undefined,
        yearTo: value.yearTo ? value.yearTo : undefined,
        mileageFrom: value.mileageFrom ? value.mileageFrom : undefined,
        mileageTo: value.mileageTo ? value.mileageTo : undefined,
        engineVolumeFrom: value.engineVolumeFrom ? value.engineVolumeFrom : undefined,
        engineVolumeTo: value.engineVolumeTo ? value.engineVolumeTo : undefined,
        frame:
          typeof value.inclusionFrame !== 'string' && value.inclusionFrame.length > 0
            ? value.inclusionFrame.filter((v) => v !== 'any')
            : undefined,
        score:
          typeof value.inclusionScore !== 'string' && value.inclusionScore.length > 0
            ? value.inclusionScore.filter((v) => v !== 'any')
            : undefined,
        auction:
          typeof value.inclusionAuction !== 'string' && value.inclusionAuction.length > 0
            ? value.inclusionAuction.filter((v) => v !== 'any')
            : undefined,
        color:
          typeof value.inclusionColor !== 'string' && value.inclusionColor.length > 0
            ? value.inclusionColor.filter((v) => v !== 'any')
            : undefined,
        status: value.inclusionStatus.length > 0 ? value.inclusionStatus : undefined,
        transmission:
          typeof value.inclusionTransmission !== 'string' && value.inclusionTransmission.length > 0
            ? value.inclusionTransmission.filter((v) => v !== 'any')
            : undefined,
        fuel:
          typeof value.inclusionFuel !== 'string' && value.inclusionFuel.length > 0
            ? value.inclusionFuel.filter((v) => v !== 'any')
            : undefined,
        notSold: value.notSold ? true : undefined,
      },
      queryParamsHandling: 'merge',
    });
  }

  clearValue(formControlName: string): void {
    this.form.get(formControlName).setValue(null);
  }

  resetFilter() {
    this.form.reset(this.getFilterFormState({}));
    this.router.navigate([this.route.snapshot.data.route, 'listings']);
    this.outdated = false;
    return false;
  }

  selectAuctionDate(event, date: DateRange) {
    const dateJapan = toLocalIsoString(
      this.getDate(date.date.toLocaleString('en-US', { timeZone: 'Asia/Tokyo' })),
    );
    date.checked = event;
    if (event) {
      this.form.controls.selectedDays.setValue(
        this.form.controls.selectedDays.value.concat(dateJapan),
      );
    } else {
      const day = this.form.controls.selectedDays.value.indexOf(dateJapan);
      if (day > -1) {
        const days = this.form.controls.selectedDays.value;
        days.splice(day, 1);
        this.form.controls.selectedDays.setValue(days);
      }
    }
    this.filter();
  }

  private checkOutdated(dumpVersion) {
    if (!this.dumpVersion) {
      this.dumpVersion = dumpVersion;
    } else if (this.dumpVersion !== dumpVersion) {
      this.outdated = true;
    }
  }

  getDate(string = null): Date {
    if (string) {
      return new Date(string);
    }
    return new Date();
  }

  getFilterFormState(params) {
    this.isYearFiltered = (params.yearFrom || params.yearTo) as boolean;
    this.isMileageFiltered = (params.mileageFrom || params.mileageTo) as boolean;
    this.isEngineVolumeFiltered = (params.engineVolumeFrom || params.engineVolumeTo) as boolean;
    const productionDate = [];
    if (params.productionDateFrom) {
      productionDate[0] = params.productionDateFrom;
    }
    if (params.productionDateTo) {
      productionDate[1] = params.productionDateTo;
    }
    return {
      company: params.company || 'any',
      model: params.model || 'any',
      bid: params.bid,
      yearFrom: params.yearFrom ? Number(params.yearFrom) : null,
      yearTo: params.yearTo ? Number(params.yearTo) : null,
      mileageFrom: params.mileageFrom || null,
      mileageTo: params.mileageTo || null,
      engineVolumeFrom: params.engineVolumeFrom || null,
      engineVolumeTo: params.engineVolumeTo || null,
      bodyType: params.bodyType || [],
      productionDate,
      inclusionFrame: params.frame ? [].concat(params.frame) : ['any'],
      inclusionAuction: params.auction ? [].concat(params.auction) : ['any'],
      inclusionScore: params.score ? [].concat(params.score) : ['any'],
      inclusionColor: params.color ? [].concat(params.color) : ['any'],
      inclusionStatus: params.status ? [].concat(params.status) : [],
      inclusionTransmission: params.transmission ? [].concat(params.transmission) : ['any'],
      inclusionFuel: params.fuel ? [].concat(params.fuel) : ['any'],
      notSold: params.notSold ? true : undefined,
    };
  }

  valueIncludes(control: AbstractControl, value: any) {
    return typeof control.value.includes == 'function' && control.value.includes(value);
  }

  addValueToControl(control: AbstractControl, value: any) {
    const newVal = control.value.concat(value).filter((v) => v !== 'any');
    control.setValue(newVal);
  }

  removeValueFromControl(control: AbstractControl, value: any) {
    control.setValue(control.value.filter((v: any) => v !== value));
  }
}
