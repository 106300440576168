import { Pipe, PipeTransform } from '@angular/core';

import { LotQuery, LotsListItemFragment } from '../../graphql/service/graphql-aucnet-moto-service';

@Pipe({ name: 'motoTitle' })
export class MotoTitlePipe implements PipeTransform {
  transform(lot: LotsListItemFragment | LotQuery['lot'], type: 'full' | 'model' = 'full'): string {
    const company = lot.company?.trim();
    const normalizedModel = lot.modelName?.replace(company, '').trim();

    switch (type) {
      case 'full':
        return [company, normalizedModel].join(' ');
      case 'model':
        return normalizedModel;
    }
  }
}
