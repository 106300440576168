import { QueryHandlingService } from '@akebono/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import {
  faCar,
  faCarBattery,
  faGaugeSimpleHigh,
  faLocationPin,
  faRegistered,
} from '@fortawesome/free-solid-svg-icons';
import { NzMessageService } from 'ng-zorro-antd/message';
import { BehaviorSubject, combineLatest, iif, of, Subject } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { ReactiveLotData } from 'src/app/const';
import {
  AucnetLotGQL,
  AucnetLotQuery,
  AucnetLotQueryVariables,
} from 'src/app/modules/graphql/service/graphql-aucnet-main-service';

import {
  AkebonoLotSource,
  AkebonoLotTypeEnum,
  CurrentUserGQL,
  CurrentUserQuery,
  CurrentUserQueryVariables,
} from '../../../graphql/service/akebono-main-service';

@Component({
  selector: 'app-lot',
  templateUrl: './lot.component.html',
  styleUrls: ['./lot.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LotComponent implements OnInit {
  data$: ReactiveLotData<AucnetLotQuery['lot']> = of({});
  loading$ = new BehaviorSubject<boolean>(true);
  refetch$ = new Subject<void>();
  lotSource = AkebonoLotSource.Aucnet;
  lotType = AkebonoLotTypeEnum.Oneprice;

  faGaugeSimpleHigh = faGaugeSimpleHigh;
  faLocationPin = faLocationPin;
  faCarBattery = faCarBattery;
  faCar = faCar;
  faRegistered = faRegistered;

  lotId = '';

  constructor(
    private title: Title,
    private route: ActivatedRoute,
    private currentUser: CurrentUserGQL,
    private autoLotGQL: AucnetLotGQL,
    private qhs: QueryHandlingService,
    private clipboard: Clipboard,
    private message: NzMessageService,
  ) {
    this.lotId = this.route.snapshot.params.lot;
  }

  ngOnInit(): void {
    this.loading$.next(true);
    this.data$ = this.route.params.pipe(
      map((params) => ({
        lotId: params.lot,
      })),
      switchMap((initData) =>
        combineLatest([
          this.qhs.fetch<AucnetLotQuery, AucnetLotQueryVariables>(
            this.autoLotGQL,
            { id: initData.lotId || 0 },
            'network-only',
          ).data,
          this.qhs.fetch<CurrentUserQuery, CurrentUserQueryVariables>(this.currentUser).data,
        ]).pipe(
          map(([lotResult, currentUserResult]) => ({
            initData,
            lotResult,
            currentUserResult,
          })),
        ),
      ),
      switchMap((data) =>
        iif(
          () => Boolean(data.lotResult.lot),
          of(data).pipe(
            map((data) => ({
              ...data,
              lot: data.lotResult.lot,
            })),
            map((data) => ({
              ...data.initData,
              lot: data.lot,
              currentUser: data.currentUserResult.currentUser,
              calcData: null,
            })),
            tap((data) => {
              this.title.setTitle(`Akebono World Car #${data.lot.id}`);
              scrollTo({ top: 0 });
            }),
          ),
          of(data.initData),
        ),
      ),
      tap((data) => {
        // console.log(data);
        this.loading$.next(false);
      }),
    );
  }

  defineCardColor(prop) {
    if (prop === 'NOT_SET') return 'eqOff';
    else if (prop === '0') return 'eqOff';
    else if (prop === 0) return 'eqOff';
    else if (prop === '1') return '';
    else if (prop === 1) return '';
    else if (prop === 'GENUINE') return 'eqGenuine';
    else if (prop === 'THIRD_PARTY') return 'eqThirdParty';
    else if (prop === 'AVAILABLE') return 'eqAvailable';
    else return prop.replaceAll('_', ' ');
  }

  copyToClipboard() {
    this.clipboard.copy(window.location.href);
    this.message.success('Link copied', {
      nzDuration: 2000,
    });
  }
}
