<nz-skeleton *ngIf="loadingFilters" [nzActive]="true"></nz-skeleton>
<form
  nz-form
  [formGroup]="form"
  (ngSubmit)="submit()"
  *ngIf="!loadingFilters"
  [nzLayout]="'vertical'"
>
  <nz-row [nzGutter]="40">
    <nz-col [nzXs]="24" [nzMd]="24" [nzXl]="24">
      <nz-form-item>
        <nz-form-label
          ><strong>{{ 'COMPANY' | translate }}</strong></nz-form-label
        >
        <nz-form-control>
          <nz-row [nzGutter]="20">
            <nz-col [nzSpan]="24">
              <nz-select
                formControlName="company"
                nzShowSearch
                (nzOnSearch)="search($event)"
                nzPlaceHolder="{{ 'SELECT_COMPANY' | translate }}"
                (ngModelChange)="change($event)"
              >
                <nz-option nzValue="any" nzLabel="{{ 'ANY' | translate }}"></nz-option>
                <nz-option
                  *ngFor="let item of mainCompaniesList"
                  [nzValue]="item.id"
                  [nzLabel]="item.name"
                ></nz-option>
                <nz-option-group nzLabel="JAPAN">
                  <nz-option
                    *ngFor="let item of japanCompaniesList"
                    [nzValue]="item.id"
                    [nzLabel]="item.name"
                  ></nz-option>
                </nz-option-group>
                <nz-option-group nzLabel="OTHER">
                  <nz-option
                    *ngFor="let item of otherCompaniesList"
                    [nzValue]="item.id"
                    [nzLabel]="item.name"
                  ></nz-option>
                </nz-option-group>
              </nz-select>
            </nz-col>
          </nz-row>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label
          ><strong>{{ 'Model' | translate }}</strong></nz-form-label
        >
        <nz-form-control>
          <nz-row [nzGutter]="20">
            <nz-col [nzSpan]="24">
              <nz-select
                formControlName="model"
                nzShowSearch
                nzAllowClear
                (nzOnSearch)="search($event)"
                (ngModelChange)="change($event)"
              >
                <nz-option nzValue="any" nzLabel="{{ 'ANY' | translate }}"></nz-option>
                <ng-container *ngFor="let m of modelsList">
                  <nz-option
                    *ngIf="
                      !form.controls.company.value ||
                      form.controls.company.value === 'any' ||
                      m.companyId == form.controls.company.value
                    "
                    [nzValue]="m.id"
                    nzLabel="{{ m.name }} ({{ m.lotsCount }})"
                  >
                  </nz-option>
                </ng-container>
              </nz-select>
            </nz-col>
          </nz-row>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label
          ><strong>{{ 'Chassis' | translate }}</strong></nz-form-label
        >
        <nz-form-control>
          <nz-select
            formControlName="inclusionFrame"
            nzMode="multiple"
            nzPlaceHolder="{{ 'PLEASE_SELECT' | translate }}"
            [nzDisabled]="!filtersData.frames"
          >
            <nz-option
              *ngFor="let option of filtersData.frames"
              nzLabel="{{ option.name }}"
              [nzValue]="option.name"
            ></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label
          ><strong>{{ 'Object ID' | translate }}</strong></nz-form-label
        >
        <nz-form-control>
          <input
            type="text"
            nz-input
            formControlName="objectID"
            placeholder="{{ 'Object ID' | translate }}"
          />
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label class="label-flex"
          ><strong>{{ 'LOT_INFO.YEAR' | translate }}</strong>
          <span *ngIf="isYearFiltered" (click)="removeFilter('year')" nz-text nzType="secondary">{{
            'SEE_ALL' | translate
          }}</span>
        </nz-form-label>
        <nz-form-control>
          <nz-input-group nzCompact class="year-selectors">
            <nz-select
              formControlName="yearFrom"
              class="from"
              [nzShowArrow]="false"
              (ngModelChange)="change($event)"
              nzPlaceHolder="{{ 'FROM' | translate }}"
              [nzDropdownMatchSelectWidth]="false"
              style="width: 50%; text-align: center"
            >
              <nz-option *ngFor="let year of years" [nzValue]="year" [nzLabel]="year"></nz-option>
            </nz-select>
            <nz-select
              formControlName="yearTo"
              class="to"
              [nzDropdownMatchSelectWidth]="false"
              [nzShowArrow]="false"
              (ngModelChange)="change($event)"
              nzPlaceHolder="{{ 'TO' | translate }}"
              style="width: 50%; text-align: center; border-left: 0px"
            >
              <nz-option *ngFor="let year of years" [nzValue]="year" [nzLabel]="year"></nz-option>
            </nz-select>
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label class="label-flex"
          ><strong>{{ 'LOT_INFO.MILEAGE' | translate }}</strong>
          <span
            *ngIf="isMileageFiltered"
            (click)="removeFilter('mileage')"
            nz-text
            nzType="secondary"
            >{{ 'SEE_ALL' | translate }}</span
          >
        </nz-form-label>
        <nz-form-control>
          <nz-input-group nzCompact>
            <input
              type="number"
              nz-input
              formControlName="mileageFrom"
              (change)="change($event)"
              placeholder="{{ 'FROM' | translate }}"
              style="width: calc(50% - 15px); text-align: center"
            />
            <input
              type="text"
              disabled
              nz-input
              placeholder="-"
              style="
                width: 30px;
                border-left: 0px;
                pointer-events: none;
                background-color: rgb(255, 255, 255);
              "
            />
            <input
              type="number"
              nz-input
              placeholder="{{ 'TO' | translate }}"
              formControlName="mileageTo"
              (change)="change($event)"
              style="width: calc(50% - 15px); text-align: center; border-left: 0px"
            />
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label class="label-flex">
          <strong>{{ 'ENGINE_VOLUME' | translate }}</strong>
          <span
            *ngIf="isEngineVolumeFiltered"
            (click)="removeFilter('engineVolume')"
            nz-text
            nzType="secondary"
            >{{ 'SEE_ALL' | translate }}</span
          >
        </nz-form-label>
        <nz-form-control>
          <nz-input-group nzCompact>
            <input
              type="number"
              nz-input
              formControlName="engineVolumeFrom"
              (change)="change($event)"
              placeholder="{{ 'FROM' | translate }}"
              style="width: calc(50% - 15px); text-align: center"
            />
            <input
              type="text"
              disabled
              nz-input
              placeholder="-"
              style="
                width: 30px;
                border-left: 0px;
                pointer-events: none;
                background-color: rgb(255, 255, 255);
              "
            />
            <input
              type="number"
              nz-input
              placeholder="{{ 'TO' | translate }}"
              formControlName="engineVolumeTo"
              (change)="change($event)"
              style="width: calc(50% - 15px); text-align: center; border-left: 0px"
            />
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label>
          <strong>{{ 'LOT_INFO.SCORE' | translate }}</strong>
        </nz-form-label>
        <nz-form-control>
          <nz-select
            formControlName="inclusionScore"
            nzMode="multiple"
            nzPlaceHolder="{{ 'PLEASE_SELECT' | translate }}"
            [nzDisabled]="!filtersData.scores"
          >
            <nz-option
              *ngFor="let option of filtersData.scores"
              [nzLabel]="option.split('').join('.') || 'No Score'"
              [nzValue]="option"
            ></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label
          ><strong>{{ 'LOT_INFO.COLOR' | translate }}</strong></nz-form-label
        >
        <nz-form-control>
          <nz-select
            formControlName="inclusionColor"
            nzMode="multiple"
            nzPlaceHolder="{{ 'PLEASE_SELECT' | translate }}"
            [nzDisabled]="!filtersData.colors"
          >
            <nz-option
              *ngFor="let option of filtersData.colors"
              [nzLabel]="option"
              [nzValue]="option"
            ></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label
          ><strong>{{ 'TRANSMISSION' | translate }}</strong
          >{{ '' | translate }}</nz-form-label
        >
        <nz-form-control>
          <nz-select
            formControlName="inclusionTransmission"
            nzMode="multiple"
            nzPlaceHolder="{{ 'PLEASE_SELECT' | translate }}"
            [nzDisabled]="!filtersData.transmissions"
          >
            <nz-option
              *ngFor="let option of filtersData.transmissions"
              [nzLabel]="option"
              [nzValue]="option"
            ></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-control>
          <label nz-checkbox formControlName="notSold">{{ 'NOT_SOLD' | translate }}</label>
        </nz-form-control>
      </nz-form-item>
      <!-- <nz-form-item>
        <nz-form-label>
          <strong>{{ 'Body Type' | translate }}</strong>
        </nz-form-label>
        <nz-form-control>
          <nz-select
            formControlName="bodyType"
            nzMode="multiple"
            nzPlaceHolder="{{ 'PLEASE_SELECT' | translate }}"
            [nzDisabled]="!filtersData.bodyTypes"
          >
            <nz-option
              *ngFor="let option of filtersData.bodyTypes"
              [nzLabel]="option.replaceAll('_', ' ')"
              [nzValue]="option"
            ></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item> -->
      <!-- <nz-form-item>
        <nz-form-label>
          <strong>{{ 'Fuel' | translate }}</strong>
        </nz-form-label>
        <nz-form-control>
          <nz-select
            formControlName="inclusionFuel"
            nzMode="multiple"
            nzPlaceHolder="{{ 'PLEASE_SELECT' | translate }}"
            [nzDisabled]="!filtersData.fuelTypes"
          >
            <nz-option
              *ngFor="let option of filtersData.fuelTypes"
              [nzLabel]="option.replaceAll('_', ' ')"
              [nzValue]="option"
            ></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item> -->
      <!-- <nz-form-item>
        <nz-form-label
          ><strong>{{ 'Location' | translate }}</strong></nz-form-label
        >
        <nz-form-control>
          <nz-select
            formControlName="inclusionAuction"
            nzMode="multiple"
            nzPlaceHolder="{{ 'PLEASE_SELECT' | translate }}"
            [nzDisabled]="!filtersData.auctions"
          >
            <nz-option
              *ngFor="let option of auctions"
              [nzLabel]="option.name"
              [nzValue]="option.id"
            ></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item> -->
      <!-- <nz-form-item>
        <nz-form-label
          ><strong>{{ 'Inventory No' | translate }}</strong></nz-form-label
        >
        <nz-form-control>
          <input
            type="text"
            formControlName="bid"
            nz-input
            placeholder="{{ 'Inventory No' | translate }}"
          />
        </nz-form-control>
      </nz-form-item> -->
    </nz-col>
  </nz-row>
  <div nz-row nzJustify="end">
    <div nz-col class="search-area">
      <button nz-button (click)="resetFilter()">{{ 'CLEAR' | translate }}</button>
      <button nz-button nzType="primary">
        {{ 'SEARCH' | translate }}
      </button>
    </div>
  </div>
</form>
