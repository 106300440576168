import {Directive, ElementRef, OnDestroy, Renderer2} from '@angular/core';

@Directive({
  selector: '[appStopPropagation]',
  standalone: true
})
export class StopPropagationDirective implements OnDestroy {
  private unsubscribe: () => void;

  constructor(renderer: Renderer2, el: ElementRef) {
    this.unsubscribe = renderer.listen(el.nativeElement, 'click', (e) => {
      e.stopPropagation();
    })
  }

  ngOnDestroy() {
    this.unsubscribe();
  }

}
