<ng-template #countryCode>
  <nz-select
    [ngModel]="selectedCountry"
    [nzCustomTemplate]="custom"
    [nzDropdownMatchSelectWidth]="false"
    (ngModelChange)="updateSelectedCountry($event)"
    tabindex="7"
    nzSize="large"
    style="width: 150px"
  >
    <nz-option
      *ngFor="let country of countries | country: countryFilter"
      nzCustomContent
      [nzLabel]="country.name"
      [nzValue]="country"
    >
      <span [class]="'flag flag-' + country.countryCode"></span>&nbsp;
      <span class="country-name"
        >{{ country.name }}
        <span class="dial-code">+ {{ country.dialCode }}</span>
      </span>
    </nz-option>
  </nz-select>
  <ng-template #custom>
    <span [class]="'flag flag-' + selectedCountry.countryCode"></span>&nbsp;
    <span class="country-name">
      <span class="dial-code">+{{ selectedCountry.dialCode }}</span>
    </span>
  </ng-template>
</ng-template>
<nz-input-group [nzAddOnBefore]="countryCode" nzCompact nzSize="large" *ngIf="countries.length">
  <!-- domestic, required, uses masking directive -->
  <input
    *ngIf="required && !isForeign()"
    nz-input
    phone
    required
    name="primaryPhoneInput"
    id="primaryPhoneInput"
    placeholder="{{ placeholder }}"
    [(ngModel)]="phoneNumberOnly"
    aria-label="phone number"
    #phoneNumberInput
    type="text"
    (ngModelChange)="updatePhoneNumber($event)"
    (blur)="blur()"
    nzSize="large"
  />

  <!-- Foreign, required, since foreign countries can have different length phones, potentially from 5 to 12 digits, remove the masking and enforce a max of 12 numeric digits. Rely on google-libphonenumber for validation. -->
  <input
    *ngIf="required && isForeign()"
    nz-input
    phone
    required
    name="primaryPhoneInput"
    id="primaryPhoneInput"
    placeholder="{{ placeholder }}"
    maxlength="{{ maxlength }}"
    onkeypress="return event.charCode >= 48 && event.charCode <= 57"
    [(ngModel)]="phoneNumberOnly"
    aria-label="phone number"
    #phoneNumberInput
    type="text"
    (ngModelChange)="updatePhoneNumber($event)"
    (blur)="blur()"
    tabindex="8"
    nzSize="large"
  />

  <!-- domestic, not required -->
  <input
    *ngIf="!required && !isForeign()"
    nz-input
    phone
    name="primaryPhoneInput"
    id="primaryPhoneInput"
    placeholder="{{ placeholder }}"
    [(ngModel)]="phoneNumberOnly"
    aria-label="phone number"
    #phoneNumberInput
    type="text"
    (ngModelChange)="updatePhoneNumber($event)"
    (blur)="blur()"
    tabindex="8"
    nzSize="large"
  />

  <!-- foreign, not required -->
  <input
    *ngIf="!required && isForeign()"
    nz-input
    phone
    name="primaryPhoneInput"
    id="primaryPhoneInput"
    placeholder="{{ placeholder }}"
    maxlength="{{ maxlength }}"
    onkeypress="return event.charCode >= 48 && event.charCode <= 57"
    [(ngModel)]="phoneNumberOnly"
    aria-label="phone number"
    #phoneNumberInput
    type="text"
    (ngModelChange)="updatePhoneNumber($event)"
    (blur)="blur()"
    tabindex="8"
    nzSize="large"
  />
</nz-input-group>
