import { AkebonoCoreModule } from '@akebono/core';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzImageModule } from 'ng-zorro-antd/image';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzResultModule } from 'ng-zorro-antd/result';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { StopPropagationDirective } from 'src/app/directives/stop-propagation.directive';
import { VinMaskPipe } from 'src/app/pipes/vin-mask.pipe';
import { SwiperModule } from 'swiper/angular';

import { ContactFormModule } from '../shared-components/contanct-form/contact-form.module';
import { LotCarouselModule } from '../shared-components/lot-carousel/lot-carousel.module';
import { RatingTagModule } from '../shared-components/rating-tag/rating-tag.module';
import { AucnetMotoRoutingModule } from './aucnet-moto-routing.module';
import { ListingsFilterComponent } from './components/listings-filter/listings-filter.component';
import { ListingsComponent } from './components/listings/listings.component';
import { LotComponent } from './components/lot/lot.component';
import { ExtractImagesPipe } from './pipes/extract-images.pipe';
import { DefaultPipe } from './pipes/default.pipe';
import { MotoTitlePipe } from './pipes/moto-title.pipe';

@NgModule({
  declarations: [ListingsComponent, ListingsFilterComponent, LotComponent, ExtractImagesPipe, DefaultPipe, MotoTitlePipe],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AucnetMotoRoutingModule,
    AkebonoCoreModule,
    TranslateModule,
    SwiperModule,
    RouterModule,
    VinMaskPipe,
    StopPropagationDirective,
    ContactFormModule,
    LotCarouselModule,
    FontAwesomeModule,
    RatingTagModule,
    NzGridModule,
    NzDropDownModule,
    NzBreadCrumbModule,
    NzMenuModule,
    NzSpinModule,
    NzCardModule,
    NzImageModule,
    NzTagModule,
    NzResultModule,
    NzIconModule,
    NzFormModule,
    NzSpaceModule,
    NzButtonModule,
    NzInputModule,
    NzSelectModule,
    NzPaginationModule,
    NzTypographyModule,
    NzSkeletonModule,
  ],
})
export class AucnetMotoModule {}
