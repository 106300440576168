import { Injectable } from '@angular/core';
import { Params } from '@angular/router';

import {
  FloatOperationFilterInput,
  SharedInventoryMotoFilterInput,
} from '../../graphql/service/graphql-aucnet-moto-service';
import { ListingsFilterFormValue } from '../types/listings-filter-form-value.type';

@Injectable()
export class ListingsFilterBuilderService {
  getParams(formValue: ListingsFilterFormValue): Params {
    return {
      company: formValue.company !== 'any' ? formValue.company : null,
      model: formValue.model !== 'any' ? formValue.model : null,
      yearFrom: formValue.yearFrom,
      yearTo: formValue.yearTo,
      volumeFrom: formValue.volumeFrom,
      volumeTo: formValue.volumeTo,
      mileageFrom: formValue.mileageFrom,
      mileageTo: formValue.mileageTo,
      score: formValue.score,
    };
  }

  parseParams(params: Params): ListingsFilterFormValue {
    return {
      company: params.company || 'any',
      model: params.model || 'any',
      yearFrom: this.paramToNumber(params.yearFrom),
      yearTo: this.paramToNumber(params.yearTo),
      volumeFrom: this.paramToNumber(params.volumeFrom),
      volumeTo: this.paramToNumber(params.volumeTo),
      mileageFrom: this.paramToNumber(params.mileageFrom),
      mileageTo: this.paramToNumber(params.mileageTo),
      score: this.parseArrayParam(params.score),
    };
  }

  buildFilter(params: Params): SharedInventoryMotoFilterInput {
    const {
      company,
      model,
      yearFrom,
      yearTo,
      volumeFrom,
      volumeTo,
      mileageFrom,
      mileageTo,
      score,
    } = this.parseParams(params);

    return {
      company:
        company === 'any'
          ? undefined
          : {
              eq: company,
            },
      modelGroup:
        model === 'any'
          ? undefined
          : {
              eq: model,
            },
      evaluation: score.length
        ? {
            in: score,
          }
        : undefined,
      modelYear: this.buildRangeFilter(yearFrom, yearTo),
      engineCc: this.buildRangeFilter(volumeFrom, volumeTo),
      mileage: this.buildRangeFilter(mileageFrom, mileageTo),
    };
  }

  private paramToNumber(value: any): number | null {
    return value ? Number(value) : null;
  }

  private buildRangeFilter(
    from: number | null,
    to: number | null,
  ): FloatOperationFilterInput | undefined {
    if (from || to) {
      return {
        gte: from || undefined,
        lte: to || undefined,
      };
    } else {
      return undefined;
    }
  }

  private parseArrayParam(value: any): string[] {
    if (value) {
      return Array.isArray(value) ? value : [value];
    } else {
      return [];
    }
  }
}
