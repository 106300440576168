import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainComponent } from './components/main/main.component';
import { AucnetSicRoutingModule } from './modules/aucnet-sic/aucnet-sic-routing.module';
// import { MochironRoutingModule } from './modules/mochiron/mochiron-routing.module';
import { MochironDumpsRoutingModule } from './modules/mochiron-dumps/mochiron-dumps-routing.module';

const haveLotsAccess = {
  // canActivate: [PermissionsGuard, ProtectedGuard],
  // data: { permissions: [PERMISSION_LOTS] },
};

const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    ...haveLotsAccess,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes), AucnetSicRoutingModule, MochironDumpsRoutingModule],
  exports: [RouterModule],
})
export class AppRoutingModule {}
