import { MutationHandlingServiceV2 } from '@akebono/core';
import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import seaPorts from 'sea-ports';
import { validateAllFormFields } from 'src/app/const';
import {
  AkebonoLotSource,
  AkebonoLotTypeEnum,
  CurrentUserQuery,
  SharedInventoryContactFormCreateGQL,
  SharedInventoryContactFormCreateInput,
  SharedInventoryContactFormCreateMutation,
} from 'src/app/modules/graphql/service/akebono-main-service';
import { Country } from 'src/app/modules/input-phone-number/country.model';
import { CountryService } from 'src/app/modules/input-phone-number/country.service';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss'],
  providers: [CountryService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactFormComponent implements OnInit {
  countries: Country[] = [];
  form: FormGroup;
  phoneNumber: FormControl;
  isQuoteLoading: Observable<boolean>;
  isQuoteSent: Observable<boolean>;
  @Input({ required: true }) lotId: string;
  @Input({ required: true }) lotType: AkebonoLotTypeEnum | string;
  @Input({ required: true }) lotSource: AkebonoLotSource | string;
  @Input({ required: false }) objectGUID: string;
  @Input() wholesalePrice: number;
  @Input({ required: false }) wholesalePriceCurrency: string;
  @Input() currentUser: CurrentUserQuery['currentUser'];
  @Input({ required: false }) departmentId: string;
  @Input({ required: false }) isGreenCorner: boolean = false;

  ports = [];

  constructor(
    private cs: CountryService,
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private sharedInventoryContactFormCreateGQL: SharedInventoryContactFormCreateGQL,
    private mhs: MutationHandlingServiceV2,
    private route: ActivatedRoute,
  ) {
    this.countries = this.cs.getCountries();
    this.phoneNumber = this.formBuilder.control('', Validators.compose([Validators.required]));
    this.form = this.formBuilder.group({
      offerAmount: this.formBuilder.control('', [Validators.required]),
      offerCurrency: this.formBuilder.control('', [Validators.required]),
      firstName: this.formBuilder.control('', [Validators.required]),
      lastName: this.formBuilder.control('', [Validators.required]),
      middleName: this.formBuilder.control(''),
      company: this.formBuilder.control(''),
      email: this.formBuilder.control(
        '',
        Validators.compose([Validators.required, Validators.email]),
      ),
      country: this.formBuilder.control('', [Validators.required]),
      phoneNumber: this.phoneNumber,
      viber: this.formBuilder.control(false),
      whatsapp: this.formBuilder.control(false),
      telegram: this.formBuilder.control(false),
      comment: this.formBuilder.control(''),
      deliveryPort: this.formBuilder.control(''),
      shippingType: this.formBuilder.control(''),
      freightPaymentType: this.formBuilder.control(''),
    });
  }

  ngOnInit(): void {
    this.form.get('country').valueChanges.subscribe((v: Country) => {
      const countryCode = v.countryCode.toUpperCase();
      this.ports = Object.keys(seaPorts.JSON)
        .filter((p) => p.startsWith(countryCode))
        .map((p) => seaPorts.JSON[p]);
      this.form.get('deliveryPort').setValue('');
      if (!this.currentUser?.phoneNumber || this.currentUser?.phoneNumber.endsWith(' ')) {
        this.form.get('phoneNumber').setValue('+' + (this.isGreenCorner ? '7' : v.dialCode) + ' ');
      } else {
        let defaultCut = -10;

        if (this.currentUser.phoneNumber.startsWith('+996')) {
          defaultCut = -9;
        }
        this.form
          .get('phoneNumber')
          .setValue(
            this.currentUser.phoneNumber.slice(0, defaultCut) +
              ' ' +
              this.currentUser.phoneNumber.slice(defaultCut),
          );
      }
    });
    this.getCountry();
  }

  getCountry() {
    this.http.get('https://locate.akebono.world/country').subscribe((res: any) => {
      const userCountry = res.country.toLowerCase();
      this.form.controls.country.setValue(
        this.countries.find((c) => c.countryCode === userCountry),
      );
    });
  }

  submitForm(): void {
    if (this.form.valid) {
      const mutation = this.mhs.mutate<
        SharedInventoryContactFormCreateMutation,
        { input: SharedInventoryContactFormCreateInput }
      >(
        this.sharedInventoryContactFormCreateGQL,
        {
          input: {
            amount: this.form.value.offerAmount,
            comments: this.form.value.comment,
            company: this.form.value.company,
            currency: this.form.value.offerCurrency,
            email: this.form.value.email,
            firstName: this.form.value.firstName,
            hasTelegram: this.form.value.telegram,
            hasViber: this.form.value.viber,
            hasWhatsapp: this.form.value.whatsapp,
            lastName: this.form.value.lastName,
            lotId: this.lotId,
            lotType: this.lotType as AkebonoLotTypeEnum,
            lotSource: this.lotSource as AkebonoLotSource,
            middleName: this.form.value.middleName,
            phoneNumber: this.form.value.phoneNumber,
            countryIso: this.form.value.country.countryCode,
            deliveryPort: this.form.value.deliveryPort,
            shippingType: this.form.value.shippingType,
            freightPaymentType: this.form.value.freightPaymentType,
            objectGuid: this.objectGUID,
            departmentCode: this.departmentId,
          },
        },
        {
          refetch: true,
          successTranslationKey: 'MUTATION_SUCCEEDED',
          failureTranslationKey: 'MUTATION_FAILED',
        },
      );
      this.isQuoteLoading = mutation.loading;
      this.isQuoteSent = mutation.data.pipe(
        map((data) => {
          scrollTo({
            top: 0,
            behavior: 'smooth',
          });
          return !data.sharedInventoryContactFormCreate.error;
        }),
      );
    } else {
      validateAllFormFields(this.form);
    }
  }
}
