import { AkebonoCoreModule } from '@akebono/core';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { SwiperModule } from 'swiper/angular';

import { ContactFormComponent } from './contact-form.component';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzInputModule } from 'ng-zorro-antd/input';
import { InputPhoneNumberModule } from '../../input-phone-number/input-phone-number.module';
import { NzResultModule } from 'ng-zorro-antd/result';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';

@NgModule({
  declarations: [ContactFormComponent],
  exports: [ContactFormComponent],
  imports: [
    CommonModule,
    SwiperModule,
    NzIconModule,
    NzButtonModule,
    NzPopconfirmModule,
    NzToolTipModule,
    NzSpinModule,
    TranslateModule,
    AkebonoCoreModule,
    NzFormModule,
    NzSelectModule,
    NzInputModule,
    InputPhoneNumberModule,
    NzResultModule,
    NzCheckboxModule,
  ],
})
export class ContactFormModule {}
