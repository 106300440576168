<div class="content">
  <nz-breadcrumb nzSeparator=">">
    <nz-breadcrumb-item>
      <a [routerLink]="['/']" translate>Motorcycles Search</a>
    </nz-breadcrumb-item>
    <nz-breadcrumb-item>
      <a [routerLink]="[]" translate>Listings</a>
    </nz-breadcrumb-item>
    <nz-breadcrumb-item>
      <a translate>Motorcycles</a>
    </nz-breadcrumb-item>
  </nz-breadcrumb>

  <h1>
    <span translate>Used</span> {{ 'Motorcycles' | translate }}
    <span translate>from Japan for Sale</span>
  </h1>

  <nz-row>
    <nz-col [nzXs]="24" [nzSm]="24" [nzMd]="24" [nzLg]="0" [nzXl]="0">
      <nz-row nzJustify="space-between">
        <button nz-button nzSize="large" (click)="isMobileFilterOn = !isMobileFilterOn">
          <span translate>Filter</span> <span nz-icon nzType="filter" nzTheme="outline"></span>
        </button>
        <button
          nz-button
          *ngIf="isMobileFilterOn"
          nzShape="circle"
          (click)="isMobileFilterOn = false"
        >
          <span nz-icon nzType="close" nzTheme="outline"></span>
        </button>
      </nz-row>
    </nz-col>
  </nz-row>

  <nz-row *ngIf="isMobileFilterOn">
    <nz-col class="mobile-filter" [nzXs]="24" [nzSm]="24" [nzMd]="24" [nzLg]="0" [nzXl]="0">
      <app-listings-filter></app-listings-filter>
    </nz-col>
  </nz-row>

  <nz-row nzGutter="20" class="listing-row">
    <nz-col [nzXs]="0" [nzSm]="0" [nzMd]="0" [nzLg]="6" [nzXl]="4">
      <app-listings-filter></app-listings-filter>
    </nz-col>

    <nz-col [nzXs]="24" [nzSm]="24" [nzMd]="24" [nzLg]="18" [nzXl]="20">
      <nz-row nzJustify="space-between" nzAlign="middle" *ngIf="total$ | async as total">
        <h2>
          <span translate>Showing</span>
          {{ (page - 1) * pageSize + 1 }} -
          {{ total < pageSize ? total : (page - 1) * pageSize + pageSize }}
          <span translate>of</span> {{ total | number }} <span translate>total</span>
        </h2>
        <div>
          <span translate>Sort by</span>&nbsp;
          <a nz-dropdown [nzDropdownMenu]="menu">
            <span translate>{{ sortings[currentSort].title }}</span>
            <span nz-icon nzType="down"></span>
          </a>
          <nz-dropdown-menu #menu="nzDropdownMenu">
            <ul nz-menu nzSelectable>
              <li
                *ngFor="let sort of sortings | keyvalue"
                nz-menu-item
                (click)="sortEvent(sort.key)"
                translate
              >
                {{ sort.value.title }}
              </li>
            </ul>
          </nz-dropdown-menu>
        </div>
      </nz-row>

      <nz-spin [nzSpinning]="loading$ | async">
        <nz-row [nzGutter]="[20, 20]" class="listings">
          <a
            *ngFor="let moto of lots$ | async"
            nz-col
            [nzXs]="24"
            [nzSm]="24"
            [nzMd]="12"
            [nzLg]="8"
            [nzXl]="6"
            [routerLink]="['/moto', moto.id]"
          >
            <nz-card [nzCover]="coverTemplate" [style.min-height.px]="520" nzHoverable>
              <h4 nz-typography nzEllipsis [title]="moto | motoTitle">
                {{ moto | motoTitle }}
              </h4>

              <h5 style="height: 2lh">{{ moto.engineCc }} cc</h5>

              <nz-row nzJustify="space-between">
                <h2>
                  <!-- <nz-tag *ngIf="moto.modelYear">{{ moto.modelYear }}</nz-tag> -->
                  <app-rating-tag
                    *ngIf="moto.evaluation"
                    [rating]="moto.evaluation"
                  ></app-rating-tag>
                </h2>
                <h2>
                  <b>{{ moto.wholesalePrice | currency: 'JPY' }}</b>
                </h2>
              </nz-row>

              <hr />
              <h5>
                <fa-icon [fixedWidth]="true" [icon]="faGaugeSimpleHigh"></fa-icon>
                {{ moto.mileage | number }} {{ moto.mileageUnits | lowercase }}
              </h5>
              <h5 *ngIf="moto.color">
                <fa-icon [fixedWidth]="true" [icon]="faPaintBrush"></fa-icon>
                {{ moto.color | titlecase }}
              </h5>
              <h5>
                <fa-icon [fixedWidth]="true" [icon]="faRegistered"></fa-icon>
                {{ moto.frameNumber | vinMask }}
              </h5>

              <ng-template #coverTemplate>
                <swiper
                  *ngIf="(moto | extractImages).length; else placeholder"
                  appStopPropagation
                  [config]="configSwiper"
                  style="border: 1px solid #f0f0f0; min-height: 250px"
                >
                  <ng-template
                    *ngFor="let image of (moto | extractImages).slice(0, 3); index as i"
                    swiperSlide
                  >
                    <img
                      nz-image
                      nzDisablePreview
                      [routerLink]="['/moto', moto.id]"
                      alt="car"
                      [nzSrc]="image"
                      [nzFallback]="fallbackImg"
                      style="max-width: 100%"
                      loading="lazy"
                    />
                  </ng-template>
                </swiper>
                <ng-template #placeholder>
                  <div class="image-placeholder">
                    <img
                      nz-image
                      nzDisablePreview
                      alt="moto image"
                      nzSrc="/assets/images/placeholder.png"
                      nzFallback="/assets/images/placeholder.png"
                      loading="lazy"
                    />
                  </div>
                </ng-template>
              </ng-template>
            </nz-card>
          </a>
        </nz-row>

        <nz-row *ngIf="total$ | async as total" nzJustify="center" [style.margin.px]="20">
          <nz-col>
            <nz-pagination
              nzShowSizeChanger
              [nzPageIndex]="page"
              [nzPageSize]="pageSize"
              [nzTotal]="total"
              (nzPageIndexChange)="changePage($event)"
              (nzPageSizeChange)="changePageSize($event)"
            ></nz-pagination>
          </nz-col>
        </nz-row>
      </nz-spin>
    </nz-col>
  </nz-row>
</div>
