import { FormControl, FormGroup } from '@angular/forms';
import { Params } from '@angular/router';
import { DateTime } from 'luxon';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CurrentUserQuery } from './modules/graphql/service/akebono-main-service';

export const PERMISSION_BOUNDARY_LOTS = 'cars.boundary-lots';
export const PERMISSION_TRANSLATOR = 'world.akebono.auto.translator';
export const PERMISSION_AUCTIONEER = 'world.akebono.auto.auctioneer';
export const PERMISSION_CHECK_PRODUCTION_DATE = 'cars.production-date-check';
export const PERMISSION_RECHECK_PRODUCTION_DATE = 'cars.production-date-recheck';
export const PERMISSION_BEST_BID_ADMIN = 'cars.best-bid-admin';
export const PERMISSION_LOTS = 'cars.lots';
export const PERMISSION_ONEPRICE_LOTS = 'cars.oneprice-lots';
export const SIGN_URL = '/sign-in';
export const TITLE_MAIN_PAGE = 'Akebono World';
export const LETTERS = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
  'AA',
  'AB',
  'AC',
  'AD',
  'AE',
  'AF',
  'AG',
  'AH',
  'AI',
  'AJ',
  'AK',
  'AL',
  'AM',
  'AN',
  'AO',
  'AP',
  'AQ',
  'AR',
  'AS',
  'AT',
  'AU',
  'AV',
  'AW',
  'AX',
  'AY',
  'AZ',
  'BA',
  'BB',
  'BC',
  'BD',
  'BE',
  'BF',
  'BG',
  'BH',
  'BI',
  'BJ',
  'BK',
  'BL',
  'BM',
  'BN',
  'BO',
  'BP',
  'BQ',
  'BR',
  'BS',
  'BT',
  'BU',
  'BV',
  'BW',
  'BX',
  'BY',
  'BZ',
  'CA',
  'CB',
  'CC',
  'CD',
  'CE',
  'CF',
  'CG',
  'CH',
  'CI',
  'CJ',
  'CK',
  'CL',
  'CM',
  'CN',
  'CO',
  'CP',
  'CQ',
  'CR',
  'CS',
  'CT',
  'CU',
  'CV',
  'CW',
  'CX',
  'CY',
  'CZ',
];

export const BID_CONDITION_INTACT = 1;
export const BID_CONDITION_BOARD_CONSTRUCTOR = 15;
export const BID_CONDITION_BOARD_SAW_BACK = 8;
export const BID_CONDITION_PALLET = 23;
export const BID_CONDITION_OTHER_COUNTRIES = 24;

export const bidConditionsOrderMap = {
  [BID_CONDITION_INTACT]: 1,
  [BID_CONDITION_BOARD_CONSTRUCTOR]: 2,
  [BID_CONDITION_BOARD_SAW_BACK]: 3,
};

export interface DateRange {
  date: Date;
  checked: boolean;
}

export function toNumber(string): number {
  return Number(string);
}

export function simpleNameSort(a, b) {
  const name1 = a?.name?.toUpperCase();
  const name2 = b?.name?.toUpperCase();
  if (name1 === name2) {
    return 0;
  }
  return name1 > name2 ? 1 : -1;
}

export function getDaysRange(start: Date, endDate: Date) {
  let current = start;
  const arr = [];
  while (current < endDate) {
    arr.push(new Date(current));
    current = toDate(current.setDate(current.getDate() + 1));
  }
  return arr;
}

export function toDate(date) {
  return new Date(date);
}

export function toLocalIsoString(date) {
  const localIsoString =
    date.getFullYear() + '-' + pad(date.getMonth() + 1) + '-' + pad(date.getDate());
  return localIsoString;
}

export function validateAllFormFields(formGroup: FormGroup) {
  Object.keys(formGroup.controls).forEach((field) => {
    const control = formGroup.get(field);
    if (control instanceof FormControl) {
      control.markAsTouched({ onlySelf: true });
      control.markAsDirty({ onlySelf: true });
      control.updateValueAndValidity({ onlySelf: true });
    } else if (control instanceof FormGroup) {
      validateAllFormFields(control);
    }
  });
}

function pad(n) {
  return n < 10 ? '0' + n : n;
}

const toCamel = (s) => {
  return s.replace(/([-_][a-z])/gi, ($1) => {
    return $1.toUpperCase().replace('-', '').replace('_', '');
  });
};

const isArray = function (a) {
  return Array.isArray(a);
};

const isObject = function (o) {
  return o === Object(o) && !isArray(o) && typeof o !== 'function';
};

export const keysToCamel = function (o) {
  if (isObject(o)) {
    const n = {};

    Object.keys(o).forEach((k) => {
      n[toCamel(k)] = keysToCamel(o[k]);
    });

    return n;
  } else if (isArray(o)) {
    return o.map((i) => {
      return keysToCamel(i);
    });
  }

  return o;
};

export function getTypeSort(type: string): string | null {
  return type ? (type === 'ascend' ? 'asc' : 'desc') : null;
}

export type ProductionDatePayload = {
  epc_export?: {
    [key: string]: string;
  };
};

export type ReactiveLoadings = {
  [identifier: string]: Observable<boolean>;
};

export interface ObservableLoadings {
  [id: string]: Observable<boolean>;
}

export interface Pagination {
  page: number;
  pageSize: number;
  total: number;
}

export interface AutoLotView {
  id?: string;
  images: string[];
  bid: string;
  company?: string;
  companyId: number;
  modelName?: string;
  modelId?: number;
  auctionName: string;
  time: string;
  date: string;
  year?: string;
  frame?: string;
  modelGrade?: string;
  color?: string;
  transmission?: string;
  engineVolume?: number;
  equip?: string;
  mileage?: string;
  inspectionEn?: string;
  inspection?: string;
  rate?: string;
  startPrice?: number;
  finishPrice?: number;
  averagePrice?: number;
  parsedData?: any;
  parsedDataRu?: any;
  parsedDataEn?: any;
  dumpVersion?: string;
  result?: string;
  status?: string;
  vin?: string;
  productionDate?: string;
  productionDatePayload?: ProductionDatePayload;
  negotiationsPrice?: number;
  source?: string;
  bidsCount?: number;
  viewsCount?: number;
  biddersCount?: number;
  inFavoritesCount?: number;
  isLotDataChanged?: boolean;
  expectedTranslationInSeconds?: number;
}

export interface HTMLInputEvent extends Event {
  target: HTMLInputElement & EventTarget;
}

export function normalizeAuctionStartDatetime(auctionDatetime: string): DateTime {
  const auctionStart = DateTime.fromISO(auctionDatetime, { zone: 'Asia/Tokyo' });

  if (auctionStart.hour === 0 && auctionStart.minute === 0 && auctionStart.second === 0) {
    return auctionStart.set({ hour: 23, minute: 59, second: 59 });
  } else {
    return auctionStart;
  }
}

export function getSharedLotLinkById(id: number): string {
  return `https://${environment.domain}/shared/${id}`;
}

export function getBase64(img: File, callback: (img: string) => void): void {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result!.toString()));
  reader.readAsDataURL(img);
}

export function getImgPrefix(): string {
  return `https://img.${environment.domain}/`;
}

export const noSawCutStaging = [1, 5, 20, 24];
export const noSawCutProduction = [1, 5, 20, 24];

export type ReactiveLotData<T> = Observable<LotData<T>>;

export type LotData<T> = {
  lotId?: string;
  lot?: T;
  lotSource?: string;
  lotType?: string;
  currentUser?: CurrentUserQuery['currentUser'];
};

export const MOCHIRON_ID = 102968;
export const AKEBONO_MONGOLIA_ID = 105920;
