<form *ngIf="!(isQuoteSent | async)" nz-form class="login-form" [formGroup]="form">
  <nz-form-item>
    <nz-form-control>
      <nz-input-group nzCompact nzSize="large">
        <input
          type="text"
          ngModel="{{ wholesalePrice }}"
          nz-input
          formControlName="offerAmount"
          placeholder="{{ 'Your offer including freight' | translate }}"
          nzSize="large"
          style="width: 70%"
        />
        <nz-select
          ngModel="{{ wholesalePriceCurrency || 'JPY' }}"
          nzSize="large"
          formControlName="offerCurrency"
          style="width: 30%"
        >
          <nz-option nzValue="USD" nzLabel="USD"></nz-option>
          <nz-option nzValue="JPY" nzLabel="JPY"></nz-option>
          <nz-option nzValue="RUB" nzLabel="RUB"></nz-option>
        </nz-select>
      </nz-input-group>
    </nz-form-control>
  </nz-form-item>
  <div nz-row nzGutter="8">
    <div nz-col nzXs="24" nzSm="24" nzMd="12">
      <nz-form-item style="width: 100%">
        <nz-form-control
          nzHasFeedback
          nzErrorTip="{{ 'SIGN_UP.INPUT_LAST_NAME_ERROR' | translate }}"
        >
          <input
            type="text"
            nz-input
            required
            formControlName="lastName"
            [ngModel]="currentUser != null ? currentUser.lastName : ''"
            placeholder="{{ 'SIGN_UP.LAST_NAME_PLACEHOLDER' | translate }}"
            nzSize="large"
          />
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col nzXs="24" nzSm="24" nzMd="12">
      <nz-form-item style="width: 100%">
        <nz-form-control
          nzHasFeedback
          nzErrorTip="{{ 'SIGN_UP.INPUT_FIRST_NAME_ERROR' | translate }}"
        >
          <input
            type="text"
            nz-input
            required
            formControlName="firstName"
            [ngModel]="currentUser != null ? currentUser.firstName : ''"
            placeholder="{{ 'SIGN_UP.FIRST_NAME_PLACEHOLDER' | translate }}"
            nzSize="large"
          />
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
  <nz-form-item *ngIf="!isGreenCorner">
    <nz-form-control>
      <input type="text" nz-input formControlName="company" placeholder="Company" nzSize="large" />
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-control nzHasFeedback nzErrorTip="{{ 'SIGN_UP.INPUT_EMAIL_ERROR' | translate }}">
      <input
        type="email"
        nz-input
        email
        required
        formControlName="email"
        [ngModel]="currentUser != null ? currentUser.email : ''"
        placeholder="{{ 'SIGN_UP.EMAIL_PLACEHOLDER' | translate }}"
        nzSize="large"
      />
    </nz-form-control>
  </nz-form-item>

  <div nz-row nzGutter="8">
    <div nz-col nzXs="24" nzSm="24" [nzMd]="isGreenCorner ? 24 : 12">
      <nz-form-item>
        <nz-form-control [nzErrorTip]="CountryErrorTpl">
          <nz-input-group>
            <nz-select
              formControlName="country"
              id="country"
              name="country"
              nz-input
              nzSize="large"
              style="width: 100%"
              [nzCustomTemplate]="defaultTemplate"
              nzPlaceHolder="Country"
              tabindex="6"
            >
              <nz-option
                *ngFor="let country of countries"
                [nzLabel]="country.name"
                [nzValue]="country"
                nzCustomContent
              >
                <span [class]="'flag flag-' + country.countryCode"></span>&nbsp;
                <span class="country-name">{{ country.name }} </span>
              </nz-option>
            </nz-select>
            <ng-template #defaultTemplate let-selected>
              <span [class]="'flag flag-' + selected.nzValue.countryCode"></span>&nbsp;
              <span class="country-name">
                <span class="dial-code">{{ selected.nzLabel }}</span>
              </span>
            </ng-template>
            <ng-template #CountryErrorTpl let-control>
              <ng-container *ngIf="control.hasError('required')">
                {{ 'SIGN_UP.INPUT_EMAIL_ERROR' | translate }}
              </ng-container>
              <ng-container *ngIf="control.hasError('country')">
                {{ 'SIGN_UP.INPUT_EMAIL_ERROR' | translate }}
              </ng-container>
            </ng-template>
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col nzXs="24" nzSm="24" nzMd="12" *ngIf="!isGreenCorner">
      <nz-form-item>
        <nz-form-control [nzErrorTip]="CountryErrorTpl">
          <nz-input-group>
            <nz-select
              formControlName="deliveryPort"
              id="deliveryPort"
              name="deliveryPort"
              nz-input
              nzSize="large"
              style="width: 100%"
              [nzCustomTemplate]="portTemplate"
              tabindex="6"
              nzPlaceHolder="Port of Delivery"
            >
              <nz-option
                *ngFor="let port of ports"
                [nzLabel]="port.name"
                nzValue="{{ port.name }} / {{ port.city }}"
                nzCustomContent
              >
                <span class="country-name">{{ port.name }} </span>
              </nz-option>
            </nz-select>
            <ng-template #portTemplate let-selected>
              <span class="country-name">
                <span class="dial-code">{{ selected.nzLabel }}</span>
              </span>
            </ng-template>
            <ng-template #CountryErrorTpl let-control>
              <ng-container *ngIf="control.hasError('required')">
                {{ 'SIGN_UP.INPUT_EMAIL_ERROR' | translate }}
              </ng-container>
              <ng-container *ngIf="control.hasError('country')">
                {{ 'SIGN_UP.INPUT_EMAIL_ERROR' | translate }}
              </ng-container>
            </ng-template>
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>

  <div nz-row nzGutter="8" *ngIf="!isGreenCorner">
    <div nz-col nzXs="24" nzSm="24" nzMd="12">
      <nz-form-item>
        <nz-form-control [nzErrorTip]="CountryErrorTpl">
          <nz-input-group>
            <nz-select
              formControlName="shippingType"
              id="shippingType"
              name="shippingType"
              nz-input
              nzSize="large"
              style="width: 100%"
              nzPlaceHolder="Shipping Type"
              tabindex="6"
            >
              <nz-option nzLabel="RO-RO" nzValue="RO-RO"></nz-option>
              <nz-option nzLabel="CONTAINER" nzValue="CONTAINER"></nz-option>
            </nz-select>
            <ng-template #CountryErrorTpl let-control>
              <ng-container *ngIf="control.hasError('required')">
                {{ 'SIGN_UP.INPUT_EMAIL_ERROR' | translate }}
              </ng-container>
              <ng-container *ngIf="control.hasError('country')">
                {{ 'SIGN_UP.INPUT_EMAIL_ERROR' | translate }}
              </ng-container>
            </ng-template>
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col nzXs="24" nzSm="24" nzMd="12">
      <nz-form-item>
        <nz-form-control [nzErrorTip]="CountryErrorTpl">
          <nz-input-group>
            <nz-select
              formControlName="freightPaymentType"
              id="freightPaymentType"
              name="freightPaymentType"
              nz-input
              nzSize="large"
              style="width: 100%"
              tabindex="6"
              nzPlaceHolder="Freight Payment"
            >
              <nz-option nzLabel="FOB" nzValue="FOB"></nz-option>
              <nz-option nzLabel="CNF" nzValue="CNF"></nz-option>
            </nz-select>
            <ng-template #CountryErrorTpl let-control>
              <ng-container *ngIf="control.hasError('required')">
                {{ 'SIGN_UP.INPUT_EMAIL_ERROR' | translate }}
              </ng-container>
              <ng-container *ngIf="control.hasError('country')">
                {{ 'SIGN_UP.INPUT_EMAIL_ERROR' | translate }}
              </ng-container>
            </ng-template>
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>

  <nz-form-item>
    <nz-form-control nzHasFeedback nzErrorTip="Please input your phone number">
      <app-input-phone-number
        formControlName="phoneNumber"
        [ngModel]="
          currentUser != null
            ? +currentUser.phoneNumber.slice(0, -10) + ' ' + currentUser.phoneNumber.slice(-10)
            : ''
        "
        placeholder="{{ 'SIGN_UP.PHONE_PLACEHOLDER' | translate }}"
        [required]="true"
        nzSize="large"
      ></app-input-phone-number>
      <div
        *ngIf="!phoneNumber.valid && !phoneNumber.pristine && !phoneNumber.untouched"
        style="color: red; width: 100%"
      >
        <span aria-hidden="true"><i class="fa fa-times-circle color-error"></i></span>
        <div *ngIf="phoneNumber.errors?.required">{{ 'SIGN_UP.PHONE_REQUIRED' | translate }}</div>
        <div *ngIf="phoneNumber.errors?.pattern">{{ 'SIGN_UP.PHONE_FORMAT' | translate }}</div>
      </div>
    </nz-form-control>
  </nz-form-item>
  <div nz-row nzGutter="8">
    <div nz-col nzXs="24" nzSm="24" nzMd="8">
      <nz-form-item>
        <nz-form-control>
          <label class="mobile" nz-checkbox formControlName="whatsapp" nzSize="large">
            <span style="font-size: 16px" translate>WhatsApp ready</span>
          </label>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col nzXs="24" nzSm="24" nzMd="8">
      <nz-form-item>
        <nz-form-control>
          <label class="mobile" nz-checkbox formControlName="viber" nzSize="large">
            <span style="font-size: 16px" translate>Viber ready</span>
          </label>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col nzXs="24" nzSm="24" nzMd="8">
      <nz-form-item>
        <nz-form-control>
          <label class="mobile" nz-checkbox formControlName="telegram" nzSize="large">
            <span style="font-size: 16px" translate>Telegram ready</span>
          </label>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
  <nz-form-item>
    <nz-form-control>
      <textarea
        nz-input
        [nzAutosize]="{ minRows: 2, maxRows: 15 }"
        nzSize="large"
        formControlName="comment"
        placeholder="{{ 'Vehicle(s) details or any other query/comments' | translate }}"
      ></textarea>
    </nz-form-control>
  </nz-form-item>
  <div nz-row>
    <div nz-col nzSpan="24">
      <button
        (click)="submitForm()"
        nz-button
        nzSize="large"
        style="width: 100%"
        [nzType]="'primary'"
        nzShape="round"
        [nzLoading]="isQuoteLoading | async"
      >
        {{ 'Get Quotation' | translate }}
      </button>
    </div>
  </div>
</form>
<nz-result
  *ngIf="isQuoteSent | async"
  nzStatus="success"
  nzTitle="{{ 'Quotation Request Sent!' | translate }}"
  nzSubTitle="{{ 'Our managers will contact you shortly. Thank you!' | translate }}"
>
</nz-result>
