import { AuthenticationService } from '@akebono/core';
import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Pipe({
  name: 'vinMask',
  standalone: true,
  pure: false,
})
export class VinMaskPipe implements PipeTransform {
  private isCurrentUserManager = false;

  constructor(authService: AuthenticationService, cdr: ChangeDetectorRef) {
    authService
      .checkPermissions(['world.akebono.auto.auctioneer'])
      .pipe(takeUntilDestroyed())
      .subscribe((isCurrentUserManager) => {
        this.isCurrentUserManager = isCurrentUserManager;
        cdr.markForCheck();
      });
  }

  transform(vin: string | null | undefined): string | null {
    if (vin) {
      const maskedVin = vin.replace(/....$/, '****');
      return this.isCurrentUserManager ? vin : maskedVin;
    } else {
      return null;
    }
  }
}
