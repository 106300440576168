const domain = 'akebono.world';

export const environment = {
  production: true,
  fullDomain: `cars.${domain}`,
  staging: false,
  domain,
  imageProxy: `https://prx.${domain}/`,
  managerDomain: 'https://manager.akebono.world',
  graphql: [
    {
      clientName: 'authentication',
      endpoint: `https://cars.${domain}/accounts/authentication`,
      fetchPolicy: 'cache-first',
    },
    {
      clientName: 'directory',
      endpoint: `https://cars.${domain}/graphql/directory/common/open`,
      fetchPolicy: 'cache-first',
    },
    {
      clientName: 'aucnet-main',
      endpoint: `https://cars.${domain}/aucnet/main`,
      fetchPolicy: 'cache-first',
      method: 'GET',
    },
    {
      clientName: 'aucnet-moto',
      endpoint: `https://cars.${domain}/aucnet/moto`,
      fetchPolicy: 'cache-first',
      method: 'GET',
    },
    {
      clientName: 'mochiron-dumps-main',
      endpoint: `https://cars.${domain}/dubai/main`,
      fetchPolicy: 'cache-first',
      method: 'GET',
    },
    {
      clientName: 'akebono-main',
      endpoint: `https://cars.${domain}/akebono/main`,
      fetchPolicy: 'cache-first',
    },
  ],
  cognitoPool: {
    ClientId: '4hg2ordk36hq7o63in3c7msjmr',
    UserPoolId: 'us-east-1_Aw3GqMAsn',
  },
};
