import { AkebonoCoreModule } from '@akebono/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import {
  CloseOutline,
  DownOutline,
  FilterOutline,
  ShareAltOutline,
} from '@ant-design/icons-angular/icons';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzCarouselModule } from 'ng-zorro-antd/carousel';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzDescriptionsModule } from 'ng-zorro-antd/descriptions';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzImageModule } from 'ng-zorro-antd/image';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzResultModule } from 'ng-zorro-antd/result';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzStatisticModule } from 'ng-zorro-antd/statistic';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { VinMaskPipe } from 'src/app/pipes/vin-mask.pipe';
import { SwiperModule } from 'swiper/angular';

import { StopPropagationDirective } from '../../directives/stop-propagation.directive';
import { ContactFormModule } from '../shared-components/contanct-form/contact-form.module';
import { LotCarouselModule } from '../shared-components/lot-carousel/lot-carousel.module';
import { LotLastVisitDateModule } from '../shared-components/lot-last-visit-date/lot-last-visit-date.module';
import { LotMiniGalleryModule } from '../shared-components/lot-mini-gallery/lot-mini-gallery.module';
import { LotTitleFullModule } from '../shared-components/lot-title-full/lot-title-full.module';
import { RatingTagModule } from '../shared-components/rating-tag/rating-tag.module';
import { ListingsFilterComponent } from './components/listings-filter/listings-filter.component';
import { ListingsComponent } from './components/listings/listings.component';
import { LotContactsComponent } from './components/lot-contacts/lot-contacts.component';
import { LotComponent } from './components/lot/lot.component';
import { MochironDumpsRoutingModule } from './mochiron-dumps-routing.module';
import { MaskedPipe } from './pipes/masked.pipe';
import { QueryFilterService } from './services/query-filter.service';

@NgModule({
  declarations: [
    ListingsComponent,
    ListingsFilterComponent,
    LotComponent,
    MaskedPipe,
    LotContactsComponent,
  ],
  imports: [
    CommonModule,
    MochironDumpsRoutingModule,
    BrowserModule,
    LotCarouselModule,
    LotLastVisitDateModule,
    LotMiniGalleryModule,
    TranslateModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    RouterModule,
    VinMaskPipe,
    AkebonoCoreModule,
    NzCollapseModule,
    NzGridModule,
    NzTagModule,
    NzSelectModule,
    NzInputModule,
    NzDividerModule,
    NzTableModule,
    NzCarouselModule,
    NzDescriptionsModule,
    NzButtonModule,
    NzStatisticModule,
    NzAlertModule,
    NzFormModule,
    NzInputNumberModule,
    NzAvatarModule,
    NzTypographyModule,
    NzCheckboxModule,
    NzDatePickerModule,
    NzSkeletonModule,
    NzSpinModule,
    NzResultModule,
    NzSpaceModule,
    NzCardModule,
    LotTitleFullModule,
    FontAwesomeModule,
    NzPaginationModule,
    NzDropDownModule,
    NzImageModule,
    NzIconModule.forRoot([DownOutline, FilterOutline, CloseOutline, ShareAltOutline]),
    SwiperModule,
    NzBreadCrumbModule,
    RatingTagModule,
    NzIconModule,
    ContactFormModule,
    StopPropagationDirective,
  ],
  providers: [QueryFilterService],
})
export class MochironDumpsModule {}
