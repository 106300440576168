import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'app-rating-tag',
  templateUrl: './rating-tag.component.html',
  styleUrls: ['./rating-tag.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RatingTagComponent {
  @Input() rating: string;

  ratingColor = {
    S: 'gold',
    '6': '#87d068',
    '5': '#87d068',
    '45': '#87d068',
    '4': '#87d068',
    '35': '#550',
    '3': '#550',
    '25': '#f50',
    '2': '#f50',
    '15': '#f50',
    '1': '#f50',
    R: '#f50',
  };
}
