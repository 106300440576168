import { AkebonoCoreModule, BirthdayGreetingsModule, LayoutComponent as LC } from '@akebono/core';
import { registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import en from '@angular/common/locales/en';
import ru from '@angular/common/locales/ru';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCalendarModule } from 'ng-zorro-antd/calendar';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzCarouselModule } from 'ng-zorro-antd/carousel';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzCommentModule } from 'ng-zorro-antd/comment';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzDescriptionsModule } from 'ng-zorro-antd/descriptions';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { en_US, NZ_I18N, ru_RU } from 'ng-zorro-antd/i18n';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzMessageServiceModule } from 'ng-zorro-antd/message';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzResultModule } from 'ng-zorro-antd/result';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { CookieService } from 'ngx-cookie-service';
import { LayoutService } from 'src/app/services/layout.service';
import { environment } from 'src/environments/environment';

import * as enLang from '../locale/en.json';
import * as ruLang from '../locale/ru.json';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainComponent } from './components/main/main.component';
import { AucnetMotoModule } from './modules/aucnet-moto/aucnet-moto.module';
import { AucnetSicRoutingModule } from './modules/aucnet-sic/aucnet-sic-routing.module';
import { AucnetSicModule } from './modules/aucnet-sic/aucnet-sic.module';
import { MochironDumpsRoutingModule } from './modules/mochiron-dumps/mochiron-dumps-routing.module';
import { MochironDumpsModule } from './modules/mochiron-dumps/mochiron-dumps.module';
import { LotMiniGalleryModule } from './modules/shared-components/lot-mini-gallery/lot-mini-gallery.module';

// import { PushModule } from '@rx-angular/template';
// import { Angulartics2, Angulartics2Module } from 'angulartics2';
registerLocaleData(en);
registerLocaleData(ru);

// @Injectable()
// class AkebonoErrorHandler extends ErrorHandler {
//   constructor(private angulartics2: Angulartics2) {
//     super();
//   }

//   handleError(error: Error) {
//     console.log(error);
//     const userId = localStorage?.getItem(
//       `CognitoIdentityServiceProvider.${environment?.cognitoPool?.ClientId}.LastAuthUser`,
//     );
//     if (userId) {
//       this.angulartics2.setUsername.next(userId);
//     }
//     this.angulartics2.eventTrack.next({
//       action: 'Error',
//       properties: {
//         message: error.message,
//         name: error.name,
//         stack: error.stack,
//         string: String(error),
//       },
//     });
//   }
// }

function initializeApplication(translate: TranslateService) {
  return () => {
    const userId = localStorage.getItem(
      `CognitoIdentityServiceProvider.${environment.cognitoPool.ClientId}.LastAuthUser`,
    );

    // if (userId) {
    //   angulartics2.setUsername.next(userId);
    // }

    // if (
    //   Object.keys(enLang).length !==
    //   [...new Set([...Object.keys(enLang), ...Object.keys(ruLang)])].length
    // ) {
    //   throw new Error('translation files are not valid');
    // }

    translate.setTranslation('ru', ruLang, true);
    translate.setTranslation('en', enLang, true);
  };
}
@NgModule({
  declarations: [AppComponent, MainComponent],
  imports: [
    BrowserModule,
    FormsModule,
    // PushModule,
    NzToolTipModule,
    HttpClientModule,
    AppRoutingModule,
    LotMiniGalleryModule,
    BrowserAnimationsModule,
    AucnetSicModule,
    AucnetMotoModule,
    MochironDumpsModule,
    AucnetSicRoutingModule,
    MochironDumpsRoutingModule,
    AkebonoCoreModule.forRoot({
      domain: environment.domain,
      apolloClientsConfig: environment.graphql,
      cognito: environment.cognitoPool,
      skipTopMenu: true,
      showSupportChats: true,
      overrideHeaderEmail: 'sales@akebonocar.jp',
    }),
    TranslateModule.forRoot(),
    // Angulartics2Module.forRoot(),
    NzTagModule,
    NzGridModule,
    NzCardModule,
    NzSpinModule,
    NzIconModule,
    NzFormModule,
    NzTabsModule,
    NzMenuModule,
    NzTableModule,
    NzSwitchModule,
    NzAlertModule,
    NzInputModule,
    NzModalModule,
    NzRadioModule,
    NzDrawerModule,
    NzLayoutModule,
    NzResultModule,
    NzPopoverModule,
    NzButtonModule,
    NzAvatarModule,
    NzSelectModule,
    NzDividerModule,
    NzCommentModule,
    NzCollapseModule,
    NzCheckboxModule,
    NzCalendarModule,
    NzSkeletonModule,
    NzCarouselModule,
    NzPageHeaderModule,
    NzBreadCrumbModule,
    NzTypographyModule,
    NzDatePickerModule,
    NzInputNumberModule,
    NzDescriptionsModule,
    NzNotificationModule,
    NzMessageServiceModule,
    NzPopconfirmModule,
    NzUploadModule,
    BirthdayGreetingsModule,
    NzSpaceModule,
    NzDatePickerModule,
  ],
  providers: [
    CookieService,
    LayoutService,
    TranslateService,
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [TranslateService],
      useFactory: initializeApplication,
    },
    {
      provide: NZ_I18N,
      useFactory: (localId: TranslateService) => {
        switch (localId.currentLang) {
          case 'en':
            return en_US;
          case 'ru':
            return ru_RU;
          default:
            return ru_RU;
        }
      },
      deps: [TranslateService],
    },
    // {
    //   provide: ErrorHandler,
    //   useClass: AkebonoErrorHandler,
    // },
  ],
  bootstrap: [LC],
})
export class AppModule {}
