import { Injectable } from '@angular/core';
import { Country } from './country.model';
import { countries } from 'countries-list';

@Injectable()
export class CountryService {
  private countries: Country[];

  constructor() {}

  /**
   * Returns the countries
   */
  public getCountries(): Country[] {
    if (!this.countries || this.countries.length === 0) {
      this.countries = this.loadCountries();
    }
    return this.countries;
  }

  /**
   * Returns the countries by iso code
   */
  public getCountriesByISO(listOfIso): Country[] {
    if (!this.countries || this.countries.length === 0) {
      this.countries = this.loadCountries();
    }

    if (listOfIso) {
      const countries = [];
      for (const iso of listOfIso) {
        const countryObj = this.countries.find(
          (o) => o.countryCode.toLowerCase() === iso.toLowerCase(),
        );
        if (countryObj) {
          countries.push(countryObj);
        }
      }
      return countries;
    } else {
      return this.countries;
    }
  }

  /**
   * Load and returns the countries
   */
  private loadCountries(locale: any = 'en'): Country[] {
    return Object.keys(countries).map((x) => {
      return {
        name: countries[x].name,
        countryCode: x.toLocaleLowerCase(),
        dialCode: countries[x].phone,
      };
    });
  }
}
