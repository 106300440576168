import { Pipe, PipeTransform } from '@angular/core';

import { LotQuery, LotsListItemFragment } from '../../graphql/service/graphql-aucnet-moto-service';

@Pipe({ name: 'extractImages' })
export class ExtractImagesPipe implements PipeTransform {
  transform(lot: LotsListItemFragment | LotQuery['lot']): string[] {
    return lot.images?.split('#')?.map((path) => `https://img.akebono.world/${path}`) || [];
  }
}
